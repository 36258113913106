import React, { useEffect, useState } from "react";
import * as PATH from "~/configs/routesConfig";
import { connect } from "react-redux";
import { compose } from "redux";
import strings from "~/localization";
import { withRouter } from "react-router-dom";
import { UIButton } from "~/views/presentation/ui/buttons";
import { PageTitle, UIPagination } from "~/views/presentation/ui/commons";
import { SearchField } from "~/views/presentation/ui/fields";
import { UITable } from "~/views/presentation/ui/tables";
import { getArray, getString } from "~/views/utilities/helpers/utilObject";
import {
  getFarmlist,
  getProductionLogList,
  getSeasonList,
} from "~/state/ducks/appApis/actions";
import InputFilter from "~/views/presentation/ui/fields/InputFilter";
import { SearchOutlined } from "@ant-design/icons";

function DiaryList(props) {
  const { getProductionLogList, match, getSeasonList, getFarmlist, history } =
    props;
  const [filters, setFilters] = useState({});
  const [dataSource, setDataSource] = useState([]);
  const [seasonList, setSeasonList] = useState([]);
  const [farmList, setFarmList] = useState([]);
  const [pagination, setPagination] = useState({
    total: 0,
    current: 1,
    pageSize: 5,
    hideOnSinglePage: false,
  });
  const [sort, setSort] = useState("productionLogId,desc");
  const [isLoading, setIsLoading] = useState(false);

  const generateAutoKey = (dataSource) => {
    return getArray(dataSource, undefined, []).map((item, index) => ({
      ...item,
      key: item.id,
    }));
  };

  useEffect(() => {
    const farmingSeasonId = Number(match.params.farmingSeasonId);
    if (farmingSeasonId) {
      setFilters({ ...filters, farmingSeasonId: farmingSeasonId });
    } else {
      if (match.params.farmingSeasonId !== "all") {
        history.push(
          PATH.DIARY_MANAGERMENT_LIST.replace(":farmingSeasonId", "all")
        );
      }
    }
  }, []);

  const fetchProductionLog = (page) => {
    setIsLoading(true);
    getProductionLogList({
      ...filters,
      sort: sort,
      page: page ? page - 1 : pagination.current - 1,
      size: pagination.pageSize,
    })
      .then((res) => {
        setDataSource(generateAutoKey(res.res));
        const total = getString(res.header, "x-total-count");
        setPagination((prev) => ({ ...prev, total: total }));
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getSeasonList()
      .then((res) => {
        setSeasonList(
          res.res.map((m) => {
            return {
              value: m.farmingSeasonId,
              label: m.farmingSeasonName,
            };
          })
        );
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setIsLoading(false);
      });

    getFarmlist()
      .then((res) => {
        setFarmList(
          res.res.map((m) => {
            return {
              value: m.id,
              label: m.name,
            };
          })
        );
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  /**
   *  Handle change filters
   */

  const changeTextSearch = (value) => {
    // setFilters((prev) => ({ name: value }));
    // setPagination((prev) => ({ ...prev, current: 1 }));
  };

  const changePage = (page) => {
    setPagination((prev) => ({ ...prev, current: page }));
    fetchProductionLog(page);
  };

  // onsort params
  const onSort = (dataIndex, sortKey) => {
    const sortOrder = sortKey === "ascend" ? "asc" : "desc";
    setSort(dataIndex + "," + sortOrder);
  };

  // onfilter params
  const onFilter = (value) => {
    setFilters({ ...filters, ...value });
  };

  useEffect(() => {
    fetchProductionLog();
  }, [filters, sort]);

  let columns = [
    {
      title: strings.SEASON_TABLE_COLUMN_ID,
      dataIndex: "id",
      key: "productionLogId",
      width: 130,
      sorter: (a, b, sortOrder) => {
        onSort("productionLogId", sortOrder);
      },
      render: (cellData, row) => {
        return (
          <div
            onClick={() => {
              history.push(
                PATH.DIARY_MANAGERMENT_DETAILS.replace(
                  ":action/:id",
                  "view/" + row.productionLogId
                )
              );
            }}
            className="text-primary"
          >
            {row.productionLogId ? row.productionLogId : "-"}
          </div>
        );
      },
    },
    {
      title: strings.DIARY_TABLE_COLUMN_CREATED_DATE,
      dataIndex: "createdDate",
      onFiler: (val) =>
        onFilter({ createdDateFrom: val[0], createdDateTo: val[1] }),
      supportFilter: "date",
      render: (cellData, row) => {
        return (
          <>{cellData ? new Date(cellData).toLocaleDateString("vi-VN") : "-"}</>
        );
      },
    },
    {
      title: strings.DIARY_TABLE_COLUMN_WORKS,
      dataIndex: "works",
      sorter: false,
      render: (cellData, records) => {
        return <>{cellData ? cellData : "-"}</>;
      },
    },
    {
      title: strings.DIARY_TABLE_COLUMN_FARM,
      dataIndex: "farmName",
      filterDropdown: (
        <InputFilter
          type="select"
          value={filters.farmId || ""}
          width={200}
          onFiler={(val) => {
            onFilter({ farmId: val });
          }}
          options={farmList}
        />
      ),
      render: (cellData, records) => {
        return cellData;
      },
    },
    {
      title: strings.DIARY_TABLE_COLUMN_SEASON,
      dataIndex: "farmingSeasonName",
      key: "farmingSeasonId",
      filterDropdown: (
        <InputFilter
          type="select"
          value={filters.farmingSeasonId || ""}
          width={200}
          onFiler={(val) => {
            onFilter({ farmingSeasonId: val });
          }}
          options={seasonList}
        />
      ),
      render: (cellData, records) => {
        return <>{cellData ? cellData : "-"}</>;
      },
    },
    {
      title: strings.ACTION,
      dataIndex: "",
      sorter: false,
      filterDropdown: false,
      width: 100,
      render: (cellData, row) => {
        return (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <UIButton
              type="primary"
              width="20"
              onClick={() => {
                history.push(
                  PATH.DIARY_MANAGERMENT_DETAILS.replace(
                    ":action/:id",
                    "view/" + row.productionLogId
                  )
                );
              }}
            >
              {strings.VIEW}
            </UIButton>
          </div>
        );
      },
    },
  ];

  columns = columns.map((col) => {
    let newCol = {
      width: 200,
      align: "center",
      sorter: (a, b, sortOrder) => {
        onSort(col.dataIndex, sortOrder);
      },
      filterDropdown: (
        <InputFilter
          value={filters[col.key ? col.key : col.dataIndex] || ""}
          type={col.supportFilter || "input"}
          width={col.width || 200}
          options={col.options || []}
          onFiler={
            col.onFiler
              ? col.onFiler
              : (val) => {
                  onFilter({ [col.key ? col.key : col.dataIndex]: val });
                }
          }
        />
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
      ),
      showSorterTooltip: false,
      ...col,
    };
    if (!newCol.filterDropdown) delete newCol.filterDropdown;
    return newCol;
  });

  return (
    <div>
      <PageTitle title={strings.MENU_DIARY} breadcrumb={false} />
      <div className="form-search-header w-100">
        <SearchField
          initialValue={filters.name || ""}
          placeholder={strings.search_diary_placeholder}
          size="small"
          onChangeText={changeTextSearch}
        />
      </div>
      <div className="category-page">
        <div className="table-view w-100">
          <UITable
            dataSource={getArray(dataSource)}
            rowClassName="cursor-pointer"
            columns={columns}
            loading={isLoading}
          />

          <div className="form-action-header mt-3 row">
            <div className="col-12 d-flex justify-content-end mb-3">
              <div className="pagination-right d-flex justify-content-center align-items-center ">
                <div className="amount-category mr-4">
                  <p>{`${pagination.total} ${strings.diary}`}</p>
                </div>
                <UIPagination
                  defaultCurrent={pagination.current}
                  total={pagination.total}
                  pageSize={pagination.pageSize}
                  onChange={(page) => changePage(page)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default compose(
  withRouter,
  connect((state) => ({}), {
    getProductionLogList,
    getSeasonList,
    getFarmlist,
  })
)(DiaryList);

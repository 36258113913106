import * as types from "./types";
import apiAction, { defaultAction } from "../utils/createAction";
import parseObjToQuery from "~/views/utilities/helpers/parseObjToQuery";

/**
 * Services
 */

let services = {
  sys: "/services/sys",
  godi: "/services/godi-services",
};

/**
 * identity type resource
 */
export const getIdentityTypes = () =>
  apiAction("get")(
    types.COMMON,
    services.sys + "/api/master-data/types?type=IDENTITY",
    {},
    true,
  );
/**
 * getPerSionNalProfile
 */
export const getPersionnalProfile = () =>
  apiAction("get")(
    types.COMMON,
    services.sys + "/api/v1/profiles/filter",
    {},
    true,
  );

/**
 * locations
 */
export const getCountries = () =>
  apiAction("get")(types.COMMON, services.sys + "/api/countries", {}, true);
export const getProvinces = (countryId) =>
  apiAction("get")(
    types.COMMON,
    services.sys +
      "/api/v1/provinces?countryId=" +
      (countryId > 0 ? countryId : ""),
    {},
    true,
  );
export const getDistrict = (provinceId) =>
  apiAction("get")(
    types.COMMON,
    services.sys + "/api/v1/districts?provinceId=" + provinceId,
    {},
    true,
  );
export const getWards = (district) =>
  apiAction("get")(
    types.COMMON,
    services.sys + "/api/v1/wards?districtId=" + district,
    {},
    true,
  );

/**
 * addresses
 */
export const getAddressTypes = () =>
  apiAction("get")(
    types.COMMON,
    services.sys + "/api/master-data/types?type=ADDRESS",
    {},
    true,
  );

/**
 * degree
 */
export const getDegreeTypes = () =>
  apiAction("get")(
    types.COMMON,
    services.sys + "/api/master-data/types?type=DEGREE",
    {},
    true,
  );

/**
 * specializes
 */
export const getSpecializes = () =>
  apiAction("get")(
    types.COMMON,
    services.sys + "/api/master-data/types?type=SPECIALIZE",
    {},
    true,
  );

/**
 * ranking
 */
export const getRankings = () =>
  apiAction("get")(
    types.COMMON,
    services.sys + "/api/master-data/types?type=RANKING",
    {},
    true,
  );

/**
 * income
 */
export const getIncomes = () =>
  apiAction("get")(
    types.COMMON,
    services.sys + "/api/master-data/types?type=INCOME",
    {},
    true,
  );

/**
 * relation ship
 */
export const getRelationShips = () =>
  apiAction("get")(
    types.COMMON,
    services.sys + "/api/master-data/types?type=RELATION",
    {},
    true,
  );
/**
 * MATERIAL
 */
export const getTypeMaterials = () =>
  apiAction("get")(
    types.COMMON,
    services.sys + "/api/master-data/types?type=MATERIAL",
    {},
    true,
  );
/**
 * MATERIAL_SUPPLIER
 */
export const getMaterialSuppliers = () =>
  apiAction("get")(
    types.COMMON,
    services.sys + "/api/master-data/types?type=MATERIAL_SUPPLIER",
    {},
    true,
  );

/**
 * banks
 */
export const getBanks = () =>
  apiAction("get")(types.COMMON, services.sys + "/api/banks", {}, true);
export const getBranches = (bankId) =>
  apiAction("get")(
    types.COMMON,
    services.sys + "/api/bank-branches?bankId=" + bankId,
    {},
    true,
  );

/**
 * unit
 */
export const getUnits = (type) =>
  apiAction("get")(
    types.COMMON,
    services.sys + `/api/units?type=${type}`,
    {},
    true,
  );

/**
 * Type of agriculture
 */
export const getAgricultureTypes = () =>
  apiAction("get")(
    types.COMMON,
    services.sys + "/api/master-data/cultivation-type",
    {},
    true,
  );

/**
 * Quanlity
 */
export const getQuanlities = () =>
  apiAction("get")(
    types.COMMON,
    services.sys + "/api/master-data/quality-type",
    {},
    true,
  );

/**
 * Product Types
 */

export const getProductTypes = () =>
  apiAction("get")(
    types.COMMON,
    services.sys + "/api/master-data/product-type",
    {},
    true,
  );

/**
 * Certification types
 */
/**
 * Get Types
 */
export const getType = (type) =>
  apiAction("get")(
    types.COMMON,
    services.sys + `/api/master-data/types?type=${type}`,
    {},
    true,
  );
export const getCertificationTypes = () =>
  apiAction("get")(
    types.COMMON,
    services.sys + "/api/master-data/types?type=CERTIFICATION",
    {},
    true,
  );

/**
 * Literacy
 */
export const getLiteracies = () =>
  apiAction("get")(
    types.COMMON,
    services.sys + "/api/master-data/types?type=LITERACY",
    {},
    true,
  );

/**
 * professions
 */
export const getProfessions = () =>
  apiAction("get")(
    types.COMMON,
    services.sys + "/api/master-data/types?type=PROFESSION",
    {},
    true,
  );

/**
 * Profiles
 */
export const getAllProfiles = (type) =>
  apiAction("get")(
    types.COMMON,
    services.sys + `/api/v1/profile/all${type ? "?type=" + type : ""}`,
    {},
    true,
  );
// personal
export const getPersionalProfile = (profileId) =>
  apiAction("get")(
    types.COMMON,
    services.sys + "/api/v1/sys-accounts/profile/" + profileId,
    {},
    true,
  );
export const deletePersonalProfile = (profileId) =>
  apiAction("delete")(
    types.COMMON,
    services.sys + "/api/v1/sys-accounts/profile/" + profileId,
    {},
    true,
  );
// farm
export const getFarmProfile = (farmId) =>
  apiAction("get")(
    types.COMMON,
    services.sys + "/api/v1/farms/" + farmId,
    {},
    true,
  );
export const getEnterpriseProfile = (enterID) =>
  apiAction("get")(
    types.COMMON,
    services.sys + "/api/v1/enterprises/" + enterID,
    {},
    true,
  );
//
export const deleteFarmProfile = (farmID) =>
  apiAction("delete")(
    types.COMMON,
    services.sys + "/api/v1/farms/" + farmID,
    {},
    true,
  );
//
export const deleteEnterpriseProfile = (enter) =>
  apiAction("delete")(
    types.COMMON,
    services.sys + "/api/v1/enterprises/" + enter,
    {},
    true,
  );
// cooperation - in tms is cooperative
export const getCooperativeProfile = (cooperationId) =>
  apiAction("get")(
    types.COMMON,
    services.sys + "/api/v1/cooperation/" + cooperationId,
    {},
    true,
  );
export const deleteCooperativeProfile = (cooperationId) =>
  apiAction("delete")(
    types.COMMON,
    services.sys + "/api/v1/cooperation/" + cooperationId,
    {},
    true,
  );
// cooperation-venture - in tms is cooperative-venture
export const getCooperativeVentureProfile = (cooperationVentureId) =>
  apiAction("get")(
    types.COMMON,
    services.sys + "/api/v1/cooperation-venture/" + cooperationVentureId,
    {},
    true,
  );
export const deleteCooperativeVentureProfile = (cooperationVentureId) =>
  apiAction("delete")(
    types.COMMON,
    services.sys + "/api/v1/cooperation-venture/" + cooperationVentureId,
    {},
    true,
  );
// organization
export const getOrganizationProfile = (organizationId) =>
  apiAction("get")(
    types.COMMON,
    services.sys + "/api/v1/organizations/" + organizationId,
    {},
    true,
  );
export const deleteOrganizationProfile = (organizationId) =>
  apiAction("delete")(
    types.COMMON,
    services.sys + "/api/v1/organizations/" + organizationId,
    {},
    true,
  );

/**
 * upload files
 */
export const getFilesProfile = (profileId, type) =>
  apiAction("get")(
    types.COMMON,
    services.sys + `/api/v1/files?profileId=${profileId}&profileType=${type}`,
    {},
    true,
  );
export const deleteFileProfile = (path) =>
  apiAction("delete")(
    types.COMMON,
    services.sys + `/api/v1/files/${path}`,
    {},
    true,
  );

/**
 * activity logs
 */
export const getActivityLogs = (params) =>
  apiAction("get")(
    types.COMMON,
    services.sys + "/api/v1/logs" + parseObjToQuery(params),
    {},
    true,
  );

/**
 * FARM MANAGEMENT
 */

export const getFarmSeasonCRUD = (farmSeasonId) =>
  apiAction("get")(
    types.COMMON,
    services.sys + "/api/v1/farming-seasons/" + farmSeasonId,
    {},
    true,
  );
export const deleteFarmSeasonCRUD = (farmSeasonId) =>
  apiAction("delete")(
    types.COMMON,
    services.sys + "/api/v1/farming-seasons/" + farmSeasonId,
    {},
    true,
  );
export const createCultivationProcess = (body) =>
  apiAction("post")(
    types.COMMON,
    services.sys + "/api/v1/cultivation-processes",
    body,
    true,
  );
export const editCultivationProcess = (body) =>
  apiAction("put")(
    types.COMMON,
    services.sys + "/api/v1/cultivation-processes",
    body,
    true,
  );
export const getAllProces = (idType = undefined) =>
  apiAction("get")(
    types.COMMON,
    services.sys + "/api/v1/cultivation-processes?typeId=" + idType,
    {},
    true,
  );

//cultivation-step-resource
export const createCultivationStep = (body) =>
  apiAction("post")(
    types.COMMON,
    services.sys + "/api/cultivation-steps",
    body,
    true,
  );

/**
 *  farm season
 *  */
// product - farm crops
export const getAllFarmCrops = () =>
  apiAction("get")(types.COMMON, services.sys + "/api/products", {}, true);
// thửa đất
export const getAllCertificateOfLands = (idFarm) =>
  apiAction("get")(
    types.COMMON,
    services.sys + `/api/v1/farms/${idFarm}/certificate-of-lands`,
    {},
    true,
  );
// farm processes
export const getAllCultivationProcesses = () =>
  apiAction("get")(
    types.COMMON,
    services.sys + `/api/v1/cultivation-processes`,
    {},
    true,
  );
// material
export const getAllMaterialInventories = () =>
  apiAction("get")(
    types.COMMON,
    services.sys + `/api/v1/material-inventories`,
    {},
    true,
  );
export const getMaterialInventories = (idMaterial) =>
  apiAction("get")(
    types.COMMON,
    services.sys + `/api/v1/material-inventories/${idMaterial}`,
    {},
    true,
  );
export const getSearchMaterialInventories = (params) => {
  return apiAction("get")(
    types.COMMON,
    services.sys + `/api/v1/material-inventories?${params}`,
    {},
    true,
  );
};
// unit Material
export const getUnitMaterial = (type = "MATERIAL") =>
  apiAction("get")(
    types.COMMON,
    services.sys + `/api/units?type=${type}`,
    {},
    true,
  );

export const getAllFarmSeason = () =>
  apiAction("get")(
    types.COMMON,
    services.sys + "/api/v1/farming-seasons",
    {},
    true,
  );
export const getAllFarmSeasonByYear = (farmId) =>
  apiAction("get")(
    types.COMMON,
    services.sys + `/api/v1/farming-seasons/group-by-year?farmId=${farmId}`,
    {},
    true,
  );

/**
 * MASTER DATA
 *
 */

// Category page
export const createCategory = (data) =>
  apiAction("post")(
    types.COMMON,
    services.sys + `/api/v1/admin/categories`,
    data,
    true,
  );

export const getAllCategories = (size = 5, page = 1, keyword = "", type = "") =>
  apiAction("get")(
    types.COMMON,
    services.sys +
      `/api/v1/admin/categories?keyword=${keyword}&type=${type}&size=${size}&page=${
        page - 1
      }`,
    {},
    true,
  );

export const getViewProducts = (categoryId) =>
  apiAction("get")(
    types.COMMON,
    services.sys + `/api/v1/admin/products?categoryId=${categoryId}`,
    {},
    true,
  );

export const getParentCategories = (type) =>
  apiAction("get")(
    types.COMMON,
    services.sys + `/api/v1/admin/categories/filter?type=${type}`,
    {},
    true,
  );

export const updateCategory = (data) =>
  apiAction("put")(
    types.COMMON,
    services.sys + `/api/v1/admin/categories`,
    data,
    true,
  );

export const deleteCategory = (id) =>
  apiAction("delete")(
    types.COMMON,
    services.sys + `/api/v1/admin/categories/${id}`,
    {},
    true,
  );

// All Products  page

export const getAllProducts = (
  keyword,
  current = 1,
  pageSize = 5,
  categoryId = "",
) =>
  apiAction("get")(
    types.COMMON,
    services.sys +
      `/api/products?categoryId=${categoryId}&keyword=${keyword}&page=${
        current - 1
      }&size=${pageSize}`,
    {},
    true,
  );
export const deleteProduct = (id) =>
  apiAction("delete")(
    types.COMMON,
    services.sys + `/api/products/${id}`,
    {},
    true,
  );

// add product page
export const getProduct = (id) =>
  apiAction("get")(
    types.COMMON,
    services.sys + `/api/products/${id}`,
    {},
    true,
  );

export const createProduct = (data) =>
  apiAction("post")(types.COMMON, services.sys + `/api/products`, data, true);

export const updateProduct = (data) =>
  apiAction("put")(types.COMMON, services.sys + `/api/products`, data, true);

export const getCategoryOptions = () =>
  apiAction("get")(
    types.COMMON,
    services.sys + `/api/v1/admin/categories/filter?type=PRODUCT`,
    {},
    true,
  );

export const getMasterData = (params) =>
  apiAction("get")(
    types.COMMON,
    services.sys + `/api/v1/admin/master-data/types`,
    {},
    true,
    true,
    params,
  );

export const createOrUpdateMasterData = (body, type) =>
  apiAction(body.id ? "put" : "post")(
    types.COMMON,
    services.sys + `/api/v1/admin/master-data/${type}`,
    body,
    true,
    true,
  );

export const deleteMasterData = (id) =>
  apiAction("delete")(
    types.COMMON,
    services.sys + `/api/v1/admin/master-data/master-data-types/${id}`,
    {},
    true,
    true,
  );

export const getFarmlist = (params) =>
  apiAction("get")(
    types.COMMON,
    services.sys + `/api/v1/admin/farm-all`,
    {},
    true,
    true,
    params,
  );

export const getFarmDetails = (id) =>
  apiAction("get")(
    types.COMMON,
    services.sys + `/api/v1/admin/farms/detail/${id}`,
    {},
    true,
    true,
  );

export const updateFarm = (body) =>
  apiAction("put")(
    types.COMMON,
    services.sys + `/api/v1/admin/farms`,
    body,
    true,
    true,
  );

export const getSeasonList = (params) =>
  apiAction("get")(
    types.COMMON,
    services.sys + `/api/v1/admin/farming-seasons/all`,
    {},
    true,
    true,
    params,
  );

export const getSeasonDetails = (id) =>
  apiAction("get")(
    types.COMMON,
    services.sys + `/api/v1/admin/farming-seasons/${id}`,
    {},
    true,
    true,
  );

export const updateSeasonDetails = (body) =>
  apiAction("put")(
    types.COMMON,
    services.sys + `/api/v1/admin/farming-seasons`,
    body,
    true,
    true,
  );

export const getProductionLogList = (params) =>
  apiAction("get")(
    types.COMMON,
    services.sys + `/api/v1/admin/production-log`,
    {},
    true,
    true,
    params,
  );

export const getProductionLogDetails = (id) =>
  apiAction("get")(
    types.COMMON,
    services.sys + `/api/v1/admin/production-log/${id}`,
    {},
    true,
    true,
  );

export const getCategorys = () =>
  apiAction("get")(
    types.COMMON,
    services.sys + `/api/v1/admin/categories/filter`,
    {},
    true,
    true,
  );
export const getManufacturer = () =>
  apiAction("get")(
    types.COMMON,
    services.godi + `/api/v1/manufacturer/filter`,
    {},
    true,
    true,
  );

export const getFarmDashBoard = (params) =>
  apiAction("get")(
    types.COMMON,
    services.sys + `/api/v1/admin/dashboard/farm`,
    {},
    true,
    true,
    params,
  );
export const getSeasonDashBoard = (params) =>
  apiAction("get")(
    types.COMMON,
    services.sys + `/api/v1/admin/dashboard/farming-season`,
    {},
    true,
    true,
    params,
  );

  export const getFarmChart = (params) =>
  apiAction("get")(
    types.COMMON,
    services.sys + `/api/v1/admin/farm/chart`,
    {},
    true,
    true,
    params,
  );

  export const getAcreageChart = (params) =>
  apiAction("get")(
    types.COMMON,
    services.sys + `/api/v1/admin/acreage/chart`,
    {},
    true,
    true,
    params,
  );

  export const getSeasonChart = (params) =>
  apiAction("get")(
    types.COMMON,
    services.sys + `/api/v1/admin/farming-season/chart`,
    {},
    true,
    true,
    params,
  );

  



import React from "react";
import UITable from "~/views/presentation/ui/tables/Table";
import { UIButton } from "~/views/presentation/ui/buttons";
import { compose, lifecycle, withHandlers, withState } from "recompose";
import * as PATH from "~/configs/routesConfig";
import strings from "~/localization";
import { getString, getArray } from "~/views/utilities/helpers/utilObject";
import { UIPagination } from "~/views/presentation/ui/commons";
import { SearchField } from "~/views/presentation/ui/fields";
import { getFarmlist } from "~/state/ducks/appApis/actions";
import { connect } from "react-redux";
import { showError } from "~/configs/ServerErrors";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import { PageTitle } from "~/views/presentation/ui/commons";
import InputFilter from "~/views/presentation/ui/fields/InputFilter";
import { SearchOutlined } from "@ant-design/icons";

const generateAutoKey = (dataSource) => {
  return getArray(dataSource, undefined, []).map((item, index) => ({
    ...item,
    key: item.id,
  }));
};

const optionFilterArea = [
  {
    value: "0-1000",
    label: "0 Ha - 1000 Ha",
  },
  {
    value: "1000-2000",
    label: "1000 Ha - 2000 Ha",
  },
  {
    value: "2000-3000",
    label: "2000 Ha - 3000 Ha",
  },
  {
    value: "3000-5000",
    label: "3000 Ha - 5000 Ha",
  },
  {
    value: "5000-10000",
    label: "5000 Ha - 10000 Ha",
  },
  {
    value: "10000-9999999",
    label: "> 10000 Ha",
  },
];

class FarmList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      loading: false,
    };
  }

  selectRow = (record) => {
    const selectedRowKeys = [...this.state.selectedRowKeys];
    if (selectedRowKeys.indexOf(record.key) >= 0) {
      selectedRowKeys.splice(selectedRowKeys.indexOf(record.key), 1);
    } else {
      selectedRowKeys.push(record.key);
    }
    this.setState({ selectedRowKeys });
  };

  onSelectedRowKeysChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  /**
   * Handle button action  on table
   */

  handleUpdate = (id) => {
    const { history } = this.props;
    history.push(
      PATH.FARM_MANAGERMENT_DETAILS.replace(":action/:id", "edit/" + id)
    );
  };

  handleView = (id) => {
    const { history } = this.props;
    history.push(
      PATH.FARM_MANAGERMENT_DETAILS.replace(":action/:id", "view/" + id)
    );
  };

  /**
   *  Handle change filters
   */

  changeTextSearch = (value) => {
    const { setFilters, fetchFarmlist, pagination, setPagination } = this.props;
    setFilters((prev) => ({ name: value }));
    setPagination((prev) => ({ ...prev, current: 1 }));
  };

  changePage = (page) => {
    const { pagination, setPagination, fetchFarmlist, filters } = this.props;
    setPagination((prev) => ({ ...prev, current: page }));
    fetchFarmlist(page, pagination.pageSize);
  };

  // onsort params
  onSort = (dataIndex, sortKey) => {
    const { setSort } = this.props;
    const sort = sortKey === "ascend" ? "asc" : "desc";
    setSort(dataIndex + "," + sort);
  };

  // onfilter params
  onFilter = (value) => {
    const { setFilters, filters } = this.props;
    setFilters({ ...filters, ...value });
  };

  render() {
    const { dataSource, isLoading, pagination, filters, history } = this.props;
    const { selectedRowKeys } = this.state;
    let columns = [
      {
        title: strings.FARM_TABLE_COLUMN_ID,
        dataIndex: "id",
        width: 150,
        render: (cellData, row) => {
          return (
            <div
              onClick={() => this.handleView(row.id)}
              className="text-primary"
            >
              {cellData ? cellData : "-"}
            </div>
          );
        },
      },
      {
        title: strings.FARM_TABLE_COLUMN_NAME,
        dataIndex: "name",
        width: 240,
        render: (cellData, row) => {
          return (
            <div
              onClick={() => this.handleView(row.id)}
              className="text-primary"
            >
              {cellData ? cellData : "-"}
            </div>
          );
        },
      },
      {
        title: strings.FARM_TABLE_COLUMN_PHONE,
        dataIndex: "phone",
        sorter: false,
        render: (cellData, records) => {
          return <>{cellData ? cellData : "-"}</>;
        },
      },
      {
        title: strings.FARM_TABLE_COLUMN_ADDRESS,
        dataIndex: "fulladdress",
        sorter: false,
        onFiler: (val) => {
          this.onFilter({ address: val });
        },
        width: 250,
        render: (cellData, records) => {
          return cellData;
        },
      },
      {
        title: strings.FARM_TABLE_COLUMN_AREA,
        dataIndex: "grossarea",
        filterDropdown: (
          <InputFilter
            value={
              (filters.grossAreaFrom &&
                filters.grossAreaTo &&
                `${filters.grossAreaFrom}-${filters.grossAreaTo}`) ||
              ""
            }
            type="select"
            width={185}
            onFiler={(val) => {
              if (val) {
                const value = val.split("-");
                this.onFilter({
                  grossAreaFrom: value[0],
                  grossAreaTo: value[1],
                });
              } else {
                this.onFilter({
                  grossAreaFrom: undefined,
                  grossAreaTo: undefined,
                });
              }
            }}
            options={optionFilterArea}
          />
        ),
        render: (cellData, records) => {
          return cellData + " " + records.grossAreaUnit;
        },
      },
      {
        title: strings.ACTION,
        dataIndex: "",
        sorter: false,
        filterDropdown: false,
        width: 140,
        render: (cellData, row) => {
          return (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <UIButton
                type="primary"
                width="20"
                onClick={() => {
                  this.handleView(row.id);
                }}
              >
                {strings.VIEW}
              </UIButton>
              <UIButton
                type="secondary"
                width="20"
                onClick={() => {
                  this.handleUpdate(row.id);
                }}
              >
                {strings.EDIT}
              </UIButton>
            </div>
          );
        },
      },
    ];

    columns = columns.map((col) => {
      let newCol = {
        width: 185,
        align: "center",
        sorter: (a, b, sortOrder) => {
          this.onSort(col.dataIndex, sortOrder);
        },
        filterDropdown: (
          <InputFilter
            value={filters[col.dataIndex] || ""}
            type={"input"}
            width={col.width || 185}
            onFiler={
              col.onFiler
                ? col.onFiler
                : (val) => {
                    this.onFilter({ [col.dataIndex]: val });
                  }
            }
          />
        ),
        filterIcon: (filtered) => (
          <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
        ),
        showSorterTooltip: false,
        ...col,
      };
      if (!newCol.filterDropdown) delete newCol.filterDropdown;
      return newCol;
    });

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectedRowKeysChange,
    };

    return (
      <>
        <PageTitle title={strings.MENU_FARM_LIST} breadcrumb={false} />
        <div className="form-search-header w-100">
          <SearchField
            initialValue={filters.name || ""}
            placeholder={strings.search_farm_placeholder}
            size="small"
            onChangeText={this.changeTextSearch}
          />
        </div>
        <div className="category-page">
          <div className="table-view w-100">
            <UITable
              dataSource={getArray(dataSource)}
              rowClassName="cursor-pointer"
              columns={columns}
              loading={isLoading}
              rowSelection={rowSelection}
            />
            <div className="form-action-header mt-3 row">
              <div className="col-12 d-flex justify-content-end mb-3">
                <div className="pagination-right d-flex justify-content-center align-items-center ">
                  <div className="amount-category mr-4">
                    <p>{`${pagination.total} ${strings.farm}`}</p>
                  </div>
                  <UIPagination
                    defaultCurrent={pagination.current}
                    total={pagination.total}
                    pageSize={pagination.pageSize}
                    onChange={(page) => this.changePage(page)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default compose(
  withRouter,
  connect((state) => ({}), {
    getFarmlist,
  }),
  withState("dataSource", "setDataSource", []),
  withState("pagination", "setPagination", {
    total: 0,
    current: 1,
    pageSize: 5,
    hideOnSinglePage: false,
  }),
  withState("filters", "setFilters", {}),
  withState("sort", "setSort", "id,desc"),
  withState("isLoading", "setIsLoading", false),
  withHandlers({
    fetchFarmlist: (props) => (page, size) => {
      const {
        getFarmlist,
        setPagination,
        setDataSource,
        setIsLoading,
        filters,
        sort,
      } = props;
      setIsLoading(true);
      getFarmlist({ page: page - 1, size: size, sort: sort, ...filters })
        .then((res) => {
          setDataSource(generateAutoKey(res.res));
          const total = getString(res.header, "x-total-count");
          setPagination((prev) => ({ ...prev, total: total }));
        })
        .catch((err) => {
          showError(err);
        })
        .finally(() => setIsLoading(false));
    },
  }),
  lifecycle({
    componentDidMount() {
      const { fetchFarmlist, pagination } = this.props;
      fetchFarmlist(pagination.current, pagination.pageSize);
    },

    componentDidUpdate(prevState, prevProps) {
      const { fetchFarmlist, pagination, sort, filters } = this.props;
      if (prevState.sort !== sort) {
        fetchFarmlist(pagination.current, pagination.pageSize);
      }

      if (prevState.filters !== filters) {
        fetchFarmlist(pagination.current, pagination.pageSize);
      }
    },
  })
)(FarmList);

import React from "react";
import { compose, withHandlers, lifecycle, withState } from "recompose";
import { connect } from "react-redux";
import { PageTitle } from "~/views/presentation/ui/commons";
import strings from "~/localization";
import { ContentWrapper } from "~/views/presentation/ui/container";
import { withRouter } from "react-router-dom";
import OriginalProduct from "./OriginalProduct";
import { UIButton } from "~/views/presentation/ui/buttons";
import * as PATH from "~/configs/routesConfig";

class AllProducts extends React.PureComponent {
  render() {
    const { history } = this.props;

    return (
      <ContentWrapper>
        <PageTitle title={strings.ORIGINAL_PAGE_TITLE} breadcrumb={false} />
        <div className="d-flex col-12 col-md-6 col-lg-6 mb-10">
          <UIButton
            width={110}
            type="primary"
            className="btn-pd-add"
            onClick={() =>
              history.push({
                pathname: PATH.MASTER_DATA_PRODUCT_ADD,
              })
            }
          >
            {strings.add_new}
          </UIButton>
          <UIButton className="ml-10 mr-10" width={110} type="default" disabled>
            {strings.import}
          </UIButton>
          <UIButton width={110} className="btn-pd-export" disabled>
            {strings.export}
          </UIButton>
        </div>

        <OriginalProduct />
      </ContentWrapper>
    );
  }
}

export default compose(withRouter)(AllProducts);

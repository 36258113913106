import React, {useContext, useState, useEffect, useRef} from "react";
import {Table, Input, DatePicker, Form, Select, Badge, Menu, Dropdown, Space, Button} from "antd";
import {DownOutlined} from "@ant-design/icons";
import {SuggestionField} from "~/views/presentation/ui/fields";
import strings from "~/localization";
import _ from "lodash";
import moment from "moment";
import {isNullOrEmpty, getArray, getString} from "~/views/utilities/helpers/utilObject";
import UtilDate from "~/views/utilities/helpers/UtilDate";
import ModelImages from "./ModelImages";
import {currencyFormat, numberFormatWithPoint} from "~/views/utilities/helpers/currency";
import TableExpand from "./TableExpand";
import styled from "styled-components";
const EditableContext = React.createContext();

const {Option} = Select;

const TableWrapStyled = styled.div`
   .even-row-styled {
      background: #f5f5f5;
   }
   .expanded-row-styled {
      background: #d9f7be;
      font-weight: 550;
   }
`;

const generateAutoKey = (dataSource) => {
   return getArray(dataSource, undefined, []).map((item, index) => ({
      ...item,
      key: index,
   }));
};

class TableExpandSelectData extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         // dataSource: generateAutoKey(props.dataSource),
         sortedInfo: null,
         expandList: [],
      };
   }

   componentWillReceiveProps(nextProps) {
      // if (this.state.dataSource !== nextProps.dataSource) {
      //    this.setState({dataSource: generateAutoKey(nextProps.dataSource)});
      // }
   }

   clearFilters = () => {
      this.setState({filteredInfo: null});
   };
   clearAll = () => {
      this.setState({
         filteredInfo: null,
         sortedInfo: null,
      });
   };

   onExpand = (expanded, record) => {
      let {expandList} = this.state;
      if (expanded) {
         // true =?> add
         this.setState({expandList: [...expandList, record.key]});
      } else {
         // // false => delete
         _.remove(expandList, (item) => item === record.key);
         this.setState({expandList});
      }
   };

   rowClassName = (r) => {
      if (!r) return;
      const {selectable = false, onSelect, selectedKey} = this.props;
      let classNameRow = `row-styled editable-row 
       ${selectedKey && selectedKey === r.key ? "selected-row" : ""} 
       ${selectable ? "selectable-row" : ""}`;
      if (r.key % 2 !== 0) {
         // odd
      } else {
         // even
         classNameRow += ` even-row-styled `;
      }
      if (_.findIndex(this.state.expandList, (key) => key === r.key) != -1) classNameRow += `expanded-row-styled`;
      return classNameRow;
   };

   render() {
      const {selectable = false, scroll, dataSource, onSelect, selectedKey, editable = true, columns} = this.props;

      let {sortedInfo, filteredInfo} = this.state;
      sortedInfo = sortedInfo || {};
      filteredInfo = filteredInfo || {};

      /**
       * ROW SELECTION
       */
      const rowSelection = {
         onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, "selectedRows: ", selectedRows);
         },
         onSelect: (record, selected, selectedRows) => {
            console.log("render -> record, selected, selectedRows", record, selected, selectedRows);
         },
         onSelectAll: (selected, selectedRows, changeRows) => {
            console.log("render -> selected, selectedRows, changeRows", selected, selectedRows, changeRows);
         },
      };

      /**
       * EXPAND
       */
      // const expandedRowRender = () => {
      //    return <TableExpand />;
      // };

      return (
         <TableWrapStyled>
            <div className="w-100">
               <div className="overflow-auto">
                  <Table
                     rowClassName={(r) => this.rowClassName(r)}
                     // expandable={{expandedRowRender}}
                     onExpand={this.onExpand}
                     dataSource={dataSource}
                     columns={columns}
                     showSorterTooltip={false}
                     rowSelection={{...rowSelection}}
                     pagination={false}
                     scroll={scroll}
                  />
               </div>
            </div>
         </TableWrapStyled>
      );
   }
}

export default TableExpandSelectData;

import React from "react";
import { compose } from "recompose";
import { PageTitle } from "~/views/presentation/ui/commons";
import strings from "~/localization";
import { ContentWrapper } from "~/views/presentation/ui/container";
import ViewPage from "../components/ViewPage";

class CultivationContent extends React.PureComponent {
  render() {
    return (
      <ContentWrapper>
        <PageTitle title={strings.CULTIVATION_PAGE_TITLE} breadcrumb={false} />
        <ViewPage
          KeyString="CULTIVATION"
          KeyGetApi="CULTIVATION"
          KeyActionApi="cultivation"
        />
      </ContentWrapper>
    );
  }
}

export default CultivationContent;

import {message} from 'antd'
message.config({
	maxCount: 1,
	duration: 2
})

/**
 * CONFIGS
 */
export const API_URL = process.env.DOMAIN
export const CKEDITOR_URL_IMG = ''
export const API_UPLOAD_URL = API_URL + '/services/vslfiles/api/images'
export const IMAGE_URL = API_URL + '/services/vslfiles/api/images/'
export const API_UPLOAD_FILES_URL = API_URL + '/services/sys/api/v1/files'
export const THUMB_IMG_URL =''

export const sizes = {
	xl: '1200px',
	lg: '992px',
	md: '768px',
	sm: '576px'
};

export const imgUploadConfig = {
	minWidth: 750,
	minHeight: 500,
	fileSize: 5,
	type: /\.(jpg|jpeg|bmp|png|gif|tiff)$/i
};

/**
 * media
 */
export const mediaUploadConfig={
	type: /\.(png|jpg|jpeg|bmp|mp4|gif|tiff)$/i
} 
export const docsFilesUploadConfig={
	type: /\.(xlsb|xls|xlsm|xlsx|doc|docx|pdf|txt|odt|rtf|tex|wks|wpd|ods|odp|pps|ppt|pptx|gz|zip|rar|iso)$/i
} 
export const videoUploadConfig={
	fileSize: 10,
	type: /\.(mp4)$/i
} 
export const docsUploadConfig={
	type: /\.(doc|docx)$/i
} 
export const pdfUploadConfig={
	type: /\.(pdf)$/i
} 
export const excelUploadConfig={
	type: /\.(xlsb|xls|xlsm|xlsx|xlr)$/i
} 
export const txtUploadConfig={
	type: /\.(txt)$/i
}
export const rarUploadConfig={
	type: /\.(zip|rar|iso|gz)$/i
}
export const otherUploadConfig={
	type: /\.(odt|rtf|tex|wks|wpd|ods|odp|pps|ppt|pptx|)$/i
}

export const imageExtension = '.png, .jpg, .jpeg, .bmp'
export const mediaExtension = '.png,.jpg,.jpeg,.bmp,.mp4,.gif,.tiff'
export const filesExtension = '.doc,.docx,,.pdf,.xls,.xlsb,.xlsm,.xlsx,.txt,.odt,.rtf,.tex,.wks,.wpd,.ods,.odp,.pps,.ppt,.pptx,.gz,.zip,.rar,.iso'

export const API_CODE = {
	SUCCESS: 'SUCCESS',
	AUTHENTICATION_INVALID: 'AUTHENTICATION_INVALID',
	UNAUTHORIZED: 'UNAUTHORIZED'
};


export const JWT = 'jwt_' + API_URL
import React from "react";
import { compose } from "recompose";
import { PageTitle } from "~/views/presentation/ui/commons";
import strings from "~/localization";
import { ContentWrapper } from "~/views/presentation/ui/container";
import ViewPage from "../components/ViewPage";

class BusinessCertificate extends React.PureComponent {
  render() {
    return (
      <ContentWrapper>
        <PageTitle title={strings.BUSINESS_CER_PAGE_TITLE} breadcrumb={false} />
        <ViewPage
          KeyString="BUSINESS_CER"
          KeyGetApi="CERTIFICATION"
          KeyActionApi="certification"
        />
      </ContentWrapper>
    );
  }
}

export default BusinessCertificate;

import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import * as PATH from "~/configs/routesConfig";
import AuthorizedRoute from "./AuthorizedRoute";
import { Login } from "~/views/container/AuthPage";
const AppRouter = (props) => {
  return (
    <Router>
      <Switch>
        <Route path={PATH.LOGIN_PATH} exact component={() => <Login />} />
        <Route
          path={PATH.ACCOUNT_RECOVERY_PATH}
          exact
          component={() => <Login />}
        />
        <Route
          path={PATH.RECOVERY_OTP_PATH}
          exact
          component={() => <Login />}
        />
        <Route
          path={PATH.RECOVERY_CHANGE_PASSWORD_PATH}
          exact
          component={() => <Login />}
        />

        {/* <AuthorizedRoute path={PATH.DASHBOARD_PATH} /> */}

        {/* MASTER_DATA */}
        <AuthorizedRoute path={PATH.MASTER_DATA_DASHBOARD} />
        <AuthorizedRoute path={PATH.MASTER_DATA_CATEGORY} />

        <AuthorizedRoute path={PATH.MASTER_DATA_PRODUCT_ALL} />
        <AuthorizedRoute path={PATH.MASTER_DATA_PRODUCT_ADD} />
        <AuthorizedRoute path={PATH.MASTER_DATA_PRODUCT_VIEW} />
        <AuthorizedRoute path={PATH.MASTER_DATA_PRODUCT_EDIT} />

        <AuthorizedRoute path={PATH.MASTER_DATA_BUSINESS_CERTIFICATE} />
        <AuthorizedRoute path={PATH.MASTER_DATA_CERTIFICATION_LAND} />
        <AuthorizedRoute path={PATH.MASTER_DATA_CROPS} />
        <AuthorizedRoute path={PATH.MASTER_DATA_CULTIVATION} />
        <AuthorizedRoute path={PATH.MASTER_DATA_MARKET} />
        <AuthorizedRoute path={PATH.MASTER_DATA_FORM_OF_USES} />

        <AuthorizedRoute path={PATH.FARM_MANAGERMENT_DASHBOARD} />
        <AuthorizedRoute path={PATH.FARM_MANAGERMENT_LIST} />
        <AuthorizedRoute path={PATH.FARM_MANAGERMENT_DETAILS} />
        <AuthorizedRoute path={PATH.SEASON_MANAGERMENT_LIST} />
        <AuthorizedRoute path={PATH.SEASON_MANAGERMENT_DETAILS} />
        <AuthorizedRoute path={PATH.DIARY_MANAGERMENT_LIST} />
        <AuthorizedRoute path={PATH.DIARY_MANAGERMENT_DETAILS} />

        <Redirect to={PATH.MASTER_DATA_DASHBOARD} />
      </Switch>
    </Router>
  );
};

export default AppRouter;

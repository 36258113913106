const apiAction =
  (method = "post") =>
  (type, url, body = {}, withToken = false, async = true, params = {}) => {
    let paramsString = "";
    Object.keys(params).map((m, i) => {
      if (params[m] || params[m] === 0)
        paramsString +=
          (paramsString === "" ? "?" : "&") + m + "=" + params[m].toString();
    });
    return {
      type,
      meta: {
        body,
        method,
        async,
        withToken,
        path: url + paramsString,
      },
    };
  };

export const defaultAction = (type, payload = {}) => ({
  type,
  payload,
});

export default apiAction;

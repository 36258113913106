import React from "react";
import {Select, Spin} from "antd";
import {debounce, isObject} from "lodash";
import {SelectStyle} from "./styles";
import {compose, withState, withHandlers, pure, lifecycle} from "recompose";
import {getArray} from "~/views/utilities/helpers/utilObject";

const SuggestionField = (props) => {
   const {
      renderOpts,
      state,
      mode,
      label,
      help,
      size,
      validatestatus,
      gutterbottom,
      labelCol,
      wrapperCol,
      className,
      filterOption,
      forwardRef,
      optionFilterProp,
      isSearch,
   } = props;
   const {value, labelSelect, disabled} = renderOpts;
   const options = !!state.data
      ? state.data.map((d, index) => {
           return {key: d[index], value: d[value], label: d[labelSelect], disabled: d[disabled]};
        })
      : "";

   return (
      <SelectStyle
         label={label}
         size={size}
         className={className}
         hasFeedback
         validatestatus={validatestatus}
         help={help}
         gutterbottom={gutterbottom}
         labelCol={labelCol}
         wrapperCol={wrapperCol}
         mode={mode}
         ref={forwardRef}
         isSearch={isSearch}
      >
         <Select
            mode={mode}
            showSearch
            ref={forwardRef}
            autoFocus
            defaultValue={state.value}
            value={state.value}
            placeholder={props.placeholder}
            disabled={props.disabled}
            style={props.style}
            onSearch={isSearch ? debounce((value) => props.handleSearch(value), 400) : () => null}
            onChange={props.onChange}
            onBlur={props.onBlur}
            loading={props.loading}
            optionFilterProp={optionFilterProp}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={filterOption}
            allowClear
            openOnFocus
            notFoundContent={state.fetching ? <Spin size="small" /> : null}
            style={{width: "100%"}}
            options={options}
         ></Select>
      </SelectStyle>
   );
};

const initState = {
   data: [],
   value: undefined,
   fetching: false,
};

export default compose(
   pure,
   withState("state", "setState", (props) => (!!props.defaultValue ? props.defaultValue : initState)),
   withHandlers({
      handleSearch: (props) => (value) => {
         props.setState({data: [], fetching: true});
         props.handleFetch(value).then(({res}) => {
            props.setState((state) => ({
               ...state,
               data: getArray(res, undefined, []).map((item) => {
                  return {
                     value: item.id,
                     label: item.name || "",
                  };
               }),
               fetching: false,
            }));
         });
      },
      handleChange: (props) => (value, option) => {
         props.setState((state) => ({...state, value, fetching: false}));
         if (props.handleSelect) {
            props.handleSelect(value, option);
         }
      },
   }),
   lifecycle({
      componentWillReceiveProps(nextProps) {
         if (this.props.data !== nextProps.data) {
            nextProps.setState((state) => ({...state, data: nextProps.data || []}));
         }
         if (this.props.value !== nextProps.value) {
            nextProps.setState((state) => ({...state, value: nextProps.value || []}));
         }
      },
      componentDidUpdate(prevProps) {
         const {setState, defaultValue} = this.props;
         if (prevProps.defaultValue === undefined && isObject(defaultValue)) {
            setState(defaultValue);
         }
      },
      componentDidMount() {
         const {state, setState, data, value} = this.props;

         setState({
            ...state,
            data: data || [],
            value: value,
         });
      },
   })
)(SuggestionField);

export const convertFarm = (farmDetails) => {
  return {
    id: farmDetails.id,
    name: farmDetails.name,
    avatar: farmDetails.avatar,
    phone: farmDetails.phone,
    status: farmDetails.status,
    address: {
      id: farmDetails.address.id,
      address1: farmDetails.address.address1,
      address2: farmDetails.address.address2,
      lat: farmDetails.address.lat,
      lng: farmDetails.address.lng,
      countryId: farmDetails.address.countryId,
      provinceId: farmDetails.address.province.id,
      districtId: farmDetails.address.district.id,
      wardsId: farmDetails.address.wards.id,
      fullName: farmDetails.address.fullName,
      fullAddress: farmDetails.address.fullAddress,
    },
    grossArea: farmDetails.grossArea,
    grossProductivity: farmDetails.grossProductivity,
    grossYield: farmDetails.grossYield,
    farmingSeasonNumber: farmDetails.farmingSeasonNumber,
    businessTypesIds: farmDetails.businessTypes.map((m) => m.id),
    // certifications: farmDetails.certifications,
    // certifycateOfLands: farmDetails.certifycateOfLands,
    certifications: farmDetails.certifications.map((m) => {
      return {
        issuedDate: m.issuedDate,
        expirationDate: m.expirationDate,
        images: m.images,
        reassessmentDate: m.reassessmentDate,
        issuedBy: m.issuedBy,
        typeId: (m.type && m.type.id) || null,
        evaluationDate: m.evaluationDate,
      };
    }),
    certifycateOfLands:
      farmDetails.certifycateOfLands &&
      farmDetails.certifycateOfLands.length >= 0
        ? farmDetails.certifycateOfLands.map((m) => {
            const formOfUsesIds = m.formOfUses
              .filter((f) => f.id)
              .map((m) => m.id);
            return {
              id: m.id,
              landLotNo: m.landLotNo,
              typeId: m.type.id || null,
              areage: {
                unitId: m.areage.unitId,
                value: m.areage.value,
              },
              formOfUsesIds: formOfUsesIds,
              images: m.images,
              ownerId: m.ownerId,
              ownerNameOther: m.ownerNameOther,
            };
          })
        : [],
    products: farmDetails.products,
    consumptionMarket: farmDetails.consumptionMarket,
  };
};

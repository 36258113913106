import {
  ArrowDownOutlined,
  ArrowRightOutlined,
  ArrowUpOutlined,
} from "@ant-design/icons";
import { Divider } from "antd";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import strings from "~/localization";
import { PageTitle } from "~/views/presentation/ui/commons";
import { Color } from "~/views/utilities/layout";

const WrapperCard = styled.div`
  width: 100%;
  margin-top: 5px;
  border-radius: 8px;
  padding: 15px;
  background-color: ${Color.white};
  @media (min-width: 600px) {
    width: 49%;
  }
  span {
    font-size: 14px;
    color: ${Color.gray};
    font-style: italic;
  }
`;

const RightContent = styled.div`
  text-align: right;
  div {
    font-size: 20px;
  }
`;

export const Performance = (props) => {
  const { farmInfor, seasonInfor } = props;
  
  const percentSeason = (seasonInfor.previous.totalFarmingSeason === 0 && seasonInfor.current.totalFarmingSeason !== 0 )? 100 :
  seasonInfor.previous.totalFarmingSeason === seasonInfor.current.totalFarmingSeason ? 0 :
  (seasonInfor.current.totalFarmingSeason - seasonInfor.previous.totalFarmingSeason) / seasonInfor.previous.totalFarmingSeason
  

  return (
    <div className="w-100 row d-flex justify-content-between m-0">
      <WrapperCard>
        <div className="row m-0 w-100 d-flex justify-content-between align-items-end">
          <div>
            <PageTitle title={farmInfor.current.totalFarm} />
            <span>{strings.farm}</span>
          </div>
          <RightContent>
            <div>{farmInfor.current.totalAcreage + " Ha"}</div>
            <span>{strings.cultivated_area}</span>
          </RightContent>
        </div>
        <Divider />
        <div className="row m-0 w-100 d-flex justify-content-between align-items-end">
          <div>
            <PageTitle title={farmInfor.previous.totalFarm} />
            <span>{strings.previos_period}</span>
          </div>
          <RightContent>
            <div>{farmInfor.previous.totalAcreage + " Ha"}</div>

            <span>{strings.previos_period}</span>
          </RightContent>
        </div>
      </WrapperCard>

      <WrapperCard>
        <div className="row m-0 w-100 d-flex justify-content-between ">
          <div>
            <PageTitle title={seasonInfor.current.totalFarmingSeason} />
            <span>{strings.season}</span>
          </div>
          <RightContent>
            <div
              style={{
                color:
                  percentSeason === 0
                    ? Color.black
                    : percentSeason > 0
                    ? Color.green
                    : Color.red,
              }}
            >
              {percentSeason > 0 ? (
                <ArrowUpOutlined />
              ) : percentSeason === 0 ? (
                <></>
              ) : (
                <ArrowDownOutlined />
              )}
              {(percentSeason && percentSeason.toFixed(2)) + " %"}
            </div>
          </RightContent>
        </div>
        <Divider />
        <div className="row m-0 w-100 d-flex justify-content-between align-items-end">
          <div>
            <PageTitle title={seasonInfor.previous.totalFarmingSeason} />
            <span>{strings.previos_period}</span>
          </div>
          <RightContent>
            <div>{seasonInfor.totalFarmingSeason}</div>

            <span>{strings.total_season}</span>
          </RightContent>
        </div>
      </WrapperCard>
    </div>
  );
};

export default connect(null, {})(Performance);

import React from "react";
import strings from "~/localization/vi";
import * as PATH from "~/configs/routesConfig";
import {
  FolderOpenOutlined,
  DashboardOutlined,
  AuditOutlined,
  ProfileOutlined,
  FileProtectOutlined,
  DatabaseOutlined,
  FileAddOutlined,
} from "@ant-design/icons";
import {
  AiOutlineDashboard,
  AiOutlineDatabase,
  AiOutlineFolderOpen,
  AiOutlineShop,
} from "react-icons/ai";
import { TbShovel } from "react-icons/tb";
import { GiFruitTree, GiFarmer, GiFarmTractor } from "react-icons/gi";
import { BsFillSunFill, BsFileEarmarkMedical } from "react-icons/bs";
import { GrDocumentTime } from "react-icons/gr";
import { MdOutlineAgriculture } from "react-icons/md";
import { BiSolidCategory } from "react-icons/bi";

const menus = {
  // overview: {
  //   icon: <AiOutlineDashboard />,
  //   name: strings.MENU_DASHBOARD,
  //   path: PATH.DASHBOARD_PATH,
  // },
  masterData: {
    icon: <AiOutlineDatabase />,
    name: strings.MENU_MASTER_DATA,
    path: PATH.MASTER_DATA_PATH,
    subMenus: {
      masterDataDashboard: {
        icon: <AiOutlineDashboard />,
        name: strings.MENU_DASHBOARD,
        path: PATH.MASTER_DATA_DASHBOARD,
      },
      categories: {
        icon: <BiSolidCategory />,
        name: strings.MENU_CATEGORY,
        path: PATH.MASTER_DATA_CATEGORY,
      },
      products: {
        icon: <AiOutlineFolderOpen />,
        name: strings.MENU_PRODUCT,
        path: PATH.MASTER_DATA_ORIGINAL_PRODUCT,
        subMenus: {
          allProducts: {
            icon: <ProfileOutlined />,
            name: strings.MENU_PRODUCT_LIST,
            path: PATH.MASTER_DATA_PRODUCT_ALL,
          },
          createProduct: {
            icon: <FileAddOutlined />,
            name: strings.MENU_PRODUCT_ADD,
            path: PATH.MASTER_DATA_PRODUCT_ADD,
          },
        },
      },
      business: {
        icon: <AuditOutlined />,
        name: strings.MENU_BUSINESS_CERTIFICATE,
        path: PATH.MASTER_DATA_BUSINESS_CERTIFICATE,
      },
      certificationLand: {
        icon: <FileProtectOutlined />,
        name: strings.MENU_CERTIFICATION_LAND,
        path: PATH.MASTER_DATA_CERTIFICATION_LAND,
      },
      crops: {
        icon: <GiFruitTree />,
        name: strings.MENU_CROPS,
        path: PATH.MASTER_DATA_CROPS,
      },

      cultivation: {
        icon: <MdOutlineAgriculture />,
        name: strings.MENU_CULTIVATION,
        path: PATH.MASTER_DATA_CULTIVATION,
      },
      markets: {
        icon: <AiOutlineShop />,
        name: strings.MENU_MARKETS,
        path: PATH.MASTER_DATA_MARKET,
      },
      formOfUses: {
        icon: <TbShovel />,
        name: strings.MENU_FORM_OF_USES,
        path: PATH.MASTER_DATA_FORM_OF_USES,
      },
    },
  },
  farmManagement: {
    icon: <GiFarmTractor />,
    name: strings.MENU_FARM_MANAGERMENT,
    path: PATH.FARM_MANAGERMENT,
    subMenus: {
      dashboard: {
        icon: <AiOutlineDashboard />,
        name: strings.MENU_DASHBOARD,
        path: PATH.FARM_MANAGERMENT_DASHBOARD,
      },
      farms: {
        icon: <GiFarmer />,
        name: strings.MENU_FARM_LIST,
        path: PATH.FARM_MANAGERMENT_LIST,
      },
      seasons: {
        icon: <BsFillSunFill />,
        name: strings.MENU_SEASON,
        path: PATH.SEASON_MANAGERMENT_LIST,
      },
      diarys: {
        icon: <BsFileEarmarkMedical />,
        name: strings.MENU_DIARY,
        path: PATH.DIARY_MANAGERMENT_LIST.replace(":farmingSeasonId", "all"),
      },
    },
  },
};

export default menus;

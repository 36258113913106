import React from "react";
import styled from "styled-components";
import { Pagination } from "antd";
import Color from "~/views/utilities/layout/color";

const PaginationStyle = styled.div`
  text-align: right;
  .ant-pagination {
    display: flex;
    align-items: center;
  }
  .ant-pagination-item {
    border: none;
    border-radius: 2px;
    min-width: 24px;
    height: 24px;
    line-height: 24px;
    margin-right: 10px;
    &:hover {
      a {
        color: ${Color.green};
      }
    }
  }
  .ant-pagination-item-active {
    background-color: ${Color.green};
    font-weight: bold;
    line-height:24px;
    a,
    &:hover a {
      color: ${Color.white};
    }
  }
  .ant-pagination-prev,
  .ant-pagination-next {
    &:hover {
      .ant-pagination-item-link {
        border-color:${Color.green}
        color: ${Color.green};
      }
    }
  }
  .ant-pagination-next.ant-pagination-disabled,.ant-pagination-prev.ant-pagination-disabled{
    &:hover{
      .ant-pagination-item-link{
        border-color: #d9d9d9;
        color: rgba(0, 0, 0, 0.25);
      }
    }
  }
  .ant-pagination-item-container .ant-pagination-item-link-icon {
    color: ${Color.green};
  }
`;

const UIPagination = (props) => {
  const {
    defaultCurrent,
    total,
    pageSize,
    onChange,
    showSizeChanger = false,
  } = props;

  return (
    <PaginationStyle>
      <Pagination
        current={defaultCurrent}
        total={total}
        pageSize={pageSize}
        onChange={(val) => {
          if (total) onChange(val);
        }}
        showSizeChanger={showSizeChanger}
      />
    </PaginationStyle>
  );
};

export default UIPagination;

import React, { useState } from "react";
import { Card } from "antd";
import strings from "~/localization";
import AvatarUpload from "~/views/presentation/ui/upload/AvatarUpload";
import styled from "styled-components";
const Wrapper = styled.div`
  border-radius: 0.5em;
  overflow: hidden;
  .ant-card-head {
    background: #fafafa;
  }
`;

function Avatar(props) {
  const { imageName, disabled, onChange = () => {} } = props;
  return (
    <Wrapper className="">
      <AvatarUpload
        disabled={disabled}
        avatarUrl={imageName}
        onChange={onChange}
        icon={<span>Avatar</span>}
      />
    </Wrapper>
  );
}

export default Avatar;

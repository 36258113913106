import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { PageTitle } from "~/views/presentation/ui/commons";
import strings from "~/localization";
import { ErrorMessage, Formik } from "formik";
import * as yup from "yup";
import StepHeader from "./StepHeader";
import {
  DatePickerField,
  InputField,
  InputNumber,
} from "~/views/presentation/ui/fields";
import { getFarmDetails } from "~/state/ducks/appApis/actions";

function SeasonInfor(props) {
  const {
    value,
    match,
    handleSetValue,
    setStep,
    items,
    handleUpdate,
    getFarmDetails,
    farm,
    ...rest
  } = props;
  const action = match.params.action;
  const validationSchema = yup.object().shape({});
  const [nextStep, setNextStep] = useState(0);

  return (
    <Formik
      initialValues={{ ...value }}
      validationSchema={action === "view" ? {} : validationSchema}
      onSubmit={(values) => {
        handleSetValue(values);
        setStep(nextStep);
      }}
    >
      {(props) => {
        const { handleSubmit, handleChange, values } = props;
        return (
          <form onSubmit={handleSubmit}>
            <StepHeader
              setStep={(val) => {
                handleSubmit(values);
                setNextStep(val);
              }}
              step={0}
              items={items}
              values={values}
              handleUpdateFarm={handleUpdate}
            />

            <div className="row col-12 p-20">
              <div className="col-12 col-lg-6 col-md-6">
                <InputField
                  required
                  disabled={true}
                  label={strings.farm_name}
                  placeholder={strings.farm_name}
                  inline={false}
                  value={farm && farm.name}
                />
              </div>
              <div className="col-12 col-lg-6 col-md-6">
                <InputField
                  required
                  disabled={action === "view"}
                  label={strings.farm_season_name}
                  onChange={(e) => {
                    handleChange("name")(e.target.value);
                  }}
                  placeholder={strings.product_name}
                  name="name"
                  inline={false}
                  value={values.name}
                />
                <div className="textErr">
                  <ErrorMessage name="name" />
                </div>
              </div>
              <div className="col-12 col-lg-6 col-md-6">
                <DatePickerField
                  required
                  disabled={action === "view"}
                  name="sowingDate"
                  value={(value && value.sowingDate) || undefined}
                  onChange={(val) => {
                    handleChange("sowingDate")(val);
                  }}
                  label={strings.down_seed_date}
                  placeholder={strings.down_seed_date}
                />
                <div className="textErr">
                  <ErrorMessage name="sowingDate" />
                </div>
              </div>
              <div className="col-12 col-lg-6 col-md-6">
                <DatePickerField
                  required
                  disabled={action === "view"}
                  name="harvestDate"
                  value={(value && value.harvestDate) || undefined}
                  onChange={(val) => {
                    handleChange("harvestDate")(val);
                  }}
                  label={strings.expected_harvest_date}
                  placeholder={strings.expected_harvest_date}
                />
                <div className="textErr">
                  <ErrorMessage name="harvestDate" />
                </div>
              </div>
              <div className="col-12 col-lg-6 col-md-6">
                <InputField
                  disabled={true}
                  label={strings.expected_sum_output_today}
                  name="value"
                  value={
                    value &&
                    `${value.grossYieldToday.value} ${
                      value.grossYieldToday.unitName || ""
                    }`
                  }
                  placeholder={strings.expected_sum_output_today}
                  className="w-100"
                />
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
}

export default compose(
  withRouter,
  connect(null, { getFarmDetails }),
)(SeasonInfor);

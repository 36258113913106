import React from "react";
import styled from "styled-components";
import strings from "~/localization";
import LogoIntro from "~/static/images/Logo.svg";
import { Background } from "~/static/images";

const RightContent = styled.div`
  height: 100%;
  width: 100%;
  padding: 10px;
`;
const Container = styled.div`
  border-radius: 44px;
  background-repeat: no-repeat;
  background-position: right;
  align-items: center;
  background-size: cover;
  justify-content: center;
  background-image: url(${Background});
  div img {
    width: 320px;
  }
  .intro {
    margin-top: 30px;
    margin-bottom: 100px;
    p {
      font-weight: 500;
    }
  }
  .copyright {
    display: block;
    position: absolute;
    bottom: 20px;
    right: 0;
    width: 100%;
  }
`;

export default class IntroduceForm extends React.PureComponent {
  render() {
    return (
      <RightContent>
        <Container style={{ height: "100%" }} />
      </RightContent>
    );
  }
}

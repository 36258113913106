import React, { useState } from "react";
import { connect } from "react-redux";
import { compose, withHandlers, lifecycle } from "recompose";
import { withRouter } from "react-router-dom";
import { getAuthUser } from "~/state/ducks/authUser/selectors";
import { authActions } from "~/state/ducks/authUser";
import {
  LOGIN_PATH,
  APP_DEFAULT_PATH,
  DASHBOARD_PATH,
  FARM_HOME_PATH,
  MASTER_DATA_DASHBOARD,
} from "~/configs/routesConfig";

import styled from "styled-components";
import {
  getString,
  getArray,
  getBool,
} from "~/views/utilities/helpers/utilObject";
import strings from "~/localization";
import { Menu } from "antd";
import NavbarFarmBar from "./NavbarFarmBar";
import {
  MailOutlined,
  AppstoreOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Color } from "~/views/utilities/layout";
const { SubMenu } = Menu;
const MenuStyled = styled.ul`
  display: flex;
  height: 45px;
  flex: 1;
  list-style: none;
  overflow: hidden;
  // flex-direction: row;
  padding-left: 20px;
  li {
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    align-items: center;
  }
  li a {
    color: #ffffffe8;
  }
  lia: hover {
    text-decoration: none;
  }
  .active a {
    color: #fff;
    font-weight: 500;
  }
  ul.ant-menu-horizontal {
    border-bottom: none;
  }
  .ant-menu {
    background: none !important;
  }
  li.ant-menu-submenu.ant-menu-submenu-horizontal {
    color: #ffffffe8;
  }
  li.ant-menu-submenu {
    &:hover {
      .ant-menu-submenu-title {
        color: #fff !important;
        font-weight: 500;
        background: #5a845d !important;
      }
    }
  }

  .ant-menu-submenu-selected {
    color: #fff !important;
    font-weight: 500;
  }
`;
const DropDownStyled = styled.a`
  position: relative;
  display: inline-block;
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    top: 45px;
  }
  :hover .dropdown-content {
    display: block;
  }
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  .dropdown-content a span {
    font-size: 14px;
  }
`;
export const MenuSize = {
  small: "small",
  medium: "medium",
  large: "large",
};

function Navbar(props) {
  const { topNav, user, handleLogout, setIsCollapsed, isCollapsed, size } =
    props;

  const handleClick = (e) => {};
  return (
    <header
      className="clearfix"
      style={{ paddingRight: isCollapsed ? 250 : 0 }}
    >
      <div className="branding">
        <a
          role="button"
          tabIndex={0}
          className="offcanvas-toggle visible-xs-inline"
        >
          <i className="fa fa-bars" />
        </a>
      </div>
      {/* Branding end */}

      {/* Search */}
      <div className="search" id="main-search">
        <div className="top-navigation">
          {<h4 className="title">{getString(topNav, "pageName", "")}</h4>}
          {/* <MenuStyled>
            {!getBool(topNav, "isMenu", false) &&
              getArray(topNav, "links", []).map((item, index) => (
                <li key={index} className={index === 0 ? "active" : ""}>
                  <a
                    className={item.disbled && "not-disable"}
                    href={getArray(item, "link", "#")}
                  >
                    {getString(item, "name", "")}
                  </a>
                </li>
              ))}
            {getBool(topNav, "isMenu", false) && (
              <NavbarFarmBar topNav={topNav} />
            )}
          </MenuStyled> */}
        </div>
        <div
          className="list-app"
          onClick={() => {
            setIsCollapsed(!isCollapsed);
          }}
        >
          <i
            className="fa fa-th"
            style={{
              fontSize: "30px",
              color: isCollapsed ? Color.white : Color.grayhover,
            }}
            aria-hidden="true"
          />
        </div>
      </div>
      {/* Search end */}
      {/* Right-side navigation */}
      <ul className="nav-right pull-right list-inline">
        <li className="notification">
          <a className="" data-toggle="dropdown">
            <i className="fa fa-bell" />
          </a>
          <div
            className="dropdown-menu with-arrow panel panel-default animated littleFadeInUp"
            role="menu"
          >
            <div className="panel-footer"></div>
          </div>
        </li>
        <li className="messages">
          <a className="">
            <i className="fa fa-comments" />
          </a>
        </li>
        <li className="notifications">
          <a className="">
            <i className="fa fa-cog" />
          </a>
        </li>
        <li className="nav-profile">
          <DropDownStyled
            className="d-flex justify-content-center align-items-center"
            data-toggle="dropdown"
          >
            <i
              className="fa fa-user-circle"
              style={{ fontSize: "24px" }}
              aria-hidden="true"
            ></i>
            <span>{user.name}</span>
            <div className="dropdown-content" onClick={handleLogout}>
              <a>
                <span>
                  <i className="fa fa-sign-out mr-2" />
                  {strings.sign_out}
                </span>
              </a>
            </div>
          </DropDownStyled>
        </li>
      </ul>
      {/* Right-side navigation end */}
    </header>
  );
}

export default compose(
  withRouter,
  connect(
    (state) => ({
      user: getAuthUser(state),
    }),
    {
      logout: authActions.logout,
    },
  ),
  withHandlers({
    handleLogout:
      ({ logout }) =>
      () => {
        logout();
      },
    handleChangeSize:
      () =>
      ({ onResize, size }) =>
      () => {
        switch (size) {
          case MenuSize.large:
            {
              onResize && onResize(MenuSize.medium);
            }
            break;
          case MenuSize.medium:
            {
              onResize && onResize(MenuSize.small);
            }
            break;
          case MenuSize.small:
            {
              onResize && onResize(MenuSize.large);
            }
            break;
        }
      },
  }),
)(Navbar);

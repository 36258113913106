import React from "react";
import AppContentWrapper from "~/views/presentation/ui/container/AppContentWrapper";
import styled from "styled-components";
import _ from "lodash";
import { Button, Card } from "antd";
import strings from "~/localization";
import { getString } from "~/views/utilities/helpers/utilObject";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";

const ContainerStyled = styled.div`
  display: flex;
  padding: 10px;
  .item-container:hover {
    .disable-p {
      color: red !important;
    }
    .avatar {
      color: #5d9c14 !important;
      border: 1px solid #5d9c14 !important;
    }
  }
  .menu-list {
    width: 1092px;
    @media (max-width: 600px) {
      width: 100%;
      margin-top: 46px;
    }
    .ant-card-bordered {
      border: 1px solid #e9e9e9;
    }
    background: #fff;
    display: flex;
    flex-wrap: wrap;
    padding: 30px;
    .ant-card {
      margin: 5px;
      width: 330px !important;
      @media (max-width: 600px) {
        width: 100% !important;
      }
      .ant-card-body {
        cursor: pointer;
        padding: 10px;
        display: flex;
        align-items: center;
        div:first-child {
          height: 60px;
          width: 60px;
          margin: 10px;
          border-radius: 50%;
          border: 1px solid #f0f0f0;
          display: flex;
          justify-content: center;
          align-items: center;
          i {
            font-size: 25px;
          }
        }
        div:last-child {
          flex: 1;
        }
      }
    }
  }
  .disable {
    background-color: #e9e9e9 !important;
  }
`;

const ButtonStyled = styled(Button)`
  height: 24px !important;
  padding: 0px 16px !important;
  border-radius: 3px !important;
  background-color: #5d9c14 !important;
`;

class Dashboard extends React.Component {
  render() {
    const {} = this.props;
    return (
      <React.Fragment>
        <div className="menu-list">Tính năng đang được mockup</div>
      </React.Fragment>
    );
  }
}

export default compose(withRouter)(Dashboard);

import React from "react";
import { Upload } from "antd";
import { imgUploadConfig } from "~/configs";

import { CloseCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { API_UPLOAD_URL, IMAGE_URL } from "~/configs";
import strings from "~/localization";
import { getString, getNumber } from "~/views/utilities/helpers/utilObject";
import { message } from "antd";

const UploadStyled = styled(Upload)`
  .ant-upload.ant-upload-select-picture-card {
    display: table;
    float: left;
    width: 100%;
    height: 94px;
    margin-right: 0px;
    margin-bottom: 0px;
    text-align: center;
    vertical-align: top;
    background-color: #fafafa;
    border: 1px dashed #d9d9d9;
    border-radius: 2px;
    cursor: pointer;
    -webkit-transition: border-color 0.3s ease;
    transition: border-color 0.3s ease;
  }
`;

const ContainerStyled = styled.div`
  #infinite-carousel {
    button i {
      border-width: 0 3px 3px 0 !important;
    }
    .InfiniteCarouselFrame ul li {
      border: 1px solid #e9e9e9;
    }
  }
`;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}
function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error(strings.invalid_image_upload);
  }
  const isLt10M = file.size / 1024 / 1024 < imgUploadConfig.fileSize;

  if (!isLt10M) {
    message.error(
      strings.formatString(strings.image_max_size, {
        size: imgUploadConfig.fileSize,
      }),
    );
  }
  return isJpgOrPng && isLt10M;
}
export default class LicenceUploader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  handleChange = (info) => {
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      if (info.fileList) {
        this.props.onChange(
          info.fileList[info.fileList.length - 1].response[0].pathImage,
        );
      }
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, () =>
        this.setState({
          // imageUrl,
          loading: false,
        }),
      );
    }
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    let tempt = getString(nextProps, "images.img", "a");
    let index = getNumber(nextProps, "images.index", 0);
    let img = tempt.split("|");
    this.setState({ imageUrl: `${img[index]}` });
    if (!img[index]) this.setState({ imageUrl: "" });
    if (img[index] === "a") this.setState({ imageUrl: "" });
  }

  render() {
    const { disabled } = this.props;

    const uploadButton = (
      <div>
        {this.state.loading ? (
          <LoadingOutlined />
        ) : (
          <div>
            <i className="fa fa-upload" style={{ fontSize: "24px" }} />

            <div className="ant-upload-text">{this.props.label}</div>
          </div>
        )}
      </div>
    );
    const { imageUrl } = this.state;
    return (
      <ContainerStyled>
        <div
          className="d-flex justify-content-center"
          style={{ marginBottom: "20px" }}
        >
        {imageUrl && <CloseCircleOutlined onClick={()=>{{
          this.props.onChange("")
        }}
        }
          style={{position:'absolute',top:'3px', right:'18px'}}/>}
          <UploadStyled
            disabled={disabled}
            name="files"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={API_UPLOAD_URL}
            beforeUpload={beforeUpload}
            onChange={this.handleChange}
          >
             
            {!imageUrl ? (
              uploadButton
            ) : (
              <img
                src={`${IMAGE_URL}${imageUrl}`}
                alt="avatar"
                style={{ height: "90px" }}
              />
            )}
          </UploadStyled>
        </div>
      </ContainerStyled>
    );
  }
}

import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Color } from "~/views/utilities/layout";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from "recharts";
import strings from "~/localization";
import { TitleCustom } from "~/views/presentation/ui/dashboard";
import moment from "moment";
import { formatShortNumber } from "~/views/utilities/helpers/formatNumber";

const WrapperChart = styled.div`
  background-color: ${Color.white};
  padding: 10px;
  border-radius: 8px;
  position : relative;
  .Xaxis {
    color:red;
    position:sticky;
    bottom: 0;
    left: 0;
  }
`;

export const LineChartCustom = (props) => {
  const {
    width = props.data.length < 10 ? 520 : props.data.length * 50,
    data,
    name = [],
    title = "",
    xTitle = strings.date_activity,
    yTitle = strings.value,
    unit="",
    margin={ top: 15, right: 20, bottom: 15, left: 0 }
  } = props;

  const tickFormatter = (tick) => moment(tick).format('DD-MM-YYYY')
  const numberFormatter = (number)=>formatShortNumber(number);
  
  return (
    <WrapperChart>
      <TitleCustom title={strings.chart + " " + title} />
      <div style={{ width: "100%", overflowX: "auto" }}>
        <LineChart
          width={width}
          height={300}
          data={data}
          margin={margin}
        >
          {name.map((m) => {
            return (
              <Line
                dataKey={m}
                dot={{ stroke: Color.blue, strokeWidth: 3 }}
                strokeWidth={3}
              />
            );
          })}
          <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
          <XAxis
            dataKey="date"
            scale="time"
            type="number"
            tickFormatter={tickFormatter}
            domain={[]}
          />

          <YAxis unit={unit} tickFormatter={numberFormatter}/>
          <Tooltip
            content={({ active, payload, label }) => {
              if (active && payload && payload.length) {
                return (
                  <div
                    style={{
                      backgroundColor: Color.white,
                      border: "solid 1px gray",
                      padding: "10px",
                      borderRadius: "8px",
                    }}
                  >
                    <span style={{ display: "block" }}>
                      {label && new Date(label).toLocaleDateString("vi-VN") || ''}
                    </span>
                    <span
                      style={{ display: "block", color: Color.blue }}
                    >{`${title} : ${payload[0].value}`}</span>
                  </div>
                );
              }
              return null;
            }}
          />
        </LineChart>
      </div>
    </WrapperChart>
  );
};

export default connect(null, {})(LineChartCustom);

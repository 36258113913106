import React from "react";
import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import {
  getStringWithWord,
  countWords,
} from "~/views/utilities/helpers/string";

const limitWord = 10;

const UIBreadcrumb = ({ path }) => {
  return (
    <Breadcrumb separator=">">
      <Breadcrumb.Item index={-1}>
        {/* <Link to="/dashboard">Trang chủ</Link> */}
      </Breadcrumb.Item>
      {path !== undefined &&
        path.length > 0 &&
        path.map((item, index) => {
          const noLink = item.noLink ? true : false;
          if (index <= path.length - 1) {
            const title =
              countWords(item.title) <= limitWord
                ? item.title
                : `${getStringWithWord(item.title, limitWord)}...`;

            return index < path.length - 1 ? (
              <Breadcrumb.Item
                key={index}
                style={{ cursor: item.link ? "pointer" : "default" }}
              >
                {noLink ? title : <Link to={`${item.href}`}>{title}</Link>}
              </Breadcrumb.Item>
            ) : (
              <Breadcrumb.Item
                key={index}
                style={{ cursor: item.link ? "pointer" : "default" }}
              >
                {title}
              </Breadcrumb.Item>
            );
          } else return "";
        })}
    </Breadcrumb>
  );
};

UIBreadcrumb.propTypes = {
  path: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      link: PropTypes.string,
    }),
  ),
};

export default UIBreadcrumb;

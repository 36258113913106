import { Input } from "antd";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import COLOR from "../../../utilities/layout/color";
import { ErrorMessage } from "formik";

export const InputFieldIcon = (props) => {
  const InputStyles = styled.div`
  height: 50px;
  margin-top:2px;
  margin-bottom:2px;
  display: flex;
  border-radius:40px;
  background-Color:${COLOR.gray_02}
  justify-content: center;
  align-items: center;
  padding-left: 18px;
  padding-right: 18px;
  .ant-input {
    border: 0px !important;
  background-Color:${COLOR.gray_02} !important;

  }
  .ant-input-affix-wrapper{
    border: 0px !important;
  }
  
  .anticon{
    margin-right:6px;
  }
`;

  const {
    iconStart,
    style,
    placeholder,
    onChange,
    defaultValue,
    name,
    type = "text",
  } = props;

  const textInput = (
    <Input
      onChange={(val) => {
        onChange(val.target.value);
      }}
      style={{
        height: "80%",
        backgroundColor: COLOR.gray_02,
      }}
      placeholder={placeholder}
    />
  );

  const textInputPassword = (
    <Input.Password
      // onChange={(val) => onChange(val.target.value)}
      onChange={(val) => {
        console.log(val.target.value, 123);
        onChange(val.target.value);
      }}
      style={{
        height: "80%",
        backgroundColor: COLOR.gray_02,
      }}
      placeholder={placeholder}
    />
  );

  return (
    <>
      <InputStyles style={{ ...style }}>
        {iconStart}
        {type === "text" && textInput}
        {type === "password" && textInputPassword}
      </InputStyles>
      <ErrorMessage name={name} />
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(InputFieldIcon);

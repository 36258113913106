import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { PageTitle } from "~/views/presentation/ui/commons";
import strings from "~/localization";
import { ErrorMessage, Formik } from "formik";
import * as yup from "yup";
import StepHeader from "./StepHeader";
import {
  InputField,
  InputNumber,
  SelectField,
} from "~/views/presentation/ui/fields";
import { Tabs } from "antd";
import { TableEditable } from "~/views/presentation/ui/tables";
import { numberFormatDecimal } from "~/views/utilities/helpers/currency";
import { UIButton } from "~/views/presentation/ui/buttons";
const { TabPane } = Tabs;

const defaultValue = {
  categoryId: null,
  supplierId: null,
  materialText: "",
  quantity: null,
  unitId: null,
  unitNameOther: null,
};

function Material(props) {
  const {
    value,
    match,
    handleSetValue,
    setStep,
    items,
    handleUpdate,
    unitsMass,
    category,
    manufacturer,
  } = props;
  const action = match.params.action;
  const validationSchema = yup.object().shape({});
  const [nextStep, setNextStep] = useState(0);
  const [currentValue, setCurrentValue] = useState(defaultValue);

  const handleClick = (val) => {
    setCurrentValue(val);
  };

  const columns = [
    {
      title: strings.ORIGINAL_PRODUCT_TABLE_COLUMN_CATEGORIES,
      dataIndex: "categoryId",
      editable: false,
      align: "center",
      render: (cell, row) => {
        const value = category.find((f) => f.value === cell);
        return (
          <div onClick={() => handleClick(row)}>
            {(value && value.label) || "-"}
          </div>
        );
      },
    },
    {
      title: strings.supplier,
      dataIndex: "supplierId",
      editable: false,
      align: "center",
      render: (cell, row) => {
        const value = manufacturer.find((f) => f.value === cell);
        return (
          <div onClick={() => handleClick(row)}>
            {(value && value.label) || "-"}
          </div>
        );
      },
    },
    {
      title: strings.material_name,
      dataIndex: "materialText",
      editable: false,
      align: "center",
    },
    {
      title: strings.count,
      dataIndex: "quantity",
      editable: false,
      align: "center",
      render: (cell, row) => {
        return (
          <div onClick={() => handleClick(row)}>
            {numberFormatDecimal(
              cell,
              (row.unitNameOther && row.unitNameOther) || "",
            )}
          </div>
        );
      },
    },
  ];
  return (
    <Formik
      initialValues={{ materials: value }}
      validationSchema={action === "view" ? {} : validationSchema}
      onSubmit={(values) => {
        handleSetValue(values);
        setStep(nextStep);
      }}
    >
      {(props) => {
        const { handleSubmit, handleChange, values } = props;
        return (
          <form onSubmit={handleSubmit}>
            <StepHeader
              setStep={(val) => {
                handleSubmit(values);
                setNextStep(val);
              }}
              step={2}
              items={items}
              values={values}
              handleUpdateFarm={handleUpdate}
            />
            <div className="row p-10">
              <div className="row col-12 p-20">
                <SelectField
                  disabled={action === "view"}
                  className="col-12 col-md-6 col-lg-6"
                  value={currentValue ? currentValue.categoryId : undefined}
                  onChange={(val) => {
                    setCurrentValue({
                      ...currentValue,
                      categoryId: val,
                    });
                  }}
                  placeholder={strings.ORIGINAL_PRODUCT_TABLE_COLUMN_CATEGORIES}
                  data={(category.length && category) || []}
                  label={strings.ORIGINAL_PRODUCT_TABLE_COLUMN_CATEGORIES}
                />
                <SelectField
                  disabled={action === "view"}
                  className="col-12 col-md-6 col-lg-6"
                  value={(currentValue && currentValue.supplierId) || undefined}
                  onChange={(val) => {
                    setCurrentValue({
                      ...currentValue,
                      supplierId: val,
                    });
                  }}
                  placeholder={strings.supplier}
                  data={(manufacturer.length && manufacturer) || []}
                  label={strings.supplier}
                />
                <div className="col-12 col-lg-6 col-md-6">
                  <InputField
                    required
                    disabled={action === "view"}
                    label={strings.material_name}
                    onChange={(e) => {
                      setCurrentValue({
                        ...currentValue,
                        materialText: e.target.value,
                      });
                    }}
                    placeholder={strings.material_name}
                    name="name"
                    inline={false}
                    value={
                      (currentValue && currentValue.materialText) || undefined
                    }
                  />
                </div>
                <div className="row col-12 col-md-6 col-lg-6 pr-0">
                  <div className="col-6">
                    <InputNumber
                      required
                      disabled={action === "view"}
                      label={strings.count}
                      name="value"
                      value={
                        (currentValue && currentValue.quantity) || undefined
                      }
                      onChange={(val) => {
                        setCurrentValue({
                          ...currentValue,
                          quantity: val,
                        });
                      }}
                      placeholder={strings.count}
                      className="w-100"
                    />
                  </div>
                  <div className="col-6 p-0">
                    <SelectField
                      required
                      disabled={action === "view"}
                      className="w-100"
                      value={(currentValue && currentValue.unitId) || undefined}
                      onChange={(val) => {
                        setCurrentValue({
                          ...currentValue,
                          unitId: val,
                          unitNameOther: unitsMass.find((f) => f.value === val)
                            .label,
                        });
                      }}
                      placeholder={strings.unit}
                      data={unitsMass}
                      label={strings.unit}
                    />
                  </div>
                </div>
              </div>
              <div className="w-100 pr-20 pl-30 ">
                <UIButton
                  type="primary"
                  className="btn-pd-add "
                  disabled={
                    !currentValue ||
                    !(
                      currentValue.materialText &&
                      currentValue.quantity &&
                      currentValue.unitId
                    )
                  }
                  onClick={() => {
                    if (currentValue.key || currentValue.key === 0) {
                      let material = values.materials;
                      material[currentValue.key] = currentValue;
                      setCurrentValue(defaultValue);
                      handleChange("materials")(material);
                    } else {
                      handleChange("materials")([
                        ...values.materials,
                        currentValue,
                      ]);
                      setCurrentValue(defaultValue);
                    }
                  }}
                >
                  {strings.save}
                </UIButton>
              </div>
              <div className="col-12 mt-3">
                <Tabs defaultActiveKey="1" type="card" size={"middle"}>
                  <TabPane tab={strings.gcn_qsdd} key="1">
                    <TableEditable
                      changedData={(index) => {
                        handleChange("materials")(
                          values.materials.filter((f, i) => i !== index),
                        );
                      }}
                      editable={action !== "view"}
                      addRowAble={true}
                      onSelect={(val) => {
                        handleClick(val);
                      }}
                      selectable={true}
                      dataSource={values.materials}
                      columns={columns}
                    />
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
}

export default compose(withRouter, connect(null, {}))(Material);

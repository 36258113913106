import React from "react";
import * as PATH from "~/configs/routesConfig";
import Dashboard from "~/views/container/Dashboard";
import FarmManagement from "~/views/container/FarmManagement";
import MasterData from "~/views/container/MasterData";

export default [
  //DASH BOARD
  {
    path: PATH.DASHBOARD_PATH,
    component: () => <Dashboard />,
    exact: true,
  },
  //DASH BOARD

  //MASTER DATA
  {
    path: PATH.MASTER_DATA_DASHBOARD,
    component: () => <MasterData />,
    exact: true,
  },
  {
    path: PATH.MASTER_DATA_CATEGORY,
    component: () => <MasterData />,
    exact: true,
  },
  {
    path: PATH.MASTER_DATA_PRODUCT_ALL,
    component: () => <MasterData />,
    exact: true,
  },
  {
    path: PATH.MASTER_DATA_PRODUCT_ADD,
    component: () => <MasterData />,
    exact: true,
  },
  {
    path: PATH.MASTER_DATA_PRODUCT_EDIT,
    component: () => <MasterData />,
    exact: true,
  },
  {
    path: PATH.MASTER_DATA_PRODUCT_VIEW,
    component: () => <MasterData />,
    exact: true,
  },
  {
    path: PATH.MASTER_DATA_BUSINESS_CERTIFICATE,
    component: () => <MasterData />,
    exact: true,
  },
  {
    path: PATH.MASTER_DATA_CERTIFICATION_LAND,
    component: () => <MasterData />,
    exact: true,
  },
  {
    path: PATH.MASTER_DATA_CROPS,
    component: () => <MasterData />,
    exact: true,
  },
  {
    path: PATH.MASTER_DATA_CULTIVATION,
    component: () => <MasterData />,
    exact: true,
  },
  {
    path: PATH.MASTER_DATA_MARKET,
    component: () => <MasterData />,
    exact: true,
  },
  {
    path: PATH.MASTER_DATA_FORM_OF_USES,
    component: () => <MasterData />,
    exact: true,
  },
  //MASTER DATA

  //FARM MANAGERMENT
  {
    path: PATH.FARM_MANAGERMENT_DASHBOARD,
    component: () => <FarmManagement />,
    exact: true,
  },
  {
    path: PATH.FARM_MANAGERMENT_LIST,
    component: () => <FarmManagement />,
    exact: true,
  },
  {
    path: PATH.FARM_MANAGERMENT_DETAILS,
    component: () => <FarmManagement />,
    exact: true,
  },
  {
    path: PATH.SEASON_MANAGERMENT_LIST,
    component: () => <FarmManagement />,
    exact: true,
  },
  {
    path: PATH.SEASON_MANAGERMENT_DETAILS,
    component: () => <FarmManagement />,
    exact: true,
  },
  {
    path: PATH.DIARY_MANAGERMENT_LIST,
    component: () => <FarmManagement />,
    exact: true,
  },
  {
    path: PATH.DIARY_MANAGERMENT_DETAILS,
    component: () => <FarmManagement />,
    exact: true,
  },
  //FARM MANAGERMENT
];

import React from "react";
import strings from "~/localization";
import { ContentWrapper } from "~/views/presentation/ui/container";
import { compose, lifecycle, withHandlers, withState } from "recompose";
import * as yup from "yup";
import { withRouter } from "react-router-dom";
import { UIButton } from "~/views/presentation/ui/buttons";
import {
  getFarmDetails,
  getUnits,
  getMasterData,
  updateFarm,
} from "~/state/ducks/appApis/actions";
import { connect } from "react-redux";
import { showError } from "~/configs/ServerErrors";
import _ from "lodash";
import * as PATH from "~/configs/routesConfig";
import { Spin, message } from "antd";
import FarmInfor from "./FarmInfor";
import Certification from "./Certification";
import CertificationLand from "./CertificationLand";
import { convertFarm } from "./convertFarm";
import Manufacturing from "./Manufacturing";
import Markets from "./Markets";

class FarmDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      action: this.props.match.params.action || "view",
      id: this.props.match.params.id || null,
    };
  }

  handleSubmit = () => {
    const {
      // values,
      // createProduct,
      // setSubmitting,
      // isEdit,
      // updateProduct,
      // history,
    } = this.props;
  };
  renderTitlePage = () => {
    switch (this.state.action) {
      case "view":
        return strings.farm_page_title_view;
      case "edit":
        return strings.farm_page_title_edit;
      default:
        const { history } = this.props;
        history.push(PATH.FARM_MANAGERMENT_LIST);
        return;
    }
  };

  renderButtons = () => {
    const { isEdit, isView, isSubmitting, isValid, values, history } =
      this.props;
    if (isEdit) {
      return (
        <>
          <UIButton
            type="primary"
            className="btn-pd-add"
            loading={isSubmitting}
            disabled={!isValid || !values.categoryId}
            onClick={this.handleSubmit}
          >
            {strings.product_page_update_button}
          </UIButton>

          <UIButton
            type="secondary"
            htmlType="button"
            loading={isSubmitting}
            className="ml-5"
            onClick={() => history.push(PATH.MASTER_DATA_PRODUCT_ALL)}
          >
            {strings.CANCELED}
          </UIButton>
        </>
      );
    } else if (isView) {
      return (
        <UIButton
          type="secondary"
          htmlType="button"
          loading={isSubmitting}
          onClick={() => history.push(PATH.MASTER_DATA_PRODUCT_ALL)}
          width={200}
        >
          {strings.product_page_back_button}
        </UIButton>
      );
    }
    return (
      <UIButton
        type="primary"
        className="btn-pd-add"
        loading={isSubmitting}
        disabled={!isValid || !values.categoryId}
        onClick={this.handleSubmit}
      >
        {strings.add_new_product}
      </UIButton>
    );
  };
  render() {
    const {
      loading,
      step,
      setStep,
      values,
      unitsArea,
      unitsMass,
      markets,
      formOfUses,
      certification,
      product,
      cultivation,
      certificationLand,
      handleSetValue,
    } = this.props;

    //list item
    const items = [
      {
        id: 0,
        title: strings.farm_info,
      },
      {
        id: 1,
        title: strings.crops,
      },
      {
        id: 2,
        title: strings.consumption_market,
      },
      {
        id: 3,
        title: strings.gcn_standard,
      },
      {
        id: 4,
        title: strings.gcn_qsdd,
      },
    ];
    const renderContent = () => {
      switch (step) {
        case 0:
          return (
            <FarmInfor
              items={items}
              setStep={setStep}
              value={values}
              unitsArea={unitsArea}
              cultivation={cultivation}
              action={this.state.action}
              handleSetValue={handleSetValue}
              handleUpdateFarm={handleUpdateFarm}
            />
          );
        case 1:
          return (
            <Manufacturing
              items={items}
              setStep={setStep}
              value={values.products}
              action={this.state.action}
              product={product}
              unitsArea={unitsArea}
              unitsMass={unitsMass}
              handleSetValue={handleSetValue}
              handleUpdateFarm={handleUpdateFarm}
            />
          );
        case 2:
          return (
            <Markets
              items={items}
              setStep={setStep}
              value={values.consumptionMarket}
              action={this.state.action}
              markets={markets}
              handleSetValue={handleSetValue}
              handleUpdateFarm={handleUpdateFarm}
            />
          );
        case 3:
          return (
            <Certification
              items={items}
              setStep={setStep}
              value={values.certifications}
              certification={certification}
              action={this.state.action}
              handleSetValue={handleSetValue}
              handleUpdateFarm={handleUpdateFarm}
            />
          );
        case 4:
          return (
            <CertificationLand
              items={items}
              setStep={setStep}
              value={values.certifycateOfLands}
              certificationLand={certificationLand}
              action={this.state.action}
              unitsArea={unitsArea}
              formOfUses={formOfUses}
              handleSetValue={handleSetValue}
              handleUpdateFarm={handleUpdateFarm}
            />
          );
        default:
          break;
      }
    };

    const { history, match, handleUpdateFarm } = this.props;
    return loading ? (
      <div
        style={{ height: "500px" }}
        className="w-100 d-flex justify-content-center align-items-center"
      >
        <Spin tip="Loading" size="large" />
      </div>
    ) : (
      <ContentWrapper>
        <div
          style={{
            minHeight: "80vh",
            borderRadius: "3px",
            position: "relative",
          }}
          className="w-100 bg-white"
        >
          {renderContent()}
        </div>
      </ContentWrapper>
    );
  }
}

export default compose(
  withRouter,
  connect(null, {
    getFarmDetails,
    getUnits,
    getMasterData,
    updateFarm,
  }),
  withState("unitsMass", "setUnitsMass", []),
  withState("unitsArea", "setUnitsArea", []),
  withState("markets", "setMarkets", []),
  withState("cultivation", "setCultivation", []),
  withState("product", "setProduct", []),
  withState("certification", "setCertification", []),
  withState("certificationLand", "setCertificationLand", []),
  withState("formOfUses", "setFormOfUses", []),
  withState("values", "setValues"),
  withState("loading", "setLoading", true),
  withState("step", "setStep", 0),

  withHandlers({
    handleSetValue: (props) => (value) => {
      const { setValues, values } = props;
      setValues({ ...values, ...value });
    },
    handleUpdateFarm: (props) => (body) => {
      const { updateFarm, setLoading, values, history } = props;
      setLoading(true);
      updateFarm({ ...values, ...body })
        .then((res) => {
          message.success(strings.farm_update_message_success);
          history.push(PATH.FARM_MANAGERMENT_LIST);
        })
        .catch((err) => {
          message.error(strings.farm_update_message_err);
        })
        .finally(() => setLoading(false));
    },
  }),
  lifecycle({
    componentDidMount() {
      const {
        getFarmDetails,
        history,
        match,
        setValues,
        setLoading,
        getUnits,
        getMasterData,
        setUnitsMass,
        setUnitsArea,
        setMarkets,
        setCertification,
        setFormOfUses,
        setCertificationLand,
        setCultivation,
        setProduct,
      } = this.props;
      const id = match.params.id;
      if (id) {
        setLoading(true);
        getFarmDetails(id)
          .then((res) => {
            setValues(convertFarm(res.res));
          })
          .catch((err) => {
            showError(err);
            history.push(PATH.FARM_MANAGERMENT_LIST);
          })
          .finally(() => setLoading(false));

        //get mass
        getUnits("MASS")
          .then((res) => {
            setUnitsMass(
              res.res.map((m) => {
                return {
                  label: m.shortName,
                  value: m.id,
                };
              }),
            );
          })
          .catch((err) => console.log(err));
        getUnits("ACREAGE")
          .then((res) => {
            setUnitsArea(
              res.res.map((m) => {
                return {
                  label: m.shortName,
                  value: m.id,
                };
              }),
            );
          })
          .catch((err) => console.log(err));
        getMasterData({ type: "MARKET" })
          .then((res) => {
            setMarkets(
              res.res.map((m) => {
                return {
                  label: m.name,
                  value: m.id,
                };
              }),
            );
          })
          .catch((err) => console.log(err));
        getMasterData({ type: "CERTIFICATION" })
          .then((res) => {
            setCertification(
              res.res.map((m) => {
                return {
                  label: m.name,
                  value: m.id,
                };
              }),
            );
          })
          .catch((err) => console.log(err));
        getMasterData({ type: "CERTIFICATION_LAND" })
          .then((res) => {
            setCertificationLand(
              res.res.map((m) => {
                return {
                  label: m.name,
                  value: m.id,
                };
              }),
            );
          })
          .catch((err) => console.log(err));
        getMasterData({ type: "FORM_OF_USES" })
          .then((res) => {
            setFormOfUses(
              res.res.map((m) => {
                return {
                  label: m.name,
                  value: m.id,
                };
              }),
            );
          })
          .catch((err) => console.log(err));
        getMasterData({ type: "CULTIVATION" })
          .then((res) => {
            setCultivation(
              res.res.map((m) => {
                return {
                  label: m.name,
                  value: m.id,
                };
              }),
            );
          })
          .catch((err) => console.log(err));

        getMasterData({ type: "PRODUCT" })
          .then((res) => {
            setProduct(
              res.res.map((m) => {
                return {
                  label: m.name,
                  value: m.id,
                };
              }),
            );
          })
          .catch((err) => console.log(err));
      } else {
        history.push(PATH.FARM_MANAGERMENT_LIST);
      }
    },
  }),
)(FarmDetails);

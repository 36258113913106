import React, { useEffect, useState } from "react";
import strings from "~/localization";
import {
  InputField,
  InputNumber,
  SelectField,
} from "~/views/presentation/ui/fields";
import Avatar from "./Avatar";
import AddressField from "~/views/presentation/ui/fields/AddressField";
import { Formik, ErrorMessage } from "formik";
import * as yup from "yup";
import {
  stringRequiredField,
  numberRequired,
} from "~/views/utilities/validation/input";
import "./style.css";
import StepHeader from "./StepHeader";

function FarmInfor(props) {
  const {
    value,
    unitsArea,
    action,
    cultivation,
    handleSetValue,
    items,
    setStep,
    handleUpdateFarm,
  } = props;

  const [nextStep, setNextStep] = useState(0);

  const validationSchema = yup.object().shape({
    name: stringRequiredField(),
    address: yup.object().shape({
      address1: stringRequiredField(),
      provinceId: stringRequiredField(),
      districtId: stringRequiredField(),
      wardsId: stringRequiredField(),
    }),
    grossArea: yup.object().shape({
      value: numberRequired(),
    }),
    businessTypesIds: yup.array().min(1, strings.required),
  });

  return (
    <Formik
      initialValues={{ ...value }}
      validationSchema={action === "view" ? {} : validationSchema}
      onSubmit={(values) => {
        handleSetValue(values);
        setStep(nextStep);
      }}
    >
      {(props) => {
        const { handleSubmit, handleChange, values } = props;
        return (
          <form onSubmit={handleSubmit}>
            <StepHeader
              setStep={(val) => {
                handleSubmit(values);
                setNextStep(val);
              }}
              step={0}
              items={items}
              values={values}
              handleUpdateFarm={handleUpdateFarm}
            />
            <div className="row m-20">
              <div className="col-12 d-md-none d-lg-none d-block mb-10">
                <Avatar
                  disabled={action === "view"}
                  imageName={value.avatar}
                  onChange={(val) => {
                    handleChange("avatar")(val);
                  }}
                />
              </div>
              <div className="col-12 col-lg-6 col-md-6">
                <InputField
                  required
                  disabled={action === "view"}
                  label={strings.farm_name}
                  onChange={(e) => {
                    handleChange("name")(e.target.value);
                  }}
                  placeholder={strings.product_name}
                  name="name"
                  inline={false}
                  value={values.name}
                />
                <div className="textErr">
                  <ErrorMessage name="name" />
                </div>
                <InputField
                  disabled={action === "view"}
                  label={strings.phone}
                  placeholder={strings.phone}
                  name="phone"
                  inline={false}
                  value={values.phone}
                  onChange={(e) => {
                    handleChange("phone")(e.target.value);
                  }}
                />

                <AddressField
                  required
                  values={values.address}
                  disabled={action === "view"}
                  name="address"
                  handleChangeAddress={handleChange}
                />

                <div className="row">
                  <div className="col-6">
                    <InputNumber
                      required
                      disabled={action === "view"}
                      label={strings.sum_acreage}
                      name="value"
                      value={values.grossArea.value}
                      onChange={(val) => {
                        handleChange("grossArea")({
                          ...value.grossArea,
                          value: val,
                        });
                      }}
                      placeholder={strings.sum_acreage}
                      className="w-100"
                    />
                    <div className="textErr">
                      <ErrorMessage name="grossArea.value" />
                    </div>
                  </div>
                  <SelectField
                    required
                    disabled={action === "view"}
                    className="col-6"
                    value={values.grossArea.unitId}
                    onChange={(val) => {
                      handleChange("grossArea")({
                        ...value.grossArea,
                        unitId: val,
                      });
                    }}
                    placeholder={strings.unit}
                    data={unitsArea}
                    label={strings.unit}
                  />
                </div>
                <SelectField
                  required
                  disabled={action === "view"}
                  className="w-100 mt-10"
                  mode="multiple"
                  data={cultivation}
                  value={values.businessTypesIds}
                  onChange={(value) => {
                    handleChange("businessTypesIds")(value);
                  }}
                  iconEnd="caret-down"
                  placeholder={strings.MENU_CULTIVATION}
                  label={strings.MENU_CULTIVATION}
                />
                <div className="textErr">
                  <ErrorMessage name="businessTypesIds" />
                </div>
              </div>
              <div className="d-md-flex d-lg-flex d-none justify-content-center col-6">
                <Avatar
                  imageName={values.avatar}
                  disabled={action === "view"}
                  onChange={(val) => {
                    handleChange("avatar")(val);
                  }}
                />
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
}

export default FarmInfor;

import React from "react";
import PropTypes from "prop-types";
import MediaResponsive from "react-responsive";
import { DatePicker } from "antd";
import RangePickerMobile from "./RangePickerMobile";

const { RangePicker } = DatePicker;

function UIRangePicker(props) {
  const { onChange, ...rest } = props;
  return (
    <React.Fragment>
      {/* desktop only */}
      <MediaResponsive minDeviceWidth={576}>
        <RangePicker
          onChange={(value) => {
            // value = [start,end]
            if (value) {
              const from = new Date(value[0]._d);
              const to = new Date(value[1]._d);
              onChange([
                new Date(from.setHours(7, 0, 0)).toISOString(),
                new Date(to.setHours(30, 59, 59)).toISOString(),
              ]);
            } else onChange([null, null]);
          }}
          {...rest}
        />
      </MediaResponsive>
      <MediaResponsive maxDeviceWidth={576}>
        {/* mobile version */}
        <RangePickerMobile onChange={onChange} {...rest} />
      </MediaResponsive>
    </React.Fragment>
  );
}

UIRangePicker.propType = {
  format: PropTypes.string,
  placeholder: PropTypes.array,
  onChange: PropTypes.func,
};

UIRangePicker.defaultProps = {
  format: "DD/MM/YYYY",
  placeholder: ["Từ", "Đến"],
  onChange: () => {},
};

export default UIRangePicker;

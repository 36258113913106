export const COMMON = 'authUser/COMMON'

export const LOGIN = 'authUser/LOGIN';
export const LOGIN_SUCCESS = 'authUser/LOGIN_SUCCESS';
export const LOGOUT = 'authUser/LOGOUT';

export const REGISTER = 'authUser/REGISTER';
export const REGISTER_SUCCESS = 'authUser/REGISTER_SUCCESS';

export const GET_USER = 'authUser/GET_USER';
export const GET_USER_SUCCESS = 'authUser/GET_USER_SUCCESS';

export const UPDATE_PROFILE = 'authUser/UPDATE_PROFILE';
export const UPDATE_PROFILE_SUCCESS = 'authUser/UPDATE_PROFILE_SUCCESS';

export const VERIFY_OTP = 'authUser/VERIFY_OTP';
export const VERIFY_OTP_SUCCESS = 'authUser/VERIFY_OTP_SUCCESS';







import React, { useEffect, useState } from "react";
import * as PATH from "~/configs/routesConfig";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import {
  getProductionLogDetails,
  getSeasonList,
  getSeasonDetails,
} from "~/state/ducks/appApis/actions";
import { Card, Tabs } from "antd";
import strings from "~/localization";
import { Color } from "~/views/utilities/layout";
import { PageTitle } from "~/views/presentation/ui/commons";
import { InputField } from "~/views/presentation/ui/fields";
import { TableEditable } from "~/views/presentation/ui/tables";
import LicenceUploader from "~/views/presentation/ui/upload/LicencesUpload";

const { TabPane } = Tabs;

function ProductionDetails(props) {
  const {
    match,
    history,
    getProductionLogDetails,
    getSeasonList,
    getSeasonDetails,
  } = props;
  const productionId = match.params.id;

  const [loading, setLoading] = useState(false);
  const [season, setSeason] = useState([]);
  const [productionLog, setProductionLog] = useState();
  const [currentWork, setCurrenWork] = useState();
  useEffect(() => {
    setLoading(true);
    if (Number(productionId)) {
      getProductionLogDetails(productionId)
        .then((res) => {
          setProductionLog(res.res);
          getSeasonDetails(res.res.farmingSeasonId).then((seasonRes) => {
            setSeason(seasonRes.res);
          });
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false));
    } else if (!productionId) {
      history.push(
        PATH.DIARY_MANAGERMENT_LIST.replace(":farmingSeasonId", "all"),
      );
    }
  }, []);

  const columns = [
    {
      title: strings.name,
      dataIndex: "name",

      width: "30%",
      editable: false,
    },
    {
      title: strings.description,
      dataIndex: "description",
      width: "70%",
      editable: false,
    },
  ];

  return (
    <div className="w-100 p-20 bg-white" style={{ minHeight: "500px" }}>
      <div className="row d-flex justify-content-end">
        <div className="col-12 col-lg-6 col-md-6">
          <PageTitle title={strings.DIARY_page_title_view} />
        </div>
        <Card className="col-12 col-lg-6 col-md-6 p-0">
          <div className="w-100">
            <div className="row">
              <div className="row col-12">
                <span style={{ color: Color.gray }} className="col-4">
                  {strings.farm}:
                </span>
                <span
                  className="col-8"
                  style={{ fontWeight: "bold", wordWrap: "break-word" }}
                >
                  {(productionLog && productionLog.farmName) || ""}
                </span>
              </div>
              <div className="row col-12">
                <span style={{ color: Color.gray }} className="col-4">
                  {strings.season}:
                </span>
                <span
                  className="col-8"
                  style={{ fontWeight: "bold", wordWrap: "break-word" }}
                >
                  {(season && season.name) || ""}
                </span>
              </div>
              <div className="row col-12">
                <div className="col-4"></div>
                <div className="col-8">
                  <span className="d-block" style={{ fontStyle: "italic" }}>
                    {(season &&
                      new Date(season.sowingDate).toLocaleDateString("vi-VN") +
                        " - " +
                        new Date(season.harvestDate).toLocaleDateString(
                          "vi-VN",
                        )) ||
                      ""}
                  </span>
                  {season && season.productsOfFarm && (
                    <div>
                      <span style={{ color: Color.gray }}>
                        {strings.crops + ": "}
                      </span>
                      <span style={{ fontWeight: "bold" }}>
                        {season.productsOfFarm.name}
                      </span>
                    </div>
                  )}
                  {season && season.grossYieldToday && (
                    <div>
                      <span style={{ color: Color.gray }}>
                        {strings.expected_output + ": "}
                      </span>
                      <span style={{ fontWeight: "bold" }}>
                        {season.grossYieldToday.value +
                          season.grossYieldToday.unitName}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>
      <div className="col-12 row mt-10">
        <div className="col-12 p-0">
          <div className="col-12 col-lg-6 col-md-6">
            <InputField
              disabled={true}
              label={strings.expected_output}
              placeholder={strings.expected_output}
              name="expectedOutput"
              inline={false}
              value={
                (productionLog &&
                  productionLog.expectedOutputToday &&
                  productionLog.expectedOutputToday.value +
                    (" " + productionLog.expectedOutputToday.unitName)) ||
                ""
              }
            />
          </div>
        </div>
        <div className="col-12 col-lg-6 col-md-6">
          <InputField
            disabled={true}
            label={strings.work}
            placeholder={strings.work}
            name="work"
            inline={false}
            value={currentWork && currentWork.name}
          />
        </div>
        <div className="col-12 col-lg-6 col-md-6">
          <InputField
            disabled={true}
            label={strings.description}
            placeholder={strings.description}
            name="description"
            inline={false}
            value={currentWork && currentWork.description}
          />
        </div>
        <div className="col-12">
          <Card style={{ height: "350px" }}>
            <span>{strings.images}</span>
            <LicenceUploader
              disabled={true}
              images={(currentWork && currentWork.media) || undefined}
            />
          </Card>
        </div>
        <div className="col-12 mt-3">
          <Tabs defaultActiveKey="1" type="card" size={"middle"}>
            <TabPane tab={strings.work} key="1">
              <TableEditable
                editable={false}
                addRowAble={false}
                selectable={true}
                onSelect={(val) => {
                  setCurrenWork(val);
                }}
                dataSource={(productionLog && productionLog.works) || []}
                columns={columns}
              />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  );
}

export default compose(
  withRouter,
  connect((state) => ({}), {
    getProductionLogDetails,
    getSeasonList,
    getSeasonDetails,
  }),
)(ProductionDetails);

import React, { useEffect, useState } from "react";
import InputField from "./InputField";
import strings from "~/localization";
import SelectField from "./SelectField";
import { connect } from "react-redux";
import {
  getProvinces,
  getDistrict,
  getWards,
} from "~/state/ducks/appApis/actions";
import { ErrorMessage } from "formik";

function AddressField(props) {
  //props
  const {
    values,
    getProvinces,
    getDistrict,
    getWards,
    disabled,
    name = "address",
    handleChangeAddress,
    required = false,
  } = props;

  //state
  const [province, setProvince] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [wards, setWards] = useState([]);
  const [loadingP, setLoadingP] = useState([]);
  const [loadingD, setLoadingD] = useState([]);
  const [loadingW, setLoadingW] = useState([]);

  useEffect(() => {
    //default address
    fetchProvince(1);
    if (values.provinceId) {
      fetchDitrict(values.provinceId);
    }
    if (values.districtId) fetchWard(values.districtId);
  }, []);

  const fetchProvince = (countryId) => {
    setLoadingP(true);
    getProvinces(countryId)
      .then((res) => {
        setProvince(
          res.res.map((m) => {
            return {
              value: m.id,
              label: m.name,
            };
          }),
        );
      })
      .catch((err) => console.log(err))
      .finally(() => setLoadingP(false));
  };
  const fetchDitrict = (provinceId) => {
    setLoadingD(true);
    getDistrict(provinceId)
      .then((res) => {
        setDistricts(
          res.res.map((m) => {
            return {
              value: m.id,
              label: m.name,
            };
          }),
        );
      })
      .catch((err) => console.log(err))
      .finally(() => setLoadingD(false));
  };
  const fetchWard = (districtId) => {
    setLoadingW(true);
    getWards(districtId)
      .then((res) => {
        setWards(
          res.res.map((m) => {
            return {
              value: m.id,
              label: m.name,
            };
          }),
        );
      })
      .catch((err) => console.log(err))
      .finally(() => setLoadingW(false));
  };

  return (
    <div className="row">
      <div className="col-12 col-md-6 col-lg-6">
        <InputField
          required={required}
          disabled={disabled}
          name="address1"
          value={values.address1}
          onChange={(e) => {
            handleChangeAddress(`${name}.address1`)(e.target.value);
          }}
          placeholder={strings.address}
          label={strings.address}
        />
        <div className="textErr">
          <ErrorMessage name={`${name}.address1`} />
        </div>
      </div>
      <div className="col-12 col-md-6 col-lg-6">
        <SelectField
          required={required}
          className="w-100"
          disabled={disabled}
          loading={loadingP}
          showSearch
          data={province}
          value={values.provinceId || undefined}
          onChange={(value) => {
            handleChangeAddress(`${name}.provinceId`)(value);
            handleChangeAddress(`${name}.districtId`)(null);
            handleChangeAddress(`${name}.wardsId`)(null);
            setWards([]);
            fetchDitrict(value);
          }}
          iconEnd="caret-down"
          placeholder={strings.city_or_province}
          label={strings.city_or_province}
        />
        <div className="textErr">
          <ErrorMessage name={`${name}.provinceId`} />
        </div>
      </div>
      <div className="col-12 col-md-6 col-lg-6">
        <SelectField
          required={required}
          className="w-100"
          disabled={disabled}
          loading={loadingD}
          data={districts}
          value={values.districtId || undefined}
          onChange={(value) => {
            handleChangeAddress(`${name}.districtId`)(value);
            handleChangeAddress(`${name}.wardsId`)(undefined);
            fetchWard(value);
          }}
          iconEnd="caret-down"
          placeholder={strings.district}
          label={strings.district}
        />
        <div className="textErr">
          <ErrorMessage name={`${name}.districtId`} />
        </div>
      </div>
      <div className="col-12 col-md-6 col-lg-6">
        <SelectField
          required={required}
          disabled={disabled}
          loading={loadingW}
          className="w-100"
          data={wards}
          value={values.wardsId || undefined}
          onChange={(value) => {
            handleChangeAddress(`${name}.wardsId`)(value);
          }}
          iconEnd="caret-down"
          placeholder={strings.ward}
          label={strings.ward}
        />
        <div className="textErr">
          <ErrorMessage name={`${name}.wardsId`} />
        </div>
      </div>
    </div>
  );
}

export default connect(null, {
  getProvinces,
  getDistrict,
  getWards,
})(AddressField);

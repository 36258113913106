// Authentication path
export const LOGIN_PATH = "/login";
export const ACCOUNT_RECOVERY_PATH = "/account-recovery";
export const RECOVERY_OTP_PATH = "/account-recovery/otp/:account";
export const REGISTER_OTP_PATH = "/register/otp/:account";
export const RECOVERY_CHANGE_PASSWORD_PATH =
  "/account-recovery/change-password";
export const REGISTER_PATH = "/register";
export const REGISTER_COMPLETED_PATH = "/register/completed";
export const SETUP_PROFILE_PATH = "/setup-profile";
/**
 *     DASHBOARD
 */
export const DASHBOARD_PATH = "/dashboard";

/**
 * DEFAULT PATH
 */
export const APP_DEFAULT_PATH = DASHBOARD_PATH;

/**
 * MASTER DATA PATH
 */
export const MASTER_DATA_PATH = "/master-data";
export const MASTER_DATA_DASHBOARD = "/master-data/dashboard";
export const MASTER_DATA_CATEGORY = "/master-data/category";
export const MASTER_DATA_ORIGINAL_PRODUCT = "/master-data/product";
export const MASTER_DATA_PRODUCT_ALL = "/master-data/products/all";
export const MASTER_DATA_PRODUCT_ADD = "/master-data/products/create";
export const MASTER_DATA_PRODUCT_VIEW = "/master-data/products/:id";
export const MASTER_DATA_PRODUCT_EDIT = "/master-data/products/:id/edit";
export const MASTER_DATA_BUSINESS_CERTIFICATE =
  "/master-data/business-certificate";
export const MASTER_DATA_CERTIFICATION_LAND = "/master-data/certification-land";
export const MASTER_DATA_CROPS = "/master-data/crops";
export const MASTER_DATA_CULTIVATION = "/master-data/cultivation";
export const MASTER_DATA_MARKET = "/master-data/markets";
export const MASTER_DATA_FORM_OF_USES = "/master-data/form-of-uses";
/**
 * FARM MANAGERMENT PATH
 */
export const FARM_MANAGERMENT = "/farm-managerment";
export const FARM_MANAGERMENT_DASHBOARD = "/farm-managerment/dashboard";
export const FARM_MANAGERMENT_LIST = "/farm-managerment/farm";
export const FARM_MANAGERMENT_DETAILS = "/farm-managerment/farm/:action/:id";

export const SEASON_MANAGERMENT_LIST = "/farm-managerment/season";
export const SEASON_MANAGERMENT_DETAILS =
  "/farm-managerment/season/:action/:id";

export const DIARY_MANAGERMENT_LIST =
  "/farm-managerment/diary/:farmingSeasonId";
export const DIARY_MANAGERMENT_DETAILS = "/farm-managerment/diary/:action/:id";

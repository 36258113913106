const vi = {
  /**
   * Server localization
   */
  server_resetkey_invalid: "Mã OTP không chính xác",
  server_Access_is_denied: "Tài khoản không được cấp quyền truy cập",
  server_TOKEN_EXPIRED: "Hết phiên đăng nhập",
  server_email_already_used:
    "Email hoặc Số điện thoại đã được sử dụng để đăng ký tài khoản",
  server_error_validation: "Dữ liệu nhập vào không đúng định dạng",
  server_user_notfound: "Tài khoản không tồn tại",
  server_account_notfound: "Tài khoản không tồn tại",
  server_can_not_connect: "Không thể kết nối đến máy chủ!!!",
  server_maintenance: "Máy chủ đang bảo trì",
  server_user_login_invalid:
    "Tài khoản đăng nhập hoặc mật khẩu không chính xác",
  server_user_not_activated: "Tài khoản chưa được kích hoạt",
  server_activationkey_incorrect: "Mã xác thực không chính xác",
  server_email_invalid: "Email không hợp lệ",
  server_phone_invalid: "Số điện thoại không hợp lệ",
  /**
   * global validation
   */
  phone_or_email_required: "Vui lòng nhập email hoặc số điện thoại",
  invalid_email: "Email không hợp lệ",
  email_required: "Vui lòng nhập Email",
  password_not_matched: "Mật khẩu không trùng khớp",
  invalid_email_or_phone: "Email/Số điện thoại không hợp lệ",
  required: "Vui lòng nhập thông tin",
  password_required: "Vui lòng nhập mật khẩu",
  more_than_zero: "Giá trị phải lớn hơn 0",
  invalid_password: "Mật khẩu từ 6 ký tự trở lên",
  max_length: "Tối đa {max} ký tự",
  image_max_size: "Dung lượng tối đa hình ảnh là {size} MB",
  file_max_size: "Dung lượng tối đa của tệp tin tải lên là {size} MB",
  must_be_number: "Vui lòng nhập số",
  EDIT: "Sửa",
  UPDATE: "Cập nhật",
  CREATE: "Thêm",
  DELETE: "Xóa",
  VIEW: "Xem",
  ACTION: "Thao tác",
  export: "Xuất file",
  import: "Nhập file",

  /**
   * range Data
   */
  dashboard_data_range: "Chọn thời gian",
  date_ranger: "Trong khoảng",
  default: "Mặc định",
  date_ranger_title: "Chọn Khoảng Thời Gian",
  today: "Hôm nay",
  yesterday: "Hôm qua",
  last_week: "Tuần trước",
  week_to_date: "Tuần này",
  month_to_date: "Tháng này",
  last_moth: "Tháng trước",
  quarter_to_date: "Quý này",
  last_quarter: "Quý trước",
  year_to_date: "Năm này",
  last_year: "Năm trước",
  presets: "Cài đặt trước",
  custom: "Tùy trỉnh",
  compare_to: "So Sánh với",
  previos_period: "Kỳ Trước",
  previos_year: "Năm trước",
  /**
   * range Data
   */

  /**
   * enum
   */
  CREATE_SEASON_PROCESS: "Tạo quy trình",
  UPDATE_SEASON_PROCESS: "Cập nhật quy trình",
  DELETE_SEASON_PROCESS: "Xoá quy trình",
  CREATE_PERSIONAL_PROFILE: "Tạo hồ sơ cá nhân",
  UPDATE_PERSIONAL_PROFILE: "Cập nhật hồ sơ cá nhân",
  DELETE_PERSIONAL_PROFILE: "Xoá hồ sơ cá nhân",
  UPDATE_FARM_PROFILE: "Cập nhật hồ sơ trang trại",
  CREATE_FARM_PROFILE: "Tạo hồ sơ trang trại",
  DELETE_FARM_PROFILE: "Xoá hồ sơ trang trại",
  UPDATE_ENTERPRISE_PROFILE: "Cập nhật hồ sơ doanh nghiệp",
  CREATE_ENTERPRISE_PROFILE: "Tạo hồ sơ doanh nghiệp",
  DELETE_ENTERPRISE_PROFILE: "Xoá hồ sơ doanh nghiệp",
  UPDATE_COOPERATIVE_PROFILE: "Cập nhật hồ sơ hợp tác xã",
  CREATE_COOPERATIVE_PROFILE: "Tạo hồ sơ hợp tác xã",
  DELETE_COOPERATIVE_PROFILE: "Xoá hồ sơ hợp tác xã",
  UPDATE_COOPERATIVE_VENTURE_PROFILE: "Cập nhật hồ sơ liên hợp tác xã",
  CREATE_COOPERATIVE_VENTURE_PROFILE: "Tạo hồ sơ liên hợp tác xã",
  DELETE_COOPERATIVE_VENTURE_PROFILE: "Xoá hồ sơ liên hợp tác xã",
  UPDATE_ORGANIZATION_PROFILE: "Cập nhật hồ sơ tổ chức",
  CREATE_ORGANIZATION_PROFILE: "Tạo hồ sơ tổ chức",
  DELETE_ORGANIZATION_PROFILE: "Xoá hồ sơ tổ chức",
  UPDATE_FARM_SEASON: "Cập nhật mùa vụ",
  CREATE_FARM_SEASON: "Tạo mùa vụ",
  DELETE_FARM_SEASON: "Xoá mùa vụ",
  /**
   * Client localization
   */
  //conflig
  home: "Trang chủ",
  login: "Đăng nhập",
  login_anotation: "Đăng nhập để sử dụng các ứng dụng trong nền tảng",
  login_placeholder: "Email hoặc số điện thoại",
  password: "Mật khẩu",
  reenter_password: "Nhập lại mật khẩu",
  forgot_password: "Quên mật khẩu?",
  register_an_account: "Đăng ký tài khoản?",
  intro:
    "<p>AgriSys là nền tảng quản lý trang trại dành cho nông dân hoặc nhà sản xuất được phát triển bởi công ty công nghệ nông nghiệp, bao gồm hệ thống các ứng dụng hỗ trợ nông dân từ trang trại đến thương mại hoá sản phẩm. Người dùng có thể cài đặt từng ứng dụng để sử dụng hoặc gỡ cài đặt nếu không sử dụng.</p><p>Cảm ơn đã đồng hành cùng AgriTech. Mọi thông tin hoặc thắc mắc vui lòng gửi về địa chỉ email hotro@global-agritech.</p>",
  copy_right: "Copyright @2020 by AgriTech Inc.",
  recovery: "Lấy lại mật khẩu",
  recovery_anotation: "Theo chỉ dẫn dưới đây để lấy lại mật khẩu tài khoản",
  continue: "Tiếp tục",
  back: "Quay lại",
  wanna_login: "Đăng nhập?",
  email_otp_sent:
    "Chúng tôi đã gửi 6 ký tự để xác thực tài khoản chủ thể tới email {email}",
  phone_otp_sent:
    "Chúng tôi đã gửi 6 ký tự để xác thực tài khoản chủ thể tới số điện thoại {phone}",
  your_account: "Tài khoản của bạn: {account}",
  completed: "Hoàn thành",
  new_password: "Mật khẩu mới",
  confirm_password: "Nhập lại mật khẩu",
  register: "Đăng ký",
  register_anotation:
    "Vui lòng nhập vào thông tin theo mẫu dưới đây để đăng ký tài khoản",
  login_with_your_account: "Đã có tài khoản? Đăng nhập",
  full_name: "Họ và tên",
  phone: "Số điện thoại",
  email: "Email",
  choose_business_model: "Chọn loại hình hoạt động",
  farming_business_placeholder:
    "Vui lòng cung cấp thêm thông tin về trang trại hoặc doanh nghiệp",
  agree_agriSys_term: "Đồng ý các điều khoản của AgriSys",
  completed_registry:
    "<p>Cảm ơn đã đăng ký tài khoản của AgriSys, chúng tôi sẽ liên hệ với quý khách sớm nhất để hoàn tất các thủ tục pháp lý để có tài khoản đăng nhập.</p><p>Trong thời gian đợi, vui lòng lưới qua các sàn giao dịch bán sỉ và bán lẻ cung cấp với AgriTech hoặc tải ứng dụng về máy để trải nghiệm.</p>",
  download: "Tải ứng dụng",
  setup_profile: "Cài đặt thông tin hồ sơ",
  personal_info: "Thông tin cá nhân",
  farming_experience: "Kinh nghiệm canh tác",
  certificate: "Giấy chứng nhận",
  birthday_placeholder: "Ngày tháng năm sinh",
  level: "Trình độ",
  career: "Ngành nghề",
  identification_documents: "Giấy tờ tuỳ thân",
  address: "Địa chỉ",
  degree: "Bằng cấp",
  income: "Thu nhập",
  family: "Gia đình",
  bank: "Ngân hàng",
  document_type: "Loại giấy tờ",
  identity_number: "Số",
  date_of_issue: "Ngày cấp",
  place_of_issue: "Nơi cấp",
  identification: "Định danh",
  kyc: "KYC",
  update: "Cập nhật",
  close: "Đóng",
  add_row: "Thêm dòng mới",
  address_type: "Loại địa chỉ",
  country: "Quốc gia",
  city_or_province: "Tỉnh/Thành phố",
  district: "Quận/Huyện",
  postcode: "Mã vùng",
  degree_of_the_school: "Trường cấp",
  classification: "Xếp loại",
  year_stared: "Năm bắt đầu",
  year_ended: "Năm kết thúc",
  field: "Lĩnh vực",
  income_type: "Loại",
  description: "Mô tả",
  amount_of_money: "Số tiền",
  bank_name: "Ngân hàng",
  account_name: "Tên tài khoản",
  account_number: "Số tài khoản",
  bank_branch: "Chi nhánh",
  relation_ship: "Quan hệ",
  year_of_birth: "Năm sinh",
  identity_papers: "Giấy tờ tuỳ thân",
  ID_number: "Số",
  start_date: "Ngày bắt đầu",
  end_date: "Ngày kết thúc",
  product_type: "Loại sản phẩm",
  yield: "Sản lượng",
  quanlity: "Chất lượng",
  farming: "Canh tác",
  drag_and_drop: "Kéo và thả",
  unit: "Đơn vị",
  more_description: "Mô tả thêm chi tiết",
  history: "Lịch sử",
  type_of: "Loại hình",
  product: "Sản phẩm",
  save: "Lưu",
  cancel: "Huỷ bỏ",
  skip_setup: "Cài đặt sau",
  first_name: "Tên",
  last_name: "Họ",
  emptyText: "Không có dữ liệu",
  invalid_phone: "Số điện thoại không hợp lệ",
  email_or_phone: "Email/Số điện thoại",
  sent_OTP: "Chúng tôi đã gởi mã kích hoạt đến tài khoản của bạn",
  activation_successful: "Kích hoạt tài khoản thành công",
  active_account: "Kích hoạt tài khoản",
  active_account_anotation: "Theo chỉ dẫn dưới đây để kích hoạt tài khoản",
  reset_password_successful: "Cập nhật tài khoản thành công",
  login_failed: "Đăng nhập không thành công",
  expiration_date: "Ngày hết hạn",
  certificate_type: "Loại chứng nhận",
  issued_by: "Cấp bởi",
  endorsed: "Đã chứng thực",
  not_authenticated: "Chưa chứng thực",
  require_firstName: "Vui lòng nhập Họ",
  require_lastName: "Vui lòng nhập Tên",
  vietnam: "Việt Nam",
  completed_setup: "Hoàn thành cài đặt",
  completed_setup_description:
    "Cảm ơn đã hoàn thành thông tin cần thiết để có thể sử dụng hệ thống quản lý nông trại.Cảm ơn đã hoàn thành thông tin cần thiết để có thể sử dụng hệ thống quản lý nông trại.Cảm ơn đã hoàn thành thông tin cần thiết để có thể sử dụng hệ thống quản lý nông trại.",
  start_date_greater_than_end_date: "Ngày kết thúc phải lớn hơn ngày bắt đầu",
  issue_date_greater_than_expired_date: "Ngày hết hạn phải lớn hơn ngày cấp",
  activity_date_greater_than_expired_date:
    "Ngày hoạt động phải lớn hơn ngày cấp",
  reevaluate_date_greater_than_evaluate_date:
    "Ngày tái đánh giá phải lớn hơn ngày đánh giá",
  update_profile_successful: "Cập nhật hồ sơ thành công",
  create_profile_successful: "Tạo hồ sơ thành công",
  invalid_format: "Nhập sai định dạng",
  profiles_management: "Quản lý hồ sơ",
  farmings_management: "Quản lý trang trại",
  purchasing_management: "Quản lý thu mua",
  goods_management: "Quản lý hàng hoá",
  comercial_management: "Quản lý thương mại",
  orders_management: "Quản lý đơn hàng",
  partners_management: "Quản lý đối tác",
  logistics_management: "Quản lý kho vận",
  human_resources_management: "Quản lý nhân sự",
  financial_management: "Quản lý tài chính",
  messages_management: "Tin nhắn",
  reports: "Báo cáo thống kê",
  profile: "Hồ sơ",
  configuration: "Cấu hình",
  all_profiles: "Tất cả hồ sơ",
  persional_profile: "Hồ sơ cá nhân",
  farm: "Trang trại",
  season: "Mùa vụ",
  total_season: "Tổng mùa vụ",
  season_update_success: "Cập nhật mùa vụ thành công",
  season_update_error: "Cập nhật mùa vụ thất bại, vui lòng thử lại sau",
  season_details: "Chi tiết mùa vụ",
  season_infor: "Thông tin mùa vụ",
  season_material: "Danh sách vật tư",
  diary: "Nhật ký sản xuất",
  business: "Doanh nghiệp",
  cooperative: "Hợp tác xã",
  union_of_cooperative: "Liên hợp tác xã",
  association_of_occupation: "Hiệp hội nghành nghề",
  department: "Ban sở ngành",
  officials: "Cán bộ",
  foreign: "Nước ngoài",
  upload_certificate: "Tải chứng nhận",
  applications: "Ứng dụng",
  farm_area: "DT Trang trại",
  farm_area_size: "{size} ha",
  farm_profile: "Hồ sơ trang trại",
  enterprise_profile: "Hồ sơ doanh nghiệp",
  cooperative_profile: "Hồ sơ hợp tác xã",
  cooperative_venture_profile: "Hồ sơ liên hợp tác xã",
  organization_profile: "Hồ sơ tổ chức",
  create_profile: "Tạo hồ sơ",
  search_placeholder: "Tìm kiếm",
  search_farm_placeholder: "Tìm kiếm trang trại",
  search_season_placeholder: "Tìm kiếm mùa vụ",
  search_diary_placeholder: "Tìm kiếm nhật ký sản xuất",
  filter: "Bộ lọc",
  name: "Tên",
  profile_type: "Loại hồ sơ",
  avatar: "Ảnh đại diện",
  manager: "Người quản lý",
  sign_out: "Đăng xuất",
  personal: "Cá nhân",
  edit_profile: "Sửa hồ sơ",
  create: "Tạo mới",
  remove_profile_confirmation: "Xoá hồ sơ này?",
  yes: "Đồng ý",
  no: "Không",
  confirmation: "Xác nhận",
  delete_profile_success: "Đã xoá hồ sơ",
  action: "Hoạt động",
  storage: "Lưu trữ",
  delete: "Xoá bỏ",
  copy: "Bản sao",
  add_new: "Thêm mới",
  //conflig
  farm_name: "Tên trang trại",
  farm_update_message_success: "Thay đổi thông tin trang trại thành công",
  farm_update_message_err: "Cập nhật thông tin gặp sự cố, vui lòng thử lại sau",
  sent_mess: "Gửi tin nhắn",
  history_note: "Nhật ký ghi chú",
  schedule_note: "Nhật ký ghi chú",
  type_certification: "Loại chứng nhận",
  date_range: "Ngày cấp",
  place_suplly: "Nơi cấp",
  number_cn: "Số CN",
  date_evaluate: "Ngày đánh giá",
  date_reevaluate: "Ngày tái đánh giá",
  id_land: "Mã số thửa đất",
  status_land: "Tình trạng đất sử dụng",
  his_land: "Chủ sở hữu đất",
  acreage: "Diện tích",
  before_upload_GCN: "Tải mặt trước GCN",
  after_upload_GCN: "Tải mặt sau GCN",
  human_and_GCN_upload: "GCN và người sử dụng",
  other: "Khác",
  cost_yeah: "Chi phí năm",
  tax_TNCN: "Thuế TNCN",
  tax_GTGT_yeah: "Thuế GTGT năm",
  annual_profit: "Lợi nhuận năm",
  sum_acreage: "Tổng diện tích",
  status_land_use: "Tình trạng đất SD",
  sum_human: "Số lượng nhân viên",
  number_seasons: "Số mùa vụ",
  sum_output: "Tổng sản lượng",
  consumption_market: "Thị trường tiêu thụ",
  master_data: "Dữ liệu chủ",
  consumption_market_other: "Thị trường tiêu thụ khác",
  market_type: "Nguồn dữ liệu",
  market_name: "Tên trường tiêu thụ",
  revenue_yeah: "Doanh thu năm",
  model: "Loại hình",
  total_productivity: "Tổng năng suất",
  ward: "Xã/Phường",
  farm_info: "Thông tin trang trại",
  gcn_standard: "GCN tiêu chuẩn",
  gcn_qsdd: "GCN QSDĐ",
  activity_logs: "Lịch sử hoạt động",
  notes_of: "Ghi chú của ",
  images: "Hình ảnh",
  invalid_image_upload:
    "Hình ảnh tải lên chưa đúng định dạng PNG, JPG, JPEG, BMP",
  limited_image_upload: "Bạn chỉ được upload tối đa {limit} hình",
  countinue: "Tiếp tục",
  branch_product: "Ngành",
  information_general: "Thông tin chung",
  information_detail: "Thông tin chi tiết",
  name_enterpise: "Tên doanh nghiệp",
  name_representative: "Tên người đại diện",
  sum_acreage_mana: "Tổng diện tích Q Lý",
  sum_employee: "Tổng số nhân viên",
  detail: "Chi tiết",
  /**
   * HOANG TAN Upload files
   */
  upload_media_placeholder: "Tải lên",
  please_download_in_to_view: "Vui lòng tải xuống để xem nội dung tệp tin này!",
  delete_file_success: "Tệp tin {name} đã được xóa thành công!",
  image_not_display: "Không thể hiển thị",
  warning_create_upload:
    "Vui lòng hoàn thành khởi tạo trước khi quản lý các tệp tin!",
  warning_no_files: "Không có tệp tin nào!",
  invalid_media_upload: "Tệp tin tải lên chưa đúng định dạng MP4, IMAGE",
  invalid_docs_upload:
    "Tệp tin tải lên chưa đúng định dạng WORD, EXCEL, PDF, TXT, RAR",
  download_file: "Tải tệp tin",
  upload_docs: " Tài liệu",
  upload_docs_title: "Tài liệu",
  upload_media: " Hình/Video",
  upload_confirm_title: "Bạn chắc chắn xóa",
  upload_confirm_yes: "Có",
  upload_confirm_no: "Không",
  management_grouping: "Phân nhóm QL",
  number_to_large: "Số quá lớn",
  /**
   * Viet - avoid conflict
   */
  enter_product: "Nhập sản phẩm",
  id_tax: "MS Thuế",
  date_activity: "Ngày hoạt động",
  procedure: "Quy trình",
  culture: "Canh tác",
  handling: "Xử lý",
  land_water: "Đất và nước",
  enviroment: "Môi trường",
  prevention: "Phòng bệnh",
  seasons: "Mùa vụ",
  create_seasons: "Tạo Mùa vụ",
  assign_procedure: "Assign quy trình",
  ledger: "Lập sổ cái",
  plant_culture: "Kế hoạch canh tác",
  structure: "Cấu trúc",
  cost_product: "Chi phí SX",
  cost_pack: "Chi phí đóng gói",
  report: "Báo cáo",
  fertilizer: "Phân bón",
  medicine_BVTV: "Thuốc bảo vệ thực vật",
  create_farm: "Tạo trang trại",
  create_on: "Tạo",
  import_exit: "Nhập tồn",
  import_input: "Nhập đầu vào",
  name_farm: "Tên trang trại",
  overview_season: "Tổng quan mùa vụ",
  overview_procedure: "Tổng quan quy trình",
  ledg: "Sổ cái",
  price: "Giá bán",
  all: "Tất cả",
  resolve_land_watter: "Xử lý đất và nước",
  resolve_envi: "Xử lý môi trường",
  resolve_prevention: "Xủ lý phòng bệnh",
  date_upadte: "Cập nhật ngày",
  rate: "Đánh giá",
  decription_procedure: "Mô tả về quy trình",
  stage: "Công đoạn",
  total_procedure: "Tổng số vụ áp dụng",
  type_procedure: "Loại quy trình",
  name_procedure: "Tên quy trình",
  edit_from_procedure_form: "Chỉnh sửa từ quy trình mẫu",
  add_procedure: "Thêm quy trình",
  edit_procedure: "Sửa quy trình",
  view_procedure: "Chi tiết",
  create_procedure_successful: "Tạo quy trình thành công",
  delete_procedure_successful: "Xóa quy trình thành công",
  specifications: "Quy cách",
  commerce: "Thương mại",
  //form
  vietnam_agricultural_food_products_connection_exchange:
    "SÀN GIAO DỊCH KẾT NỐI NÔNG SẢN THỰC PHẨM VIỆT NAM",
  offering_number: "Phiếu chào hàng số",
  seller_registration_ship: "PHIẾU ĐĂNG KÝ CHÀO BÁN HÀNG",
  seller_code_number: "Mã số người chào bán hàng",
  name_of_the_offering_organization: "Tên tổ chức chào bán",
  date_sale: "Ngày chào bán hàng",
  week_number: "Tuần thứ",
  transaction_classification: "Phân loại giao dịch",
  name_of_personal_representative: "Tên cá nhân đại diện",
  DTDD_number: "ĐTDĐ số",
  website: "Website",
  transaction_role: "Vai trò giao dịch",
  sales_address: "Địa chỉ bán hàng",
  town_village: "KP/thôn",
  city_city: "TP/tỉnh",
  scope_transaction_registration: "Phạm vi đăng ký giao dịch",
  register_to_sell_nationwide: "Đăng ký bán cả nước",
  register_to_sell_by_region: "Đăng ký bán theo vùng miền",
  register_to_sell_in_city_province: "Đăng ký bán trong TP/tỉnh",
  register_to_sell_in_district: "Đăng ký bán trong Quận/huyện",
  register_to_sell_in_ward_commune: "Đăng ký bán trong Phường/xã",
  connection_results: "Kết quả việc kết nối",
  time_to_sell: "Thời gian đăng bán",
  products_offered_for_sale: "Mặt hàng chào bán",
  technical_specifications_of_products: "Thông số kỹ thuật sản phẩm chào bán",
  number_of_offers: "Số lượng chào bán",
  offering_price_vnd: "Giá chào bán (đồng) ",
  service_fee_vnd: "Phí dịch vụ (đồng)",
  delivery_time: "Thời gian giao hàng",
  payments: "Hình thức thanh toán",
  STT: "SST",
  qr_code: "Mã số QR",
  characteristic: "Đặc tính",
  chart: "Biểu đồ",
  value: "Giá trị",
  formality: "Hình thức",
  size_product: "Cỡ trái",
  number_of_seeds: "Số hạt",
  plate: "% tấm",
  color_sharp: "Màu sắc",
  humidity: "Độ ẩm",
  closed_package: "Đóng gói",
  measure: "Đo lường",
  ĐVT: "ĐVT",
  total: "Tổng cộng",
  delivery_date: "Ngày giao",
  deposit: "Đặt cọc",
  Pay: "Thanh toán",
  percentage_connection_service_cost_over_transaction:
    "Tỉ lệ% chi phí dịch vụ kết nối trên giao dịch tổng giá trị",
  sign_up_for_sale: "Đăng ký chào bán",
  successful_connection: "Kết nối thành công",
  payment_through_the_exchange: "Thanh toán qua Sàn",
  register_for_delivery_at: "Đăng ký giao hàng tại",
  delivered_in_the_field: "Giao tại ruộng",
  delivered_at_the_facility: "Giao tại cơ sở",
  delivered_at_the_warehouse: "Giao tại kho",
  delivered_at_factory: "Giao tại nhà máy",
  delivered_at_the_store: "Giao tại cửa hàng",
  delivered_at_the_company: "Giao tại công ty",
  delivered_at_the_airport: "Giao tại sân bay",
  delivered_at_the_port: "Giao tại cảng",
  delivered_at_the_checkpoint_of_export: "Giao tại cửa khẩu xuất",
  delivered_at_the_import_border_gate: "Giao tại cửa khẩu nhập",
  delivery_to_the_receiver: "Giao tận cửa người nhận",
  other_mixes: "Giao hỗn hợp khác ",
  AgriSys: "AgriSys",
  AgriDepot: "AgriDepot",
  AgriService: "AgriService",
  AgriHub: "AgriHub",
  AgriPay: "AgriPay",
  using_value_added_services: "Sử dụng dịch vụ giá trị gia tăng",
  supplying_agricultural_materials: "Cung cấp vật tư nông nghiệp ",
  plant_doctor_services: "Dịch vụ bác sĩ cây trồng",
  warehouse_rental_service: "Dịch vụ cho thuê kho hàng",
  data_analysis_service: "Dịch vụ phân tích số liệu",
  trade_promotion_services_fairs: "Dịch vụ XTTM, hội chợ",
  payment_assistance_service: "Dịch vụ hỗ trợ thanh toán",
  irrigation_system_care_service: "Dịch vụ chăm sóc hệ thống tưới",
  service_of_product_harvest: "D.vụ thu hoạch sản phẩm",
  freight_forwarding_service: "Dịch vụ giao nhận vận chuyển",
  services_of_iot_technology_solutions: "D.vụ giải pháp công nghệ IoT",
  service_to_find_customers: "Dịch vụ tìm khách hàng",
  loan_support_services: "Dịch vụ hỗ trợ vốn vay",
  drug_administration_services: "Dịch vụ quản lý phân thuốc",
  standard_consulting_service: "D.vụ tư vấn tiêu chuẩn",
  outsourcing_service_packaging: "Dịch vụ gia công đóng gói",
  cargo_location_services: "Dịch vụ định vị hàng hóa",
  export_support_services: "Dịch vụ hỗ trợ xuất khẩu",
  insurance_consulting_services: "Dịch vụ tư vấn bảo hiểm",
  lighting_management_service: "Dịch vụ quản lý hệ thống chiếu sáng",
  service_standard_assessment: "D.vụ đánh giá tiêu chuẩn",
  outsourcing_service: "Dịch vụ gia công chế biến",
  packaging_label_design_services: "Dịch vụ thiết kế tem nhãn bao bì",
  legal_consulting_service: "Dịch vụ tư vấn pháp lý",
  financial_advisory_service: "Dịch vụ tư vấn tài chính",
  farm_management_services: "Dịch vụ quản lý trang trại",
  dv_traceability: "DV Truy xuất nguồn gốc",
  factory_management_services: "Dịch vụ quản lý nhà máy",
  packaging_services: "Dịch vụ cung cấp bao bì",
  distribution_support_service: "Dịch vụ hỗ trợ phân phối",
  investment_consulting_services: "Dịch vụ tư vấn đầu tư",
  post_sale: "Đăng bán",
  create_post_sale: "Tạo đăng bán",
  number_vote: "Số phiếu",
  offering_sell: "Tổ chức chào bán",
  surrogate: "Người đại diện",
  date_buy_register: "Ngày ĐK bán",
  time_buy_register: "Thời gian đăng ký bán",
  sum_total_buy: "Tổng số lượng bán",
  sum_cost_buy: "Tổng giá trị bán",
  feature_development: "Tính năng đang phát triển",
  /**
   * HIEU - avoid conflict
   */
  install: "Cài đặt",
  installed: "Đã cài",
  contact: "Liên lạc",
  logistics: "Kho vận",
  human_resources: "Nhân sự",
  financial: "Tài chính",
  profile_management_description: "Hồ sơ cá nhân, pháp nhân",
  farm_management_description: "Trang trại, nông sản, canh tác",
  purchase_management_description: "Trang trại, nông sản, canh tác",
  goods_management_description: "Nông sản, hàng hoá",
  comercial_management_description: "Mua, bán, giá, quy cách",
  orders_management_description: "Đơn bán hàng, mua, đặt trước",
  contact_management_description: "Đối tác, khách hàng",
  logictics_management_description: "Nhập/xuất kho, tồn kho, kiểm kê",
  human_resources_management_description: "Phòng ban, nhân sự",
  finance_management_description: "Kế toán, tài chính",
  messenger_management_description: "",
  report_management_description: "Báo cáo, thống kê, dự đoán",
  expected: "Dự kiến",
  input: "Đầu vào",
  candidate: "Lịch",
  excellent: "Xuất sắc",
  very_good: "Rất tốt",
  good: "Tốt",
  fair: "Khá",
  poor: "Kém",
  promotion: "Khuyến mãi",

  add_stage: "Thêm công đoạn",
  delete_procedure: "Xóa quy trình này",
  /**
   * HOANG TAN Cooperative, Cooperation ventrure, Organization profile
   */
  cooperative_info: "Thông tin chung",
  cooperative_detail: "Thông tin chi tiết",
  cooperative_name: "Tên hợp tác xã",
  placeholder_cooperative_name: "HTX Ba khía",
  cooperative_sum_member: "Tổng số thành viên",
  certificate_number: "Số chứng nhận",
  tax_number: "Mã số thuế",
  tax_TNDN: "Thuế TNDN",
  operation_date: "Ngày hoạt động",
  issue_date_greater_than_operation_date: "Ngày hoạt động phải sau ngày cấp",
  where_by_issue: "Nơi cấp",
  cooperative_name_required: "Vui lòng nhập tên Hợp tác xã",
  cooperative_surrogate_required: "Vui lòng nhập tên Người đại diện",
  cooperative_employ_required: "Vui lòng nhập Tổng số thành viên",
  unit_price: "VND",
  unit_price_d: "đ",
  more_than_number: "Giá trị phải lớn hơn {number}",
  number_to_larger: "Giá trị phải nhỏ hơn {number}",
  unit_farming_season: "Vụ/năm",
  unit_gross_yield: "Tấn/năm",
  cooperative_farm_season_required: "Vui lòng nhập Tổng số mùa vụ",
  cooperative_gross_yield_required: "Vui lòng nhập Tổng sản lượng",
  placeholder_sum_acreage: "1",
  placeholder_name_representative: "Nguyễn Văn A",
  placeholder_phone: "0987654321",
  placeholder_email: "abc@gmail.com",
  placeholder_status_land: "Sỡ hữu",
  placeholder_consumption_market: "Thương lái",
  placeholder_certificate_type: "Giấy phép kinh doanh",
  placeholder_certificate_number: "777888999",
  placeholder_date_of_issue: "01/01/2020",
  placeholder_where_by_issue: "Sở kế hoạch - đầu tư HCM",
  placeholder_tax_number: "0123456789",
  placeholder_operation_date: "01/02/2020",
  placeholder_address: "15 Trần Hưng Đạo",
  placeholder_type_of: "Sản xuất",
  placeholder_branch_product: "Hồ tiêu",
  placeholder_management_grouping: "Đầu vào, Xử lý chế biến",
  placeholder_expiration_date: "31/12/2025",
  placeholder_date_evaluate: "01/01/2026",
  placeholder_date_reevaluate: "01/06/2026",
  placeholder_issued_by: "Sở kế hoạch - đầu tư HCM",
  // cooperative venture
  cooperative_venture_name: "Tên liên hợp tác xã",
  // Organization
  organization: "Tổ chức",
  organization_name: "Tên tổ chức",
  STOP: "Dừng",
  PAUSE: "Đang ngưng",
  CANCELED: "Hủy",
  WAITING: "Đang chờ",
  RUNNING: "Đang chạy",
  FINISHED: "Hoàn thành",
  rating_default: "Chưa đánh giá",
  update_season: "Cập nhật mùa vụ",
  organization_name: "Tên tổ chức",
  search: "Tìm kiếm",
  policy: "Chính sách bảo mật",
  terms: "Điều khoản dịch vụ",
  welcom_to: "Welcom to",
  Farm_management_backoffice: "Farm management backoffice",
  login_des: "Đăng nhập để sử dụng các ứng dụng trong nền tảng",

  /**
   * HOANG TAN Farm season
   */
  farm_season_input: "Đầu vào",
  farm_setup: "Cài đặt thông tin cho trang trại",
  farm_choose: "Chọn trang trại",
  down_seed_date: "Ngày xuống giống",
  allocation_per_tree: "Phân bổ % size / 01 cây",
  total_cultivated_area: "Tổng diện tích canh tác",
  cultivated_area: "Diện tích canh tác",
  farm_season_name: "Tên mùa vụ",
  expected_harvest_date: "Ngày dự kiến thu hoạch",
  number_tree_per_ha: "Số trụ trồng trên 01 ha đất",
  expected_sum_output: "Tổng sản lượng dự kiến",
  expected_sum_output_today: "Tổng sản lượng dự kiến tính tới hiện tại",
  crops: "Cây trồng",
  min_crops_1: "Cần có ít nhất 1 loại cây trồng",
  crops_type: "Loại cây trồng",
  crops_other: "Loại cây trồng khác",
  cultivated_area: "DT canh tác",
  parcel_of_land: "Thửa đất",
  tree_per_area: "Số cây/trụ",
  expected_output: "Sản lượng dự kiến",
  note: "Ghi chú",
  solution_improvement: "Giải pháp cải tiến",
  pillar_unit: "Trụ",
  total_yield_harvested: "Tổng sản lượng thu hoạch",
  total_revenue: "Tổng doanh thu",
  total_cost: "Tổng chi phí",
  expected_total_cost: "Tổng chi phí dự kiến",

  gross_profit: "Lợi nhuận gộp",

  down_seed_date_greater_than_expected_harvest_date:
    "Ngày thu hoạch phải lớn hơn ngày xuống giống",
  standard_of_cultivation: "Tiêu chuẩn canh tác",
  acreage_farming: "Diện tích canh tác",
  crops_type: "Loại cây trồng",
  harvest_date: "Ngày thu hoạch",
  classify: "Phân loại",
  yield_by_type: "Sản lượng theo loại",
  recent_activity: "Hoạt động gần đây",
  recent_farm_season: "Mùa vụ gần đây",
  input_type: "Loại đầu vào",
  order_date_greater_than_received_date: "Ngày nhận phải lớn hơn ngày đặt",
  create_farm_season_successful: "Tạo mùa vụ thành công",
  running: "Đang chạy",
  not_run: "Chưa chạy",
  update_farm_successful: "Cập nhật mùa vụ thành công",

  // procedure
  procedure_name: "Tên quy trình",
  procedure_status: "Trạng thái",
  procedure_step: "Giai đoạn",
  supplier: "Nhà cung cấp",
  count: "Số lượng",
  unit_cal: "ĐVT",
  order_date: "Ngày đặt",
  procedure_unit_price: "Đơn giá",
  into_money: "Thành tiền",
  received_date: "Ngày nhận",
  estimated_unit_price: "Đơn giá ước tính",
  estimated_money: "Thành tiền ước tính",
  hectare: "Ha",
  ton: "Tấn",
  forecast_production_season_results: "Dự báo kết quả mùa vụ sản xuất",

  /**
   * FARM SEASON
   */
  farm_performance: "Hiệu suất sản xuất của trang trại",

  // farm season input - supplies:
  supplies: "Vật tư",
  labor: "Nhân công",
  employee_id: "Mã nhân viên",
  employee_full_name: "Họ Tên nhân viên",
  work: "Công việc",

  // farm season input - employee:
  employee_work_day_month: "Số ngày làm việc trên tháng",
  employee_salary: "Lương tháng",
  employee_work_day_season: "Số ngày làm việc trong mùa vụ",
  employee_cost_season: "Chi phí Lương Mùa vụ",
  //note: Ghi chú

  // farm season input - others:
  other_cost_detail: "Mô tả chi phí",
  // unit_price
  // quantity
  // into_money
  // note
  allocation_add: "Thêm phân bổ",
  allocation_done: "Tạo xong phân bổ",
  allocation_type_placeholder: "Loại",
  allocation_percent_placeholder: "% phân bổ",
  required_allocation_percent: "Vui lòng nhập % phân bổ",
  required_allocation_type: "Vui lòng chọn loại",
  percent: "%",
  year: "Năm",
  no_name: "không có tên",
  edit_farm_season: "Sửa mùa vụ",
  create_farm_season: "Tạo mùa vụ",
  environmental_parameters_from_the_iodine_device:
    "Thông số môi trường từ thiết bị IoT",
  recent_farming_activities: "Hoạt động canh tác gần đây",
  upcoming_farming_schedule: "Lịch canh tác sắp tới",
  percent_name: "Phần trăm phân bổ",
  tree_unit: "cây",

  /**
   * TAY TRAN
   */

  // Menu items
  MENU_MASTER_DATA: "Dữ liệu chủ",
  MENU_DASHBOARD: "Tổng quan",
  MENU_ORDER: "Đơn hàng",
  MENU_USER: "Người dùng",
  MENU_REPORT: "Báo cáo",
  MENU_CONFIG: "Cấu hình",
  MENU_CATEGORY: "Danh mục sản phẩm",
  MENU_PRODUCTS: "Sản phẩm",
  MENU_ALL: "Tất cả",
  MENU_CREATE: "Thêm mới",
  MENU_BUSINESS_CERTIFICATE: "GCN kinh doanh",
  MENU_STANDARD: "GCN tiêu chuẩn canh tác",
  MENU_ADD_PRODUCT: "Thêm sản phẩm",
  MENU_CERTIFICATION_LAND: "GCN QSDĐ",
  MENU_CULTIVATION: "Loại hình canh tác",
  MENU_CROPS: "Cây trồng",
  MENU_MARKETS: "Thị trường tiêu thụ",
  MENU_FORM_OF_USES: "Tình trạng sử dụng đất",
  MENU_FARM_MANAGERMENT: "Quản lý trang trại",
  MENU_FARM_LIST: "Danh sách trang trại",
  MENU_LIST: "Danh sách",
  MENU_FARM: "Danh sách trang trại",
  MENU_PRODUCT: "Sản phẩm gốc",
  MENU_PRODUCT_LIST: "Tất cả sản phẩm",
  MENU_PRODUCT_ADD: "Thêm sản phẩm",
  MENU_SEASON: "Danh sách mùa vụ",
  MENU_DIARY: "Nhật ký sản xuất",
  MENU_VIEW: "Xem chi tiết",
  MENU_EDIT: "Chỉnh sửa",

  // Menu items

  // Menu item navbar
  NAVBAR_MENU_MASTER_DATA: "Dữ liệu chủ",
  NAVBAR_MENU_DASHBOARD: "Tổng quan",
  NAVBAR_MENU_PRODUCT: "Sản phẩm",
  NAVBAR_MENU_ORDER: "Đơn hàng",
  NAVBAR_MENU_USER: "Người dùng",
  NAVBAR_MENU_REPORT: "Báo cáo",
  NAVBAR_MEN_CONFIG: "Cấu hình",

  //Global text
  HEADER_PLACEHOLDER_SEARCH: "Tìm kiếm danh mục",

  // CATEGORY PAGE
  CATEGORY_PAGE_TITLE_FORM: "Tạo danh mục mới",
  CATEGORY_PAGE_FORM_LABLE_NAME: "Tên danh mục",
  CATEGORY_PAGE_FORM_LABLE_SLUG: "Slug",
  CATEGORY_PAGE_FORM_LABLE_CATEGORY: "Danh mục cha",
  CATEGORY_PAGE_FORM_LABLE_DESCRIPTION: "Mô tả",
  CATEGORY_PAGE_FORM_LABLE_CATEGORY_TYPE: "Loại danh mục",
  CATEGORY_PAGE_FORM_LABLE_THUMBNAIL: "Thumbnail",
  CATEGORY_PAGE_FORM_BUTTON_CREATE_CATEGORY: "Thêm danh mục mới",
  CATEGORY_PAGE_FORM_SHORT_DESCRIPTION_NAME:
    "Tên danh mục sẽ được hiển thị lên trên website",
  CATEGORY_PAGE_FORM_SHORT_DESCRIPTION_SLUG:
    ' "slug" là tên của liên kết (URL) hiển thị theo cách thân thiện dễ hiểu. Slug thường được đặt bởi các ký tự thường (không in hoa), số và các ký tự như (-) hoặc (_)',
  CATEGORY_PAGE_FORM_SHORT_DESCRIPTION_PARENT:
    "Chọn danh mục cha để có thể kế thừa các sản phẩm trong danh mục cha",
  CATEGORY_PAGE_FORM_SHORT_DESCRIPTION_DESCRIPTION:
    "Mô tả danh mục chỉ được dùng để hiểu rõ hơn, không được hiển thị trên website",
  CATEGORY_PAGE_FORM_SHORT_DESCRIPTION_TYPE: "Loại danh mục",
  CATEGORY_PAGE_FORM_PLACEHOLDER_FILTER: "Chọn bộ lọc",
  CATEGORY_PAGE_TABLE_COLUMN_TITLE_PICTURE: "Hình",
  CATEGORY_PAGE_TABLE_COLUMN_TITLE_CATEGORY_NAME: "Tên danh mục",
  CATEGORY_PAGE_TABLE_COLUMN_TITLE_DESCRIPTION: "Mô tả",
  CATEGORY_PAGE_TABLE_COLUMN_TITLE_SLUG: "Slug",
  CATEGORY_PAGE_TABLE_COLUMN_TITLE_AMOUNT_PRODUCT: "Tổng sản phẩm",
  CATEGORY_PAGE_COUNT_PRODUCT: "danh mục",
  CATEGORY_PAGE_FORM_FOOTER_NOTE:
    "Việc xóa danh mục sản phẩm sẽ không xóa sản phẩm nằm trong danh mục đó nhưng sản phẩm nằm trong danh mục đã xóa sẽ được chọn tự động gán vào danh mục sản phẩm mặc định của hệ thống.",
  CATEGORY_PAGE_FORM_UPLOAD: "Tải lên",
  CATEGORY_PAGE_TABLE_BUTTON_EDIT: "Sửa",
  CATEGORY_PAGE_TABLE_BUTTON_DELETE: "Xóa",
  CATEGORY_PAGE_TABLE_BUTTON_VIEW: "Xem",
  CATEGORY_PAGE_CREATE_SUCCESS: "Thêm danh mục thành công",
  CATEGORY_PAGE_FORM_PLACEHOLDER_SELECT_TYPE: "Chọn loại danh mục",
  CATEGORY_PAGE_DELETE_CATEGORY_CONFIRM:
    "Bạn có chắc muốn xóa danh mục này không?",
  CATEGORY_PAGE_FORM_BUTTON_EDIT_CATEGORY: "Cập nhật danh mục",
  CATEGORY_PAGE_EDIT_SUCCESS: "Cập nhật danh mục thành công",
  CATEGORY_PAGE_DELETE_SUCCESS: "Xóa danh mục thành công",
  CATEGORY_PAGE_OPTION_LABEL_PRODUCT: "Nông sản",
  CATEGORY_PAGE_OPTION_LABEL_MATERIAL: "Đầu vào nông nghiệp",
  CATEGORY_PAGE_FIRST_DESCRIPTION:
    'Product categories for your store can be mananged here. To change the order of categries on the front-end you can drag and drop to sort them. To see more categories listed click the "screen options" link at the top-right of this page.',
  CATEGORY_PAGE_VIEW_PRODUCTS_TITLE: "Xem sản phẩm",
  CATEGORY_PAGE_MESSAGE_DELETE_FALURE: "Danh mục không thể xóa!",
  CATEGORY_PAGE_MESSAGE_UPDATE_FALURE_NAME_EXIST: "Tên danh mục đã tồn tại!",

  /**
   * ORIGINAL PRODUCT
   */
  ORIGINAL_PAGE_TITLE: "Sản phẩm",
  ADD_PRODUCT_PAGE_TITLE: "Thêm mới sản phẩm",
  ORIGINAL_PRODUCT_TABLE_COLUMN_PICTURE: "Hình",
  ORIGINAL_PRODUCT_TABLE_COLUMN_NAME: "Tên sản phẩm",
  ORIGINAL_PRODUCT_TABLE_COLUMN_CODE: "Mã sản phẩm",
  ORIGINAL_PRODUCT_TABLE_COLUMN_CATEGORIES: "Danh mục sản phẩm",
  ORIGINAL_PRODUCT_TABLE_COLUMN_TAGS: "Tags",
  ORIGINAL_PRODUCT_TABLE_COLUMN_CREATE_DATE: "Ngày tạo",
  ORIGINAL_PRODUCT_FILTER_CATEGORY_PLACEHOLDER: "Chọn danh mục",
  ORIGINAL_PRODUCT_PAGE_DELETE_PRODUCT_CONFIRM:
    "Bạn có chắc muốn xóa sản phẩm này không?",
  ORIGINAL_PRODUCT_PAGE_DELETE_PRODUCT_SUCCESS: "Xóa sản phẩm thành công",
  /**
   * ADD PRODUCT PAGE
   */
  product_name: "Tên sản phẩm",
  material_name: "Tên vật tư",
  category: "Danh mục",
  category_all: "Tất cả",
  category_always: "Thường dùng",
  add_tags: "Thêm",
  avatar: "Hình đại diện",
  text_note_tags: "Phân biệt giữa các tags bằng dấu chấm phẩy.",
  title_upload_images: "Chọn hình sản phẩm",
  product_page_count_product: "sản phẩm",
  add_new_product: "Thêm sản phẩm mới",
  product_description: "Mô tả ngắn sản phẩm",
  product_description_detail: "Mô tả chi tiết sản phẩm",
  product_images: "Hình sản phẩm",
  products_all: "Sản phẩm nông sản",
  product_add_new: "Thêm mới",
  product_page_search_placeholder: "Tìm kiếm sản phẩm",
  product_page_message_add_success: "Thêm sản phẩm thành công",
  product_page_update_button: "Cập nhật sản phẩm",
  product_page_message_update_success: "Cập nhật sản phẩm thành công",
  product_page_title_add: "Thêm sản phẩm mới",
  product_page_title_edit: "Cập nhật sản phẩm",
  product_page_title_view: "Chi tiết sản phẩm",
  product_page_back_button: "Quay lại",
  product_page_not_found_product: "Không tìm thấy sản phẩm",
  /**
   * render button menu header
   */
  service_creation: "Tạo dịch vụ",

  /**
   * BUSINESS CERTIFICATE PAGE
   */
  BUSINESS_CER_DELETE_SUCCESS: "Xóa giấy chứng nhận thành công",
  BUSINESS_CER_CREATE_SUCCESS: "Thêm giấy chứng nhận thành công",
  BUSINESS_CER_UPDATE_SUCCESS: "Cập nhật giấy chứng nhận thành công",
  BUSINESS_CER_DELETE_CONFIRM:
    "Bạn có chắc muốn xóa giấy chứng nhận này không?",
  BUSINESS_CER_PAGE_TITLE: "Giấy chứng nhận kinh doanh",
  BUSINESS_CER_PAGE_FIRST_DESCRIPTION:
    "Giấy chứng nhận kinh doanh có thể được quản lý ở đây.",
  BUSINESS_CER_PAGE_HEADER_PLACEHOLDER_SEARCH:
    "Tìm kiếm giấy chứng nhận kinh doanh",
  BUSINESS_CER_PAGE_TITLE_FORM_ADD: "Tạo mới giấy chứng nhận kinh doanh",
  BUSINESS_CER_PAGE_TITLE_FORM_UPDATE: "Cập nhật giấy chứng nhận kinh doanh",
  BUSINESS_CER_PAGE_FORM_LABEL_NAME: "Tên giấy chứng nhận",
  BUSINESS_CER_PAGE_FORM_SHORT_DESCRIPTION_NAME:
    "Tên giấy chứng nhận sẽ được hiển thị trên website",
  BUSINESS_CER_PAGE_FORM_LABLE_SLUG: "Slug",
  BUSINESS_CER_PAGE_FORM_LABLE_DESCRIPTION: "Mô tả",
  BUSINESS_CER_PAGE_FORM_SHORT_DESCRIPTION_SLUG:
    ' "slug" là tên của liên kết (URL) hiển thị theo cách thân thiện dễ hiểu. Slug thường được đặt bởi các ký tự thường (không in hoa), số và các ký tự như (-) hoặc (_)',
  BUSINESS_CER_PAGE_FORM_SHORT_DESCRIPTION_DESCRIPTION:
    "Mô tả giấy chứng nhận kinh doanh chỉ được dùng để hiểu rõ hơn, không được hiển thị trên website",

  BUSINESS_CER_FORM_FOOTER_NOTE: "",
  BUSINESS_CER_PAGE_TABLE_COLUMN_TITLE_PICTURE: "Hình",
  BUSINESS_CER_PAGE_TABLE_COLUMN_TITLE_CERTIFICATION_NAME:
    "Tên giấy chứng nhận",
  BUSINESS_CER_PAGE_TABLE_COLUMN_TITLE_DESCRIPTION: "Mô tả",
  BUSINESS_CER_PAGE_TABLE_COLUMN_TITLE_SLUG: "Slug",
  BUSINESS_CER_PAGE_FORM_BUTTON_CREATE: "Thêm giấy chứng nhận mới",
  BUSINESS_CER_PAGE_FORM_BUTTON_EDIT: "Cập nhật",
  MASTER_DATA_ADMIN_TITLE: "FARM ADMIN",

  /**
   * BUSINESS CERTIFICATE OF LAND PAGE
   */
  BUSINESS_CER_LAND_DELETE_SUCCESS: "Xóa giấy chứng nhận QSDĐ thành công",
  BUSINESS_CER_LAND_CREATE_SUCCESS: "Thêm giấy chứng nhận QSDĐ thành công",
  BUSINESS_CER_LAND_UPDATE_SUCCESS: "Cập nhật giấy chứng nhận QSDĐ thành công",
  BUSINESS_CER_LAND_DELETE_CONFIRM:
    "Bạn có chắc muốn xóa giấy chứng nhận QSDĐ này không?",
  BUSINESS_CER_LAND_PAGE_TITLE: "Giấy chứng nhận QSDĐ",
  BUSINESS_CER_LAND_PAGE_FIRST_DESCRIPTION:
    "Giấy chứng nhận QSDĐ có thể được quản lý ở đây.",
  BUSINESS_CER_LAND_PAGE_HEADER_PLACEHOLDER_SEARCH:
    "Tìm kiếm giấy chứng nhận QSDĐ",
  BUSINESS_CER_LAND_PAGE_TITLE_FORM_ADD: "Tạo mới giấy chứng nhận QSDĐ",
  BUSINESS_CER_LAND_PAGE_TITLE_FORM_UPDATE: "Cập nhật giấy chứng nhận QSDĐ",
  BUSINESS_CER_LAND_PAGE_FORM_LABEL_NAME: "Tên giấy chứng nhận",
  BUSINESS_CER_LAND_PAGE_FORM_SHORT_DESCRIPTION_NAME:
    "Tên giấy chứng nhận QSDĐ sẽ được hiển thị trên website",
  BUSINESS_CER_LAND_PAGE_FORM_LABLE_SLUG: "Slug",
  BUSINESS_CER_LAND_PAGE_FORM_LABLE_DESCRIPTION: "Mô tả",
  BUSINESS_CER_LAND_PAGE_FORM_SHORT_DESCRIPTION_SLUG:
    ' "slug" là tên của liên kết (URL) hiển thị theo cách thân thiện dễ hiểu. Slug thường được đặt bởi các ký tự thường (không in hoa), số và các ký tự như (-) hoặc (_)',
  BUSINESS_CER_LAND_PAGE_FORM_SHORT_DESCRIPTION_DESCRIPTION:
    "Mô tả giấy chứng nhận QSDĐ chỉ được dùng để hiểu rõ hơn, không được hiển thị trên website",

  BUSINESS_CER_LAND_FORM_FOOTER_NOTE: "",
  BUSINESS_CER_LAND_PAGE_TABLE_COLUMN_TITLE_CERTIFICATION_NAME: "Tên giấy QSDĐ",

  BUSINESS_CER_LAND_PAGE_FORM_BUTTON_CREATE: "Thêm giấy chứng nhận mới",
  BUSINESS_CER_LAND_PAGE_FORM_BUTTON_EDIT: "Cập nhật",
  /**
   * PRODUCT PAGE
   */
  PRODUCT_DELETE_SUCCESS: "Xóa cây trồng thành công",
  PRODUCT_CREATE_SUCCESS: "Thêm cây trồng thành công",
  PRODUCT_UPDATE_SUCCESS: "Cập nhật cây trồng thành công",
  PRODUCT_DELETE_CONFIRM: "Bạn có chắc muốn xóa cây trồng này không?",
  PRODUCT_PAGE_TITLE: "Danh sách cây trồng",
  PRODUCT_PAGE_FIRST_DESCRIPTION:
    "Danh sách cây trồng có thể được quản lý ở đây.",
  PRODUCT_PAGE_HEADER_PLACEHOLDER_SEARCH: "Tìm kiếm cây trồng",
  PRODUCT_PAGE_TITLE_FORM_ADD: "Tạo mới cây trồng",
  PRODUCT_PAGE_TITLE_FORM_UPDATE: "Cập nhật cây trồng",
  PRODUCT_PAGE_FORM_LABEL_NAME: "Tên chứng nhận",
  PRODUCT_PAGE_FORM_SHORT_DESCRIPTION_NAME:
    "Tên cây trồng sẽ được hiển thị trên website",
  PRODUCT_PAGE_FORM_LABLE_SLUG: "Slug",
  PRODUCT_PAGE_FORM_LABLE_DESCRIPTION: "Mô tả",
  PRODUCT_PAGE_FORM_SHORT_DESCRIPTION_SLUG:
    ' "slug" là tên của liên kết (URL) hiển thị theo cách thân thiện dễ hiểu. Slug thường được đặt bởi các ký tự thường (không in hoa), số và các ký tự như (-) hoặc (_)',
  PRODUCT_PAGE_FORM_SHORT_DESCRIPTION_DESCRIPTION:
    "Mô tả cây trồng chỉ được dùng để hiểu rõ hơn, không được hiển thị trên website",

  PRODUCT_FORM_FOOTER_NOTE: "",
  PRODUCT_PAGE_TABLE_COLUMN_TITLE_CERTIFICATION_NAME: "Tên cây trồng",
  PRODUCT_PAGE_FORM_BUTTON_CREATE: "Thêm Cây trồng mới",
  PRODUCT_PAGE_FORM_BUTTON_EIDT: "Cập nhật",

  /**
   * CULTIVATION PAGE
   */
  CULTIVATION_DELETE_SUCCESS: "Xóa loại hình canh tác thành công",
  CULTIVATION_CREATE_SUCCESS: "Thêm loại hình canh tác thành công",
  CULTIVATION_UPDATE_SUCCESS: "Cập nhật loại hình canh tác thành công",
  CULTIVATION_DELETE_CONFIRM:
    "Bạn có chắc muốn xóa loại hình canh tác này không?",
  CULTIVATION_PAGE_TITLE: "Loại hình canh tác",
  CULTIVATION_PAGE_FIRST_DESCRIPTION:
    "Danh sách loại hình canh tác có thể được quản lý ở đây.",
  CULTIVATION_PAGE_HEADER_PLACEHOLDER_SEARCH: "Tìm kiếm loại hình canh tác",
  CULTIVATION_PAGE_TITLE_FORM_ADD: "Tạo mới loại hình canh tác",
  CULTIVATION_PAGE_TITLE_FORM_UPDATE: "Cập nhật loại hình canh tác",
  CULTIVATION_PAGE_FORM_LABEL_NAME: "Tên chứng nhận",
  CULTIVATION_PAGE_FORM_SHORT_DESCRIPTION_NAME:
    "Tên loại hình canh tác sẽ được hiển thị trên website",
  CULTIVATION_PAGE_FORM_LABLE_SLUG: "Slug",
  CULTIVATION_PAGE_FORM_LABLE_DESCRIPTION: "Mô tả",
  CULTIVATION_PAGE_FORM_SHORT_DESCRIPTION_SLUG:
    ' "slug" là tên của liên kết (URL) hiển thị theo cách thân thiện dễ hiểu. Slug thường được đặt bởi các ký tự thường (không in hoa), số và các ký tự như (-) hoặc (_)',
  CULTIVATION_PAGE_FORM_SHORT_DESCRIPTION_DESCRIPTION:
    "Mô tả loại hình canh tác chỉ được dùng để hiểu rõ hơn, không được hiển thị trên website",

  CULTIVATION_FORM_FOOTER_NOTE: "",
  CULTIVATION_PAGE_TABLE_COLUMN_TITLE_CERTIFICATION_NAME:
    "Tên loại hình canh tác",
  CULTIVATION_PAGE_FORM_BUTTON_CREATE: "Thêm loại hình canh tác mới",

  /**
   * MARKETS PAGE
   */
  MARKET_DELETE_SUCCESS: "Xóa thị trường tiêu thụ thành công",
  MARKET_CREATE_SUCCESS: "Thêm thị trường tiêu thụ thành công",
  MARKET_UPDATE_SUCCESS: "Cập nhật thị trường tiêu thụ thành công",
  MARKET_DELETE_CONFIRM: "Bạn có chắc muốn xóa thị trường tiêu thụ này không?",
  MARKET_PAGE_TITLE: "Thị trường tiêu thụ",
  MARKET_PAGE_FIRST_DESCRIPTION:
    "Danh sách thị trường tiêu thụ có thể được quản lý ở đây.",
  MARKET_PAGE_HEADER_PLACEHOLDER_SEARCH: "Tìm kiếm thị trường tiêu thụ",
  MARKET_PAGE_TITLE_FORM_ADD: "Tạo mới thị trường tiêu thụ",
  MARKET_PAGE_TITLE_FORM_UPDATE: "Cập nhật thị trường tiêu thụ",
  MARKET_PAGE_FORM_LABEL_NAME: "Tên chứng nhận",
  MARKET_PAGE_FORM_SHORT_DESCRIPTION_NAME:
    "Tên thị trường tiêu thụ sẽ được hiển thị trên website",
  MARKET_PAGE_FORM_LABLE_SLUG: "Slug",
  MARKET_PAGE_FORM_LABLE_DESCRIPTION: "Mô tả",
  MARKET_PAGE_FORM_SHORT_DESCRIPTION_SLUG:
    ' "slug" là tên của liên kết (URL) hiển thị theo cách thân thiện dễ hiểu. Slug thường được đặt bởi các ký tự thường (không in hoa), số và các ký tự như (-) hoặc (_)',
  MARKET_PAGE_FORM_SHORT_DESCRIPTION_DESCRIPTION:
    "Mô tả thị trường tiêu thụ chỉ được dùng để hiểu rõ hơn, không được hiển thị trên website",

  MARKET_FORM_FOOTER_NOTE: "",
  MARKET_PAGE_TABLE_COLUMN_TITLE_CERTIFICATION_NAME: "Tên thị trường tiêu thụ",
  MARKET_PAGE_FORM_BUTTON_CREATE: "Thêm thị trường tiêu thụ mới",

  /**
   * FORM OF USES PAGE
   */
  FORM_OF_USES_DELETE_SUCCESS: "Xóa tình trạng thành công",
  FORM_OF_USES_CREATE_SUCCESS: "Thêm tình trạng mới thành công",
  FORM_OF_USES_UPDATE_SUCCESS: "Cập nhật tình trạng thành công",
  FORM_OF_USES_DELETE_CONFIRM:
    "Bạn có chắc muốn xóa tình trạng sử dụng đất này không?",
  FORM_OF_USES_PAGE_TITLE: "Tình trạng sử dụng đất",
  FORM_OF_USES_PAGE_FIRST_DESCRIPTION:
    "Danh sách tình trạng sở hữu đất có thể được quản lý ở đây.",
  FORM_OF_USES_PAGE_HEADER_PLACEHOLDER_SEARCH: "Tìm kiếm",
  FORM_OF_USES_PAGE_TITLE_FORM_ADD: "Tạo mới tình trạng",
  FORM_OF_USES_PAGE_TITLE_FORM_UPDATE: "Cập nhật tình trạng",
  FORM_OF_USES_PAGE_FORM_LABEL_NAME: "Tên chứng nhận",
  FORM_OF_USES_PAGE_FORM_SHORT_DESCRIPTION_NAME:
    "Tên tình trạng sẽ được hiển thị trên website",
  FORM_OF_USES_PAGE_FORM_LABLE_SLUG: "Slug",
  FORM_OF_USES_PAGE_FORM_LABLE_DESCRIPTION: "Mô tả",
  FORM_OF_USES_PAGE_FORM_SHORT_DESCRIPTION_SLUG:
    ' "slug" là tên của liên kết (URL) hiển thị theo cách thân thiện dễ hiểu. Slug thường được đặt bởi các ký tự thường (không in hoa), số và các ký tự như (-) hoặc (_)',
  FORM_OF_USES_PAGE_FORM_SHORT_DESCRIPTION_DESCRIPTION:
    "Mô tả tình trạng chỉ được dùng để hiểu rõ hơn, không được hiển thị trên website",

  FORM_OF_USES_FORM_FOOTER_NOTE: "",
  FORM_OF_USES_PAGE_TABLE_COLUMN_TITLE_CERTIFICATION_NAME: "Tên tình trạng",
  FORM_OF_USES_PAGE_FORM_BUTTON_CREATE: "Thêm tình trạng mới",

  /**
   * FARM PAGE
   */
  FARM_TABLE_COLUMN_ID: "ID",
  FARM_TABLE_COLUMN_IMAGE: "ẢNH ĐẠI DIỆN",
  FARM_TABLE_COLUMN_NAME: "TÊN TRANG TRẠI",
  FARM_TABLE_COLUMN_PHONE: "SỐ ĐIỆN THOẠI",
  FARM_TABLE_COLUMN_ADDRESS: "ĐỊA CHỈ TRANG TRẠI",
  FARM_TABLE_COLUMN_AREA: "DIỆN TÍCH CANH TÁC",
  farm_page_title_view: "Chi tiết trang trại",
  farm_page_title_edit: "Chỉnh sửa thông tin trang trại",
  /**
   * FARM PAGE
   */

  /**
   * SEASON PAGE
   */
  SEASON_TABLE_COLUMN_ID: "ID",
  SEASON_TABLE_COLUMN_IMAGE: "ẢNH ĐẠI DIỆN",
  SEASON_TABLE_COLUMN_NAME: "TÊN MÙA VỤ",
  SEASON_TABLE_COLUMN_FARM: "TÊN TRANG TRẠI",
  SEASON_TABLE_COLUMN_CROPS: "LOẠI CÂY TRỒNG",
  SEASON_TABLE_COLUMN_SOWING: "NGÀY XUỐNG GIỐNG",
  SEASON_TABLE_COLUMN_HARVEST: "NGÀY THU HOẠCH",
  SEASON_TABLE_COLUMN_EXPECTED: "SẢN LƯỢNG DỰ KIẾN",
  SEASON_TABLE_COLUMN_DIARY: "LỊCH SỬ CANH TÁC",
  SEASON_page_title_view: "Chi tiết trang trại",
  SEASON_page_title_edit: "Chỉnh sửa thông tin trang trại",
  /**
   * SEASON PAGE
   */

  /**
   * DIARY PAGE
   */
  DIARY_TABLE_COLUMN_ID: "ID",
  DIARY_TABLE_COLUMN_CREATED_DATE: "NGÀY CANH TÁC",
  DIARY_TABLE_COLUMN_WORKS: "CÔNG VIỆC",
  DIARY_TABLE_COLUMN_FARM: "TRANG TRẠI",
  DIARY_TABLE_COLUMN_SEASON: "MÙA VỤ",
  DIARY_page_title_view: "Chi tiết nhật ký sản xuất",
  DIARY_page_title_edit: "Chỉnh sửa thông tin trang trại",
  /**
   * DIARY PAGE
   */
};

export default vi;

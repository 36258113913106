import React from "react";
import { compose } from "recompose";
import { PageTitle } from "~/views/presentation/ui/commons";
import strings from "~/localization";
import { ContentWrapper } from "~/views/presentation/ui/container";
import ViewPage from "../components/ViewPage";

class MarketContent extends React.PureComponent {
  render() {
    return (
      <ContentWrapper>
        <PageTitle title={strings.MARKET_PAGE_TITLE} breadcrumb={false} />
        <ViewPage KeyString="MARKET" KeyGetApi="MARKET" KeyActionApi="market" />
      </ContentWrapper>
    );
  }
}

export default MarketContent;

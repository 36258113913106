import React from "react";
import { compose } from "recompose";
import { PageTitle } from "~/views/presentation/ui/commons";
import strings from "~/localization";
import { ContentWrapper } from "~/views/presentation/ui/container";
import ViewPage from "../components/ViewPage";

class CropsProductContent extends React.PureComponent {
  render() {
    return (
      <ContentWrapper>
        <PageTitle title={strings.PRODUCT_PAGE_TITLE} breadcrumb={false} />
        <ViewPage
          KeyString="PRODUCT"
          KeyGetApi="PRODUCT"
          KeyActionApi="product"
        />
      </ContentWrapper>
    );
  }
}

export default CropsProductContent;

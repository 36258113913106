import React from "react";
import { connect } from "react-redux";
import { compose, withHandlers, lifecycle } from "recompose";
import { withRouter } from "react-router-dom";
import { getAuthUser } from "~/state/ducks/authUser/selectors";
import { authActions } from "~/state/ducks/authUser";
import {
  LOGIN_PATH,
  APP_DEFAULT_PATH,
  DASHBOARD_PATH,
} from "~/configs/routesConfig";

import styled from "styled-components";
import {
  getString,
  getArray,
  getBool,
} from "~/views/utilities/helpers/utilObject";
import strings from "~/localization";
import { Menu } from "antd";
const { SubMenu } = Menu;

const SubMenuStyled = styled(SubMenu)`
  .active {
    color: #fff;
    font-weight: 500;
  }
`;

function NavbarFarmBar(props) {
  const { topNav, history, handleLogout, location, match } = props;
  const handleClick = (e) => {
    // this.setState({ current: e.key });
  };

  return (
    <Menu
      defaultSelectedKeys={"/"}
      selectedKeys={[location.pathname]}
      style={{ height: "100%" }}
      // className="branding"
      // onClick={handleClick}
      // selectedKeys={e}
      mode={props.topNav.mode || "horizontal"}
    >
      {getArray(props, "topNav.slot", []).map((item, index) => {
        return (
          <SubMenuStyled
            popupClassName={"active"}
            title={
              <div
                className={
                  getString(match, "path", "")
                    .replace("/:id", "")
                    .replace("/:type", "") ===
                  getString(item, "key", "")
                    .replace("/:type", "")
                    .replace(":id")
                    ? "active"
                    : null
                }
              >
                {item.title}
              </div>
            }
            key={index}
            onTitleClick={() => {
              if (item.path !== "#") history.push(item.path);
            }}
          >
            {getArray(item, "subTitle", []).map((sub, index) => {
              return (
                <Menu.ItemGroup title={sub.name} key={sub.path}>
                  {getArray(sub, "option", []).map((options, index) => {
                    return (
                      <Menu.Item key={options.path}>{options.name}</Menu.Item>
                    );
                  })}
                </Menu.ItemGroup>
              );
            })}
          </SubMenuStyled>
        );
      })}
    </Menu>
  );
}

export default compose(
  withRouter,
  connect(
    (state) => ({
      user: getAuthUser(state),
    }),
    {
      logout: authActions.logout,
    },
  ),
  withHandlers({
    handleLogout:
      ({ logout }) =>
      () => {
        logout();
      },
    // handleChangeSize: () => ({ onResize, size }) => () => {
    //   switch (size) {
    //     case MenuSize.large:
    //       {
    //         onResize && onResize(MenuSize.medium);
    //       }
    //       break;
    //     case MenuSize.medium:
    //       {
    //         onResize && onResize(MenuSize.small);
    //       }
    //       break;
    //     case MenuSize.small:
    //       {
    //         onResize && onResize(MenuSize.large);
    //       }
    //       break;
    //   }
    // },
  }),
)(NavbarFarmBar);

import React, { useState } from "react";
import { connect } from "react-redux";
import {
  InputField,
  InputNumber,
  SelectField,
} from "~/views/presentation/ui/fields";
import strings from "~/localization";
import { TableEditable } from "~/views/presentation/ui/tables";
import { Card, Tabs, message } from "antd";
import * as yup from "yup";
import { Formik } from "formik";
import StepHeader from "./StepHeader";
import { UIButton } from "~/views/presentation/ui/buttons";
const { TabPane } = Tabs;

export const Manufacturing = (props) => {
  const {
    value,
    action,
    product,
    unitsArea,
    unitsMass,
    handleSetValue,
    items,
    setStep,
    handleUpdateFarm,
  } = props;

  const [cropId, setCropId] = useState();
  const defaultValue = {
    farmingSeasonNumber: null,
    productType: {
      id: undefined,
      name: "",
    },
    grossProductivities: {
      value: null,
      interval: "YEAR",
      unitId: null,
    },
    grossYields: {
      value: null,
      unitId: null,
    },
    grossAreas: {
      unitId: null,
      value: null,
    },
  };

  const [currentValue, setCurrentValue] = useState(defaultValue);
  const [nextStep, setNextStep] = useState(0);

  const validationSchema = yup.object().shape({
    // products: yup.array().min(1, strings.min_crops_1),
  });

  const handleSelectRow = (value) => {
    setCurrentValue(value);
    setCropId(value.productType.id);
  };

  const columns = [
    {
      title: strings.crops_type,
      dataIndex: ["productType", "name"],
      key: "productTypeName",
      width: "25%",
      editable: false,
    },
    {
      title: strings.total_cultivated_area,
      dataIndex: ["grossAreas", "value"],
      key: "grossAreasValue",
      width: "25%",
      align: "center",
      editable: false,
      render: (cell, row) => {
        const unit = row.grossAreas
          ? unitsArea.find((f) => f.value === row.grossAreas.unitId)
          : undefined;
        return (
          <div onClick={() => handleSelectRow(row)}>
            {cell || cell === 0 ? cell + " " + (unit && unit.label) : "-"}
          </div>
        );
      },
    },
    {
      title: strings.total_productivity,
      dataIndex: ["grossProductivities", "value"],
      key: "grossProductivitiesValue",
      width: "25%",
      editable: false,
      align: "center",
      render: (cell, row) => {
        const unit = row.grossProductivities
          ? unitsMass.find((f) => f.value === row.grossProductivities.unitId)
          : undefined;

        return (
          <div onClick={() => handleSelectRow(row)}>
            {cell || cell === 0 ? cell + " " + (unit && unit.label) : "-"}
          </div>
        );
      },
    },
    {
      title: strings.number_seasons,
      dataIndex: "farmingSeasonNumber",
      key: "farmingSeasonNumber",
      width: "25%",
      align: "center",
      editable: false,
    },
  ];

  return (
    <Formik
      initialValues={{ products: value }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        if (values.products.length || action === "view") {
          handleSetValue(values);
          setStep(nextStep);
        } else {
          message.error(strings.min_crops_1);
        }
      }}
    >
      {(props) => {
        const { handleSubmit, handleChange, values } = props;
        return (
          <form onSubmit={handleSubmit}>
            <StepHeader
              setStep={(val) => {
                if (val < 1) {
                  handleSetValue(values);
                  setStep(nextStep);
                } else {
                  handleSubmit(values);
                  setNextStep(val);
                }
              }}
              step={1}
              items={items}
              values={values}
              handleUpdateFarm={handleUpdateFarm}
            />
            <div className="row">
              <div className="col-12">
                <div className="row col-md-6 col-sm-6 col-lg-6 col-12 mt-10">
                  <div className="col-12">
                    <SelectField
                      showSearch
                      require
                      disabled={action === "view"}
                      className="w-100"
                      value={
                        currentValue && currentValue.productType
                          ? currentValue.productType.id
                          : undefined
                      }
                      data={[...product, { value: null, label: strings.other }]}
                      onChange={(value) => {
                        setCropId(value);
                        //to do after
                        setCurrentValue({
                          ...currentValue,
                          productType: {
                            name: value
                              ? product.find((f) => f.value === value).label
                              : "",
                            id: value,
                          },
                        });
                      }}
                      iconEnd="caret-down"
                      placeholder={strings.crops}
                      label={strings.crops}
                    />
                  </div>
                  {cropId === null && (
                    <div className="col-12">
                      <InputField
                        disabled={action === "view"}
                        label={strings.crops_other}
                        placeholder={strings.crops_other}
                        name="name"
                        inline={false}
                        value={currentValue.productType.name || ""}
                        onChange={(e) => {
                          setCurrentValue({
                            ...currentValue,
                            productType: {
                              id: null,
                              name: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  )}
                  <div className="row m-0 w-100">
                    <InputNumber
                      disabled={action === "view"}
                      label={strings.sum_acreage}
                      name="value"
                      onChange={(val) => {
                        setCurrentValue({
                          ...currentValue,
                          grossAreas: {
                            ...currentValue.grossAreas,
                            value: val,
                          },
                        });
                      }}
                      placeholder={strings.sum_acreage}
                      className="col-6"
                      value={
                        (currentValue &&
                          currentValue.grossAreas &&
                          currentValue.grossAreas.value) ||
                        null
                      }
                    />
                    <SelectField
                      disabled={action === "view"}
                      className="col-6"
                      onChange={(val) => {
                        setCurrentValue({
                          ...currentValue,
                          grossAreas: {
                            ...currentValue.grossAreas,
                            unitId: val,
                            unitName: unitsArea.find((f) => f.value === val)
                              .label,
                          },
                        });
                      }}
                      placeholder={strings.unit}
                      data={unitsArea}
                      label={strings.unit}
                      value={
                        (currentValue &&
                          currentValue.grossAreas &&
                          currentValue.grossAreas.unitId) ||
                        undefined
                      }
                    />
                  </div>
                </div>
                <div className="row col-md-6 col-sm-6 col-lg-6 col-12 mt-10">
                  <div className="row m-0 w-100">
                    <InputNumber
                      disabled={action === "view"}
                      label={strings.total_productivity}
                      name="value"
                      onChange={(val) => {
                        setCurrentValue({
                          ...currentValue,
                          grossProductivities: {
                            ...currentValue.grossProductivities,
                            value: val,
                          },
                        });
                      }}
                      placeholder={strings.total_productivity}
                      className="col-6"
                      value={
                        (currentValue &&
                          currentValue.grossProductivities &&
                          currentValue.grossProductivities.value) ||
                        undefined
                      }
                    />
                    <SelectField
                      disabled={action === "view"}
                      className="col-6"
                      onChange={(val) => {
                        setCurrentValue({
                          ...currentValue,
                          grossProductivities: {
                            ...currentValue.grossProductivities,
                            unitId: val,
                            unitName: unitsMass.find((f) => f.value === val)
                              .label,
                          },
                        });
                      }}
                      placeholder={strings.unit}
                      data={unitsMass}
                      label={strings.unit}
                      value={
                        (currentValue &&
                          currentValue.grossProductivities &&
                          currentValue.grossProductivities.unitId) ||
                        undefined
                      }
                    />
                  </div>
                  <InputNumber
                    disabled={action === "view"}
                    label={strings.number_seasons}
                    name="value"
                    onChange={(val) => {
                      setCurrentValue({
                        ...currentValue,
                        farmingSeasonNumber: val,
                      });
                    }}
                    placeholder={strings.number_seasons}
                    className="col-12"
                    value={
                      (currentValue && currentValue.farmingSeasonNumber) ||
                      undefined
                    }
                  />
                </div>
              </div>
              <div className="w-100 pr-20 pl-30 ">
                <UIButton
                  type="primary"
                  className="btn-pd-add "
                  disabled={!currentValue || !currentValue.productType.name}
                  onClick={() => {
                    if (currentValue.key || currentValue.key === 0) {
                      let pro = values.products;
                      pro[currentValue.key] = currentValue;
                      setCurrentValue(defaultValue);
                      handleChange("products")(pro);
                    } else {
                      handleChange("products")([
                        ...values.products,
                        currentValue,
                      ]);
                      setCurrentValue(defaultValue);
                    }
                  }}
                >
                  {strings.save}
                </UIButton>
              </div>
              <div className="w-100 pr-20 pl-30 pt-30">
                <Tabs defaultActiveKey="1" type="card" size={"middle"}>
                  <TabPane tab={strings.crops} key="1">
                    <TableEditable
                      changedData={(index, val) => {
                        handleChange("products")(
                          values.products.filter((f, i) => i !== index),
                        );
                      }}
                      editable={action !== "view"}
                      addRowAble={action !== "view"}
                      selectedKey={value.key}
                      onSelect={handleSelectRow}
                      selectable={true}
                      dataSource={values.products}
                      columns={columns}
                    />
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default connect()(Manufacturing);

import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { withRouter } from 'react-router-dom';

import { authActions } from '~/state/ducks/authUser';
import { RECOVERY_OTP_PATH } from '~/configs/routesConfig';

// const validationSchema = yup.object().shape({
// 	email: emailValidate,
// 	password: passwordValidate
// });

export default compose(
	withRouter,
	connect(
		null,
		{
			login: authActions.login,
			getUser: authActions.getProfile
		}
	),
	withFormik({
		displayName: 'loginForm',
		handleSubmit: async (values, { props }) => {
			const { history } = props;
			history.push(RECOVERY_OTP_PATH)
		}
	})
);

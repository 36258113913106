import strings from "~/localization";
import * as PATH from "~/configs/routesConfig";

const breadCrumbPath = {
  DASHBOARD: PATH.DASHBOARD_PATH,
  MASTER_DATA: PATH.MASTER_DATA_DASHBOARD,
  PRODUCTS: PATH.MASTER_DATA_PRODUCT_ALL,
  FARM: PATH.FARM_MANAGERMENT_LIST,
  FARM_MANAGERMENT: PATH.FARM_MANAGERMENT_DASHBOARD,
};

export const breadcrumb = (match) => {
  const links = match
    .replace("/", "")
    .split("/")
    .map((m) => {
      return m.split("-").join("_").toUpperCase();
    });

  let breacrumbArray = [];
  links.map((m) => {
    const value = {
      title: strings["MENU_" + m] || "",
      href: breadCrumbPath[m],
    };
    breacrumbArray = [...breacrumbArray, value];
  });
  return breacrumbArray;
};

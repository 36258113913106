import styled from "styled-components";
import { Table } from "antd";
import { Color } from "~/views/utilities/layout";

export const TableStyle = styled(Table)`
  thead {
    font-weight: bold;
    color: ${Color.gray};
    th {
      /* background-color: ${Color.white}; */
      color: ${Color.gray};
      font-weight: bold;
    }
    th.ant-table-column-sort {
      background-color: ${Color.white};
    }
  }
  tbody {
    background-color: ${Color.white};
    td.ant-table-column-sort {
      background-color: ${Color.white};
    }
    .ant-table-row-indent + .ant-table-row-expand-icon {
        margin-top: 0;
        margin-right: 12px;
    }
    .ant-table-row-level-1{     
      .ant-checkbox-wrapper{
        padding-left: 35px !important;
      }     
    }
    .indent-level-1{
      padding-left:0px !important;
    }
    .ant-table-row-level-0{
      .ant-table-cell-with-append{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;  
        min-height: 83px;           
         /* white-space: nowrap; */
         /*
        .ant-table-row-expand-icon{
        }
        img{
          margin-right:20px;
          display: inline-block;
        } */
      }
    }
    
  }
  .ant-pagination-item {
    border: none;
    border-radius: 2px;
    min-width: 24px;
    height: 24px;
    line-height: 24px;
    margin-right: 10px;
    &:hover {
      a {
        color: ${Color.red};
      }
    }
  }
  .ant-pagination {
    border-top: none !important;
  }
  .ant-pagination-item-active {
    background-color: ${Color.green};
    font-weight: bold;
    line-height:24px;
    a,
    &:hover a {
      color: ${Color.white};
    }
  }
  .ant-pagination-prev,
  .ant-pagination-next {
    &:hover {
      .ant-pagination-item-link {
        border-color:${Color.green};
        color: ${Color.green};
      }
    }
  }
  .ant-pagination-next.ant-pagination-disabled,.ant-pagination-prev.ant-pagination-disabled{
    &:hover{
      .ant-pagination-item-link{
        border-color: #d9d9d9;
        color: rgba(0, 0, 0, 0.25);
      }
    }
  .ant-pagination-item-container .ant-pagination-item-link-icon {
    color: ${Color.red};
  }
`;

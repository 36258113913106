import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import strings from "~/localization";
import { DataRang } from "~/views/presentation/ui/dashboard";
import {
  getFarmDashBoard,
  getSeasonDashBoard,
  getSeasonList,
  getSeasonChart,
  getFarmChart,
  getAcreageChart

} from "~/state/ducks/appApis/actions";
import Performance from "./Performance";
import LineChart from "./LineChart";
import { PageTitle } from "~/views/presentation/ui/commons";

export const FarmDashboard = (props) => {
  const { getFarmDashBoard, getSeasonDashBoard, getSeasonList,getSeasonChart,getFarmChart,getAcreageChart } = props;

  const [farmDashBoard, setFarmDashBoard] = useState({
    current: {
      totalFarm: 0,
      totalAcreage: 0,
    },
    previous: {
      totalFarm: 0,
      totalAcreage: 0,
    },
  });
  const [seasonDashBoard, setSeasonDashBoard] = useState({
    current: {
      totalFarmingSeason: 0,
    },
    previous: {
      totalFarmingSeason: 0,
    },
    totalFarmingSeason: 0,
  });

  const [seasonChart, setSeasonChart] = useState([]);
  const [culChart, setCulChart] = useState([]);
  const [farmChart, setFarmChart] = useState([]);

  const fetchData = (fromDate, toDate) => {
    getFarmDashBoard({ fromDate: fromDate, toDate: toDate || fromDate })
      .then(({ res }) => {
        setFarmDashBoard(res);
      })
      .catch((err) => console.log(err));

    getSeasonDashBoard({
      fromDate: fromDate,
      toDate: toDate || fromDate,
      filterStatus: "CREATED_DATE",
    })
      .then(({ res }) => {
        getSeasonList()
          .then(({ header }) => {
            setSeasonDashBoard({
              ...res,
              totalFarmingSeason: Number(header["x-total-count"]),
            });
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));

      getFarmChart({fromDate:fromDate,toDate:toDate}).then(({res})=>{
        setFarmChart(res.map((m)=>{
              return {
              ...m,
                date : new Date(m.date).getTime()
              }
            }))
        // setSeasonChart(res.map((m)=>{
        //   return {
        //     ...m,
        //     date : new Date(m.date).toLocaleDateString("vi-VN")
        //   }
        // }))
      }).catch(err=>console.log(err))

      getSeasonChart({fromDate:fromDate,toDate:toDate}).then(({res})=>{
        setSeasonChart(res.map((m)=>{
          return {
            ...m,
            date : new Date(m.date).getTime()
          }
        }))
      }).catch(err=>console.log(err))

      getAcreageChart({fromDate:fromDate,toDate:toDate}).then(({res})=>{
        setCulChart(res.map((m)=>{
              return {
                ...m,
                date : new Date(m.date).getTime()
              }
            }))
      }).catch(err=>console.log(err))
      
  };

  useEffect(() => {
    const fromDate = new Date(
      `${new Date(Date.now()).getFullYear()}-01-01T00:00:00.000Z`,
    ).toISOString();
    const toDate = new Date(Date.now()).toISOString();

    fetchData(fromDate, toDate);
  }, []);

  return (
    <div className="row pb-20">
      
        <div className="col-12 col-md-6 col-lg-6 mb-20">
          <PageTitle title={strings.MENU_DASHBOARD}/>
        </div>
        <div className="col-12 col-md-6 col-lg-6">
          <DataRang
            handleChange={(val) => {
              fetchData(val.firstValue.first, val.firstValue.second);
            }}
            label={strings.dashboard_data_range}
          />
          {/* <FilterForm /> */}
        </div>
   
      <div className="col-12">
        <Performance farmInfor={farmDashBoard} seasonInfor={seasonDashBoard} />
      </div>
      <div className="col-12 col-md-6 col-lg-6 mt-20">
        <LineChart
          data={farmChart}
          name={["statisticValue"]}
          title={strings.farm}
          yTitle={strings.count}
        />
      </div>
      <div className="col-12 col-md-6 col-lg-6 mt-20">
        <LineChart
          data={culChart}
          name={["statisticValue"]}
          title={strings.cultivated_area}
          margin={{ top: 15, right: 20, bottom: 15, left: 50 }}
          unit={"(Ha)"}
        />
      </div>
      <div className="col-12 col-md-6 col-lg-6 mt-10">
        <LineChart
          data={seasonChart}
          name={["statisticValue"]}
          yTitle={strings.count}
          title={strings.season}
        />
      </div>
    </div>
  );
};

export default connect(null, {
  getFarmDashBoard,
  getSeasonDashBoard,
  getSeasonList,
  getSeasonChart,
  getFarmChart,
  getAcreageChart
})(FarmDashboard);

import React, { useState } from "react";
import { connect } from "react-redux";
import {
  InputField,
  DatePickerField,
  SelectField,
} from "~/views/presentation/ui/fields";
import strings from "~/localization";
import { Tabs, Card, message } from "antd";
import LicenceUploader from "~/views/presentation/ui/upload/LicencesUpload";
import { TableEditable } from "~/views/presentation/ui/tables";
import { Formik } from "formik";
import StepHeader from "./StepHeader";
import { UIButton } from "~/views/presentation/ui/buttons";

const { TabPane } = Tabs;

function Certification(props) {
  const {
    certification,
    action,
    value,
    handleSetValue,
    items,
    setStep,
    handleUpdateFarm,
  } = props;

  const defaultValue = {
    issuedPlace: null,
    issuedBy: "",
    issuedDate: undefined,
    expirationDate: undefined,
    images: "",
    reassessmentDate: undefined,
    evaluationDate: undefined,
    typeId: null,
  };
  const [currentValue, setCurrentValue] = useState(defaultValue);
  const [nextStep, setNextStep] = useState(0);

  const handleSelectRow = (value) => {
    setCurrentValue(value);
  };

  const columns = [
    {
      title: strings.certificate_type,
      dataIndex: "typeId",
      key: "typeId",
      width: "15%",
      editable: false,
      render: (cell, row) => {
        const result = certification.find((f) => f.value === cell);
        return (
          <div onClick={() => handleSelectRow(row)}>
            {result ? result.label : "-"}
          </div>
        );
      },
    },
    {
      title: strings.issued_by,
      dataIndex: "issuedBy",
      key: "issuedBy",
      width: "15%",
      editable: false,
      placeholder: strings.certificate_type,
      type: "text",
    },
    {
      title: strings.date_of_issue,
      dataIndex: "issuedDate",
      key: "issuedDate",
      width: "15%",
      editable: false,
      placeholder: strings.date_of_issue,
      type: "date",
    },
    {
      title: strings.expiration_date,
      dataIndex: "expirationDate",
      key: "expirationDate",
      width: "15%",
      editable: false,
      placeholder: strings.certificate_type,
      type: "date",
    },
    {
      title: strings.date_evaluate,
      dataIndex: "evaluationDate",
      key: "evaluationDate",
      width: "15%",
      editable: false,
      placeholder: strings.date_evaluate,
      type: "date",
    },
    {
      title: strings.date_reevaluate,
      dataIndex: "reassessmentDate",
      key: "reassessmentDate",
      width: "15%",
      editable: false,
      placeholder: strings.date_reevaluate,
      type: "date",
    },
  ];

  return (
    <Formik
      initialValues={{ certifications: value }}
      onSubmit={(values) => {
        handleSetValue(values);
        setStep(nextStep);
      }}
    >
      {(props) => {
        const { handleSubmit, handleChange, values } = props;
        return (
          <form onSubmit={handleSubmit}>
            <StepHeader
              setStep={(val) => {
                handleSubmit(values);
                setNextStep(val);
              }}
              step={3}
              items={items}
              values={values}
              handleUpdateFarm={handleUpdateFarm}
            />
            <div className="row p-10">
              <div className="col-12 col-md-6 mb-3">
                <Card>
                  <SelectField
                    required
                    className="w-100"
                    data={certification}
                    disabled={action === "view"}
                    onChange={(value) => {
                      setCurrentValue({
                        ...currentValue,
                        typeId: value,
                      });
                    }}
                    iconEnd="caret-down"
                    placeholder={strings.certificate_type}
                    label={strings.certificate_type}
                    value={(currentValue && currentValue.typeId) || undefined}
                  />
                  <DatePickerField
                    required
                    disabled={action === "view"}
                    name="issuedDate"
                    value={
                      (currentValue && currentValue.issuedDate) || undefined
                    }
                    onChange={(val) => {
                      setCurrentValue({
                        ...currentValue,
                        issuedDate: val,
                      });
                    }}
                    label={strings.date_of_issue}
                    placeholder={strings.date_of_issue}
                  />
                  <DatePickerField
                    disabled={action === "view"}
                    name="expirationDate"
                    value={
                      (currentValue &&
                        currentValue.expirationDate &&
                        currentValue.expirationDate) ||
                      undefined
                    }
                    onChange={(val) => {
                      setCurrentValue({
                        ...currentValue,
                        expirationDate: val,
                      });
                    }}
                    label={strings.expiration_date}
                    placeholder={strings.expiration_date}
                  />
                  <DatePickerField
                    disabled={action === "view"}
                    name="evaluationDate"
                    value={
                      (currentValue && currentValue.evaluationDate) || undefined
                    }
                    onChange={(val) => {
                      setCurrentValue({
                        ...currentValue,
                        evaluationDate: val,
                      });
                    }}
                    label={strings.date_evaluate}
                    placeholder={strings.date_evaluate}
                  />
                  <DatePickerField
                    disabled={action === "view"}
                    name="reassessmentDate"
                    value={
                      (currentValue && currentValue.reassessmentDate) ||
                      undefined
                    }
                    onChange={(val) => {
                      setCurrentValue({
                        ...currentValue,
                        reassessmentDate: val,
                      });
                    }}
                    label={strings.date_reevaluate}
                    placeholder={strings.date_reevaluate}
                  />

                  <InputField
                    required
                    name="issuedPlace"
                    type="text"
                    disabled={action === "view"}
                    value={(currentValue && currentValue.issuedBy) || undefined}
                    onChange={(e) => {
                      setCurrentValue({
                        ...currentValue,
                        issuedBy: e.target.value,
                      });
                    }}
                    placeholder={strings.issued_by}
                    label={strings.issued_by}
                  />
                </Card>
              </div>

              <div className="col-12 col-md-6 mb-3">
                <Card>
                  <LicenceUploader
                    disabled={action === "view"}
                    maximumUpload={8}
                    images={(currentValue && currentValue.images) || undefined}
                    onChange={(val) => {
                      setCurrentValue({
                        ...currentValue,
                        images: val,
                      });
                    }}
                  />
                </Card>
              </div>
              <div className="w-100 pr-20 pl-30 ">
                <UIButton
                  type="primary"
                  className="btn-pd-add "
                  disabled={
                    !currentValue ||
                    !(
                      currentValue.typeId &&
                      currentValue.issuedDate &&
                      currentValue.issuedBy
                    )
                  }
                  onClick={() => {
                    if (currentValue.key || currentValue.key === 0) {
                      let cer = values.certifications;
                      cer[currentValue.key] = currentValue;
                      setCurrentValue(defaultValue);
                      handleChange("certifications")(cer);
                    } else {
                      handleChange("certifications")([
                        ...values.certifications,
                        currentValue,
                      ]);
                      setCurrentValue(defaultValue);
                    }
                  }}
                >
                  {strings.save}
                </UIButton>
              </div>
              <div className="col-12 mt-3">
                <Tabs defaultActiveKey="1" type="card" size={"middle"}>
                  <TabPane tab={strings.gcn_standard} key="1">
                    <TableEditable
                      changedData={(index) => {
                        handleChange("certifications")(
                          values.certifications.filter((f, i) => i !== index),
                        );
                      }}
                      editable={action !== "view"}
                      addRowAble={action !== "view"}
                      // selectedKey={values.key}
                      onSelect={handleSelectRow}
                      selectable={true}
                      dataSource={values.certifications}
                      columns={columns}
                    />
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
}

export default connect()(Certification);

import styled, { css } from "styled-components";
import { Button } from "antd";
import Color from "~/views/utilities/layout/color";

export default styled(Button)`
  border-radius: ${(props) => (props.border ? props.border + "px" : "4px")};
  min-width: ${(props) => (props.width ? props.width + "px" : "125px")};
  height: ${(props) => (props.height ? props.height + "px" : "35px")};
  // text-transform: uppercase;
  ${(props) => {
    let cBorder = "none",
      cBackgroundColor = "#5d9c1430",
      cColor = Color.white;
    if (!props.disabled) {
      switch (props.type) {
        case "primary":
          if (props.ghost) {
            cBorder = `solid 1px ${Color.green}`;
            cBackgroundColor = `${Color.white} !important`;
            cColor = Color.green;
          } else {
            cBackgroundColor = Color.green;
          }
          break;
        case "secondary":
          if (props.ghost) {
            cBorder = `solid 1px ${Color.yellowGreen}`;
            cBackgroundColor = `${Color.white} !important`;
            cColor = Color.yellowGreen;
          } else {
            cBackgroundColor = Color.yellowGreen;
          }
          break;
        case "danger":
          if (props.ghost) {
            cBorder = `solid 1px ${Color.red}`;
            cBackgroundColor = `${Color.white} !important`;
            cColor = Color.red;
          } else {
            cBackgroundColor = Color.red;
          }
          break;
        default:
          return null;
      }
    }
    return css`
      && {
        background-color: ${cBackgroundColor};
        color: ${cColor};
        border: ${cBorder};
        margin-left: 2px;
        margin-rigth: 2px;
        &:hover {
          opacity: 0.7;
          border: ${Color.cBorder};
          background-color: ${cBackgroundColor};
          color: ${cColor};
        }
      }
    `;
  }};
`;

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { PageTitle } from "~/views/presentation/ui/commons";
import strings from "~/localization";
import { ErrorMessage, Formik } from "formik";
import * as yup from "yup";
import StepHeader from "./StepHeader";
import { InputNumber, SelectField } from "~/views/presentation/ui/fields";
import {
  getFarmDetails,
  getMasterData,
  getAllCertificateOfLands,
  getUnits,
} from "~/state/ducks/appApis/actions";

function SeasonInfor(props) {
  const {
    value,
    match,
    handleSetValue,
    setStep,
    items,
    handleUpdate,
    product,
    unitsMass,
    unitsArea,
    certificationLand,
  } = props;
  const action = match.params.action;
  const validationSchema = yup.object().shape({});
  const [nextStep, setNextStep] = useState(0);

  return (
    <Formik
      initialValues={{ ...value }}
      validationSchema={action === "view" ? {} : validationSchema}
      onSubmit={(values) => {
        handleSetValue(values);
        setStep(nextStep);
      }}
    >
      {(props) => {
        const { handleSubmit, handleChange, values } = props;
        return (
          <form onSubmit={handleSubmit}>
            <StepHeader
              setStep={(val) => {
                handleSubmit(values);
                setNextStep(val);
              }}
              step={1}
              items={items}
              values={values}
              handleUpdateFarm={handleUpdate}
            />

            <div className="row col-12 p-20">
              <SelectField
                required
                disabled={action === "view"}
                className="col-12 col-md-6 col-lg-6"
                value={
                  values.productsOfFarm && values.productsOfFarm.farmProductId
                }
                onChange={(val) => {
                  const result = product.find((f) => f.value === val);
                  handleChange("productsOfFarm")({
                    name: result && result.label,
                    farmProductId: result && result.value,
                    masterDataTypeId: result && result.masterDataTypeId,
                  });
                }}
                placeholder={strings.crops}
                data={(product.length && product) || []}
                label={strings.crops}
              />
              <SelectField
                disabled={action === "view"}
                className="col-12 col-md-6 col-lg-6"
                value={
                  (values.certifycateOfLandIds &&
                    values.certifycateOfLandIds.length &&
                    values.certifycateOfLandIds[0]) ||
                  []
                }
                onChange={(val) => {
                  handleChange("certifycateOfLandIds")([val]);
                }}
                placeholder={strings.id_land}
                data={(certificationLand.length && certificationLand) || []}
                label={strings.id_land}
              />
              <div className="row col-12 col-md-6 col-lg-6 pr-0">
                <div className="col-6">
                  <InputNumber
                    required
                    disabled={action === "view"}
                    label={strings.sum_acreage}
                    name="value"
                    value={values && values.grossArea && values.grossArea.value}
                    onChange={(val) => {
                      handleChange("grossArea")({
                        ...values.grossArea,
                        value: val,
                      });
                    }}
                    placeholder={strings.sum_acreage}
                    className="w-100"
                  />
                  <div className="textErr">
                    <ErrorMessage name="grossArea.value" />
                  </div>
                </div>
                <div className="col-6 p-0">
                  <SelectField
                    required
                    disabled={action === "view"}
                    className="w-100"
                    value={
                      values && values.grossArea && values.grossArea.unitId
                    }
                    onChange={(val) => {
                      handleChange("grossArea")({
                        ...values.grossArea,
                        unitId: val,
                      });
                    }}
                    placeholder={strings.unit}
                    data={unitsArea}
                    label={strings.unit}
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="row col-12 col-md-6 col-lg-6 p-0">
                  <div className="col-6">
                    <InputNumber
                      required
                      disabled={action === "view"}
                      label={strings.sum_output}
                      name="value"
                      value={
                        values && values.grossYield && values.grossYield.value
                      }
                      onChange={(val) => {
                        handleChange("grossYield")({
                          ...values.grossYield,
                          value: val,
                        });
                      }}
                      placeholder={strings.sum_output}
                      className="w-100"
                    />
                    <div className="textErr">
                      <ErrorMessage name="grossYield.value" />
                    </div>
                  </div>
                  <div className="col-6 p-0">
                    <SelectField
                      required
                      disabled={action === "view"}
                      className="w-100"
                      value={
                        values && values.grossYield && values.grossYield.unitId
                      }
                      onChange={(val) => {
                        handleChange("grossYield")({
                          ...values.grossYield,
                          unitId: val,
                        });
                      }}
                      placeholder={strings.unit}
                      data={unitsMass}
                      label={strings.unit}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
}

export default compose(
  withRouter,
  connect(null, {
    getFarmDetails,
    getMasterData,
    getAllCertificateOfLands,
    getUnits,
  }),
)(SeasonInfor);

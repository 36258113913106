import React, { Fragment } from "react";
import { map } from "lodash";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import enhance from "~/views/presentation/ui/navigation/withMenuEnhancer";
import { Color } from "~/views/utilities/layout";
import strings from "~/localization";
import { LogoInTro } from "~/static/images";

function renderMenuItem(props) {
  const { keyOption, icon, name, path, subMenus } = props;
  if (subMenus) {
    return (
      <Menu.SubMenu
        children={map(subMenus, (item, keyOption) =>
          renderMenuItem({ ...item, keyOption: keyOption })
        )}
        key={keyOption}
        title={
          <span>
            {icon} <span>{name}</span>
          </span>
        }
      ></Menu.SubMenu>
    );
  }

  return (
    <Menu.Item key={keyOption}>
      <Link to={path}>
        {icon} <span>{name}</span>
      </Link>
    </Menu.Item>
  );
}

function Sidebar({
  menus,
  openKeys,
  defaultSelectedKeys,
  isCollapsed,
  onOpenChange,
  handleClick,
  theme,
  style,
}) {
  return (
    <div style={{ height: "100vh" }}>
      <div
        className="d-none d-lg-flex"
        style={{
          height: "45px",
          backgroundColor: Color.white,
          padding: "5px",
          // justifyContent: "center",
          // alignItems: "center",
          borderBottom: "1px solid #5D9C14",
        }}
      >
        <img src={LogoInTro} width={"80px"} />
      </div>
      <div
        style={{
          height: "100%",
          backgroundColor: Color.white,
          paddingBottom: "45px",
          overflowY: "auto",
        }}
      >
        <Menu
          style={style}
          mode="inline"
          theme={theme}
          defaultOpenKeys={openKeys}
          onOpenChange={onOpenChange}
          defaultSelectedKeys={defaultSelectedKeys}
          inlineCollapsed={isCollapsed}
          onClick={handleClick}
        >
          {map(menus, (item, keyOption) =>
            renderMenuItem({ ...item, keyOption })
          )}
        </Menu>
      </div>
    </div>
  );
}

export default enhance(Sidebar);

import React from "react";
import { connect } from "react-redux";
import SearchField from "./SearchField";
import SelectField from "./SelectField";
import RangePickerField from "./RangePickerField";
import strings from "~/localization";

export const InputFilter = (props) => {
  const { options = [], type, width = 140, onFiler = () => {}, value } = props;
  switch (type) {
    case "input":
      return (
        <div style={{ height: "35px" }} className="form-search-header w-100">
          <SearchField
            width={width}
            initialValue={value}
            placeholder={strings.search_placeholder}
            size="small"
            onChangeText={(val) => {
              onFiler(val);
            }}
          />
        </div>
      );
    case "select":
      return (
        <SelectField
          onChange={(val) => {
            onFiler(val);
          }}
          value={value}
          width={width}
          placeholder={strings.search}
          data={options || []}
          showSearch={true}
          filterOption={(input, option) => {
            return (
              option.children &&
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
          }}
        />
      );
    case "date":
      return (
        <RangePickerField
          width={width}
          onChange={([from, to]) => {
            onFiler([from, to]);
          }}
        />
      );

    default:
      return (
        <div style={{ height: "35px" }} className="form-search-header w-100">
          <SearchField
            width={width}
            initialValue={value}
            placeholder={strings.search_placeholder}
            size="small"
            onChangeText={(val) => {
              onFiler(val);
            }}
          />
        </div>
      );
  }
};

export default connect(null, {})(InputFilter);

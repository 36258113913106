import React from "react";
import { Navbar, Sidebar } from "../navigation";
import _ from "lodash";
import { MenuSize } from "../navigation/Navbar";
import "jquery-slimscroll/jquery.slimscroll.min";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import strings from "~/localization";
import MDNav from "../navigation/MDNav";
import * as PATH from "~/configs/routesConfig";
import { breadcrumb } from "~/configs/breadcrumb";
import { Color } from "~/views/utilities/layout";
import menus from "../navigation/sidebarMenu";

const menuHeader = [
  {
    title: strings.NAVBAR_MENU_MASTER_DATA,
    path: PATH.MASTER_DATA_CATEGORY,
  },
];

class AppContentWrapper extends React.PureComponent {
  state = {
    isCollapsed: false,
    isToggled: false,
    menuSize: MenuSize.small,
    pageWidth: 0,
  };

  componentDidMount() {
    this.setState({
      pageWidth: window.innerWidth,
      menuSize:
        window.innerWidth < 600
          ? MenuSize.small
          : window.innerWidth < 960
          ? MenuSize.medium
          : MenuSize.large,
      isCollapsed: window.innerWidth > 960,
    });

    window.addEventListener(
      "resize",
      () => {
        if (window.innerWidth < 600) {
          this.setState({ menuSize: MenuSize.small });
          this.setState({ isCollapsed: false });
        } else if (window.innerWidth < 960) {
          this.setState({ isCollapsed: false });
          this.setState({ menuSize: MenuSize.medium });
        } else {
          this.setState({ menuSize: MenuSize.large });
          this.setState({ isCollapsed: true });
        }
        this.setState({ pageWidth: window.innerHeight });
      },
      false
    );
  }

  getMenuSize = (size) => {
    switch (size) {
      case MenuSize.small: {
        return "appWrapper scheme-default default-scheme-color header-fixed aside-fixed rightbar-hidden device-lg sidebar-xs";
      }
      // case MenuSize.medium: {
      //   return "appWrapper scheme-default default-scheme-color header-fixed aside-fixed rightbar-hidden device-lg sidebar-xs";
      // }
      default:
        return "appWrapper";
    }
  };

  setBearCum = (name) => {
    this.setState({ bearCum: name });
  };

  getBreadcrumb = () => {
    const { match } = this.props;
    return breadcrumb(window.location.pathname);
  };

  render() {
    const { children, showMainSideBar = true, topNav } = this.props;

    return (
      <div id="minovate" className={this.getMenuSize(this.state.menuSize)}>
        <div
          id="wrap"
          className="animsition"
          style={{ animationDuration: "1.5s", opacity: 1 }}
        >
          {/* ================================================
                ================= SIDEBAR Content ===================
                ================================================= */}
          {this.state.isCollapsed === true && (
            <div id="controls">
              <aside
                id="sidebar"
                className="scheme-default aside-fixed dropdown-open sidebar-main"
              >
                <div style={{ height: "100%", backgroundColor: Color.white }}>
                  <Sidebar
                    showMainSideBar={this.state.isCollapsed}
                    menus={menus}
                  />
                </div>
              </aside>
            </div>
          )}
          {/* ====================================================
            ================= CONTENT ===============================
            ===================================================== */}

          <section
            id={
              this.state.isCollapsed === true ? "content" : "non-left-content"
            }
          >
            <section
              id="header"
              style={{
                position: "fixed",
                top: 0,
                width: "100%",
              }}
              className="scheme-default"
            >
              <Navbar
                topNav={{
                  isMenu: true,
                  pageName:
                    this.state.menuSize === MenuSize.small &&
                    strings["MASTER_DATA_ADMIN_TITLE"],
                  // slot: menuHeader,
                }}
                size={this.state.menuSize}
                onResize={(size) => this.setState({ menuSize: size })}
                isCollapsed={this.state.isCollapsed}
                setIsCollapsed={(value) =>
                  this.setState({ isCollapsed: value })
                }
              />
              <MDNav
                changeViewStyle={this.changeViewStyle}
                // className="profile-nav"
                breadcrumb={this.getBreadcrumb()}
              />
            </section>
            <div
              style={{
                marginTop: this.state.menuSize === MenuSize.large ? 100 : 140,
                paddingLeft: 20,
                paddingRight: 20,
              }}
            >
              {children}
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default compose(withRouter)(AppContentWrapper);

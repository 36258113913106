import React, { PureComponent } from "react";
import { TableStyle } from "./styles";
import strings from "~/localization";
import { MenuSize } from "../navigation/Navbar";
import { SearchOutlined } from "@ant-design/icons";

class UITable extends PureComponent {
  state = {
    menuSize: null,
    selectedRowKeys: this.props.selectedRowKeys || [], // take selectedRowKeys props from outside
    columns:
      (this.props.columns && this.props.columns.length && this.props.columns) ||
      [],
  };
  componentDidMount() {
    this.setState({
      menuSize:
        window.innerWidth < 600
          ? MenuSize.small
          : window.innerWidth < 960
          ? MenuSize.medium
          : MenuSize.large,
      // columns: this.props.columns.map((m) => {
      //   if (!m.filterDropdown) delete m.filterDropdown;
      //   return {
      //     ...m,
      //     filterIcon: (filtered) => (
      //       <SearchOutlined
      //         style={{ color: filtered ? "#1677ff" : undefined }}
      //       />
      //     ),
      //   };
      // }),
    });

    window.addEventListener(
      "resize",
      () => {
        if (window.innerWidth < 600) {
          this.setState({ menuSize: MenuSize.small });
          this.setState({ isCollapsed: false });
        } else if (window.innerWidth < 960) {
          this.setState({ isCollapsed: false });
          this.setState({ menuSize: MenuSize.medium });
        } else {
          this.setState({ menuSize: MenuSize.large });
          this.setState({ isCollapsed: true });
        }
        this.setState({ pageWidth: window.innerHeight });
      },
      false,
    );
  }
  componentDidUpdate(prevState, prevProps) {
    //change size (response)
    if (prevProps.menuSize !== this.state.menuSize) {
      this.setState({
        columns: this.state.columns.map((m) => {
          let colunm = m;
          //fixed colunm Id
          if (["id", "ID", "Id"].includes(colunm.dataIndex)) {
            colunm.fixed =
              this.state.menuSize === MenuSize.small ? false : "left";
          }
          //fixed action
          if (colunm.title === strings.ACTION) {
            colunm.fixed =
              this.state.menuSize === MenuSize.small ? false : "right";
          }
          return colunm;
        }),
      });
    }
  }

  onSelectChange = (selectedRowKeys) => {
    // if props is controlled from outside, don't setState
    if (!this.props.selectedRowKeys) {
      this.setState({ selectedRowKeys });
    }
    this.props.handleRowSelect(selectedRowKeys);
  };

  onTableChange = (tableObj, filters, sorter) => {
    // return row state to outside
    if (this.props.rowSelectable) {
      if (!this.props.selectedRowKeys) {
        this.props.handleRowSelect([]);
      } else {
        this.setState({ selectedRowKeys: [] });
      }
    }
    const baseObj = {
      ...tableObj,
      current: tableObj.current,
    };

    // this.props.changePage(tableObj.current);
  };

  render() {
    const {
      dataSource,
      // columns,
      pagination,
      loading,
      rowSelectable,
      footer,
      rowKey,
      width = 1000,
      ...rest
    } = this.props;

    // to control selected row state from outside
    const selectedRowKeys = this.props.selectedRowKeys
      ? this.props.selectedRowKeys
      : this.state.selectedRowKeys;

    const rowSelection = rowSelectable
      ? {
          selectedRowKeys,
          onChange: this.onSelectChange,
        }
      : null;

    const tablePaging = pagination
      ? {
          ...pagination,
          current: pagination.current,
        }
      : false;

    return (
      <TableStyle
        {...rest}
        scroll={{ x: width }}
        // rowKey={(record) => record[rowKey]}
        dataSource={dataSource}
        bordered
        pagination={tablePaging}
        loading={loading}
        onChange={this.onTableChange}
        locale={{ emptyText: strings.emptyText }}
        rowSelection={rowSelection}
        footer={footer}
        columns={this.props.columns}
      />
    );
  }
}

UITable.defaultProps = {
  rowSelectable: false,
  rowKey: "_id",
};

export default UITable;

import React from "react";
import UITable from "~/views/presentation/ui/tables/Table";
import { UIButton } from "~/views/presentation/ui/buttons";
import { Form, Col, Row, message, Popconfirm } from "antd";
import { compose, lifecycle, withHandlers, withState } from "recompose";
import { withFormik } from "formik";
import { connect } from "react-redux";
import strings from "~/localization";
import * as yup from "yup";
import _ from "lodash";
import { stringRequiredField } from "~/views/utilities/validation/input";
import { getString, getArray } from "~/views/utilities/helpers/utilObject";
import { UIPagination } from "~/views/presentation/ui/commons";
import { InputField, SearchField } from "~/views/presentation/ui/fields";
import {
  getMasterData,
  deleteMasterData,
  createOrUpdateMasterData,
} from "~/state/ducks/appApis/actions";
import { showError } from "~/configs/ServerErrors";
import { withRouter } from "react-router-dom";

const validationSchema = yup.object().shape({
  name: stringRequiredField(),
});

const generateAutoKey = (dataSource) => {
  return getArray(dataSource, undefined, []).map((item, index) => {
    let newItem = {
      ...item,
      key: item.id,
    };
    return newItem;
  });
};

class ViewPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      masterDataKey: {
        KeyString: props.KeyString || "",
        KeyGetApi: props.KeyGetApi || "",
        KeyActionApi: props.KeyActionApi || "",
      },
    };
  }

  /**
   * Handle change value of form
   */

  handleChangeSelectType = (type) => {
    const { setFieldValue, fetchParentCategories } = this.props;
    setFieldValue("type", type);
    fetchParentCategories(type);
    setFieldValue("parentId", undefined);
  };

  /**
   * Handle button action  on table
   */

  handleUpdateCertification = (data) => {
    const { setValues, setIsEdit } = this.props;

    if (this.state.selectedRowKeys[0] === data.id) {
      this.handleCancelButton();
    } else {
      this.setState({ selectedRowKeys: [data.id] });
      setIsEdit(true);
      const { id, name } = data;
      setValues({ id, name });
    }
  };

  handleDeleteCertification = (id) => {
    const { deleteMasterData, setRefreshPage, isEdit } = this.props;
    deleteMasterData(id)
      .then((res) => {
        message.success(
          strings[this.state.masterDataKey.KeyString + "_DELETE_SUCCESS"]
        );
        setRefreshPage(true);
        if (isEdit) {
          this.handleCancelButton();
        }
      })
      .catch((err) => {
        // showError(err);
      });
  };

  handleCancelButton = () => {
    const { setIsEdit, resetForm } = this.props;
    setIsEdit(false);
    resetForm();
    this.setState({ selectedRowKeys: [] });
  };

  /**
   * Handle select row on table
   */

  selectRow = (record) => {
    const selectedRowKeys = [...this.state.selectedRowKeys];
    if (selectedRowKeys.indexOf(record.key) >= 0) {
      selectedRowKeys.splice(selectedRowKeys.indexOf(record.key), 1);
    } else {
      selectedRowKeys.push(record.key);
    }
    this.setState({ selectedRowKeys });
  };

  onSelectedRowKeysChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  /**
   *  Handle filter table
   */
  changeTextSearch = (value) => {
    const { setFilters, filters, pagination, setPagination, fetchMasterData } =
      this.props;
    setFilters((prev) => ({ ...prev, keyword: value }));
    setPagination((prev) => ({ ...prev, current: 1 }));
    fetchMasterData(pagination.pageSize, 1, {
      type: filters.type,
      name: value,
    });
  };

  // change curent page
  changePage = (page) => {
    const { pagination, setPagination, filters, fetchMasterData } = this.props;
    setPagination((prev) => ({ ...prev, current: page }));
    fetchMasterData(pagination.pageSize, page, filters);
  };

  render() {
    const {
      handleSubmit,
      values,
      handleChange,
      handleBlur,
      setFieldValue,
      touched,
      errors,
      isSubmitting,
      dataSource,
      pagination,
      filters,
      isEdit,
    } = this.props;

    const columns = [
      {
        title:
          strings[
            this.state.masterDataKey.KeyString +
              "_PAGE_TABLE_COLUMN_TITLE_CERTIFICATION_NAME"
          ],
        dataIndex: "name",
        render: (cellData, row, index) => {
          return <>{cellData}</>;
        },
      },
      {
        title: strings.ACTION,
        dataIndex: "",
        width: "25%",
        render: (cellData, row) => {
          return (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <UIButton
                type="primary"
                width="20"
                onClick={() => this.handleUpdateCertification(row)}
              >
                {strings.VIEW}
              </UIButton>

              <Popconfirm
                title={
                  strings[
                    this.state.masterDataKey.KeyString + "_DELETE_CONFIRM"
                  ]
                }
                cancelText={strings.CANCELED}
                onConfirm={() => this.handleDeleteCertification(row.id)}
              >
                <UIButton width="20" type="danger">
                  {strings.DELETE}
                </UIButton>
              </Popconfirm>
            </div>
          );
        },
      },
    ];

    return (
      <>
        {/* to do dev after api support filter name */}
        <div className="row">
          {/* start left column*/}
          <div className="col-lg-5">
            <div className="content-head mt-2">
              <p>
                {
                  strings[
                    this.state.masterDataKey.KeyString +
                      "_PAGE_FIRST_DESCRIPTION"
                  ]
                }
              </p>
            </div>
            <div className="form-category w-100">
              <h4 className="mt-5 mb-5">
                {isEdit
                  ? strings[
                      this.state.masterDataKey.KeyString +
                        "_PAGE_TITLE_FORM_UPDATE"
                    ]
                  : strings[
                      this.state.masterDataKey.KeyString +
                        "_PAGE_TITLE_FORM_ADD"
                    ]}
              </h4>
              <Form onFinish={handleSubmit}>
                <div className="row w-100">
                    <div className="col-12 w-100">
                      <Form.Item>
                        <InputField
                          name="name"
                          required
                          placeholder={
                            strings[
                              this.state.masterDataKey.KeyString +
                                "_PAGE_FORM_LABEL_NAME"
                            ]
                          }
                          value={values.name}
                          validatestatus={
                            touched.name && errors.name ? "error" : undefined
                          }
                          help={touched.name && errors.name ? errors.name : ""}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Form.Item>
                    </div>
                    <div className="col-12">
                      <p>
                        {
                          strings[
                            this.state.masterDataKey.KeyString +
                              "_PAGE_FORM_SHORT_DESCRIPTION_NAME"
                          ]
                        }
                      </p>
                    </div>
                <div className="col-12">
                  <Form.Item>
                    <UIButton
                      type="primary"
                      htmlType="submit"
                      loading={isSubmitting}
                      onClick={() => {
                        window.scroll({
                          top: 65,
                          left: 0,
                          behavior: "smooth",
                        });
                      }}
                    >
                      {isEdit
                        ? strings["UPDATE"]
                        : strings[
                            this.state.masterDataKey.KeyString +
                              "_PAGE_FORM_BUTTON_CREATE"
                          ]}
                    </UIButton>

                    {isEdit && (
                      <UIButton
                        type="secondary"
                        htmlType="button"
                        loading={isSubmitting}
                        className="ml-3"
                        onClick={this.handleCancelButton}
                      >
                        {strings.CANCELED}
                      </UIButton>
                    )}
                  </Form.Item>
                </div>
                </div>
              </Form>
            </div>
          </div>
          {/* end left column*/}

          {/* start right column*/}
          <div className="col-lg-7 table-view">
            <div className="d-flex justify-content-end w-100">
              <div className="form-search-header w-100">
                <SearchField
                  initialValue={filters.keyword}
                  placeholder={
                    strings[
                      this.state.masterDataKey.KeyString +
                        "_PAGE_HEADER_PLACEHOLDER_SEARCH"
                    ]
                  }
                  size="small"
                  onChangeText={this.changeTextSearch}
                />
              </div>
            </div>
            <UITable
              dataSource={getArray(dataSource)}
              width={300}
              rowClassName="cursor-pointer"
              columns={columns}
              loading={this.props.isLoading}
              // rowSelection={rowSelection}
              changePage={this.changePage}
            />
            <div className="form-action-header d-flex justify-content-end mt-10">
              <div className="pagination-right d-flex justify-content-between align-items-center mb-3 ml-3">
                <div className="amount-category mr-4">
                  <p>
                    {pagination.total &&
                      `${pagination.pageSize} / ${pagination.total}`}
                  </p>
                </div>
                <UIPagination
                  defaultCurrent={pagination.current}
                  total={pagination.total}
                  pageSize={pagination.pageSize}
                  onChange={(page) => this.changePage(page)}
                />
              </div>
            </div>
            <div className="note-footer short-desciption">
              <p>
                {
                  strings[
                    this.state.masterDataKey.KeyString + "_FORM_FOOTER_NOTE"
                  ]
                }
              </p>
            </div>
          </div>
          {/* end right column*/}
        </div>
      </>
    );
  }
}

export default compose(
  withRouter,
  connect(null, {
    createOrUpdateMasterData,
    getMasterData,
    deleteMasterData,
  }),
  withState("dataSource", "setDataSource", []),
  withState("pagination", "setPagination", {
    total: 0,
    current: 1,
    pageSize: 5,
    hideOnSinglePage: false,
  }),
  withState("filters", "setFilters", { keyword: "", type: undefined }),
  withState("isEdit", "setIsEdit", false),
  withState("refreshPage", "setRefreshPage", false),
  withState("isLoading", "setIsLoading", false),
  withHandlers({
    fetchMasterData: (props) => (pageSize, current, params) => {
      const { getMasterData, setPagination, setDataSource, setIsLoading } =
        props;
      setIsLoading(true);
      getMasterData({ size: pageSize, page: current - 1, ...params })
        .then(({ header, res }) => {
          setIsLoading(false);
          const total = getString(header, "x-total-count");
          setDataSource(generateAutoKey(res));
          setPagination((prev) => ({ ...prev, total: total }));
        })
        .catch((err) => {
          setIsLoading(false);
          showError(err);
        });
    },
    resetForm: (props) => () => {
      const { setIsEdit } = props;
      setIsEdit(false);
    },
  }),
  withFormik({
    displayName: "add",
    mapPropsToValues: () => ({
      name: "",
    }),
    validationSchema,
    handleSubmit: (values, { props, setSubmitting, resetForm }) => {
      const {
        createOrUpdateMasterData,
        isEdit,
        KeyString,
        KeyActionApi,
        setRefreshPage,
        setIsEdit,
      } = props;
      createOrUpdateMasterData(values, KeyActionApi)
        .then((result) => {
          message.success(
            isEdit
              ? strings[KeyString + "_UPDATE_SUCCESS"]
              : strings[KeyString + "_CREATE_SUCCESS"]
          );
          setSubmitting(false);
          resetForm();
          setIsEdit();
          setRefreshPage(true);
        })
        .catch((err) => {
          setSubmitting(false);
        });
    },
  }),
  lifecycle({
    componentDidMount() {
      const { pagination, fetchMasterData, KeyGetApi, setFilters, values } =
        this.props;

      setFilters({ keyword: "", type: KeyGetApi });
      fetchMasterData(pagination.pageSize, pagination.current, {
        type: KeyGetApi,
      });
    },
    componentDidUpdate(prevState, prevProps) {
      const {
        refreshPage,
        setRefreshPage,
        fetchMasterData,
        pagination,
        KeyGetApi,
      } = this.props;
      if (refreshPage) {
        setRefreshPage(false);
        fetchMasterData(pagination.pageSize, pagination.current, {
          type: KeyGetApi,
        });
      }
    },
  })
)(ViewPage);

import React, { useEffect, useState } from "react";
import strings from "~/localization";
import { ContentWrapper } from "../../AuthPage/styles";
import SeasonInfor from "./SeasonInfor";
import * as PATH from "~/configs/routesConfig";
import {
  getSeasonDetails,
  getFarmDetails,
  getMasterData,
  getAllCertificateOfLands,
  getUnits,
  getCategoryOptions,
  getCategorys,
  getManufacturer,
  updateSeasonDetails,
} from "~/state/ducks/appApis/actions";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { Spin, message } from "antd";
import Crops from "./Crops";
import Material from "./Material";

function SeasonDetails(props) {
  const {
    match,
    getSeasonDetails,
    getFarmDetails,
    getMasterData,
    getAllCertificateOfLands,
    getUnits,
    getCategoryOptions,
    getCategorys,
    getManufacturer,
    updateSeasonDetails,
    history,
  } = props;
  const [step, setStep] = useState(0);
  const [seasonDetails, setseasonDetails] = useState();
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState([]);
  const [unitsMass, setUnitsMass] = useState([]);
  const [unitsArea, setUnitsArea] = useState([]);
  const [certificationLand, setCertificationLand] = useState([]);
  const [category, setCategory] = useState([]);
  const [manufacturer, setManufacturer] = useState([]);
  const [farm, setFarm] = useState();

  useEffect(() => {
    setLoading(true);
    getSeasonDetails(match.params.id)
      .then((res) => {
        setseasonDetails(res.res);
        const farmId = res.res.farmId;

        getFarmDetails(farmId)
          .then((res) => {
            setFarm(res.res);
          })
          .catch((err) => console.log(err));
        getFarmDetails(farmId)
          .then((res) => {
            setProduct(
              res.res.products.map((m) => {
                return {
                  value: m.id,
                  label: m.productType.name,
                  masterDataTypeId: m.productType.id,
                };
              }),
            );
          })
          .catch((err) => console.log(err));

        getAllCertificateOfLands(farmId)
          .then((res) => {
            setCertificationLand(
              res.res.map((m) => {
                return {
                  value: m.id,
                  label: m.landLotNo,
                };
              }),
            );
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
    getUnits("MASS")
      .then((res) => {
        setUnitsMass(
          res.res.map((m) => {
            return {
              label: m.shortName,
              value: m.id,
            };
          }),
        );
      })
      .catch((err) => console.log(err));
    getUnits("ACREAGE")
      .then((res) => {
        setUnitsArea(
          res.res.map((m) => {
            return {
              label: m.shortName,
              value: m.id,
            };
          }),
        );
      })
      .catch((err) => console.log(err));

    getCategorys().then((res) => {
      setCategory(
        res.res.map((m) => {
          return {
            value: m.id,
            label: m.name,
          };
        }),
      );
    });
    getManufacturer().then((res) => {
      setManufacturer(
        res.res.map((m) => {
          return {
            value: m.id,
            label: m.profile.name,
          };
        }),
      );
    });
  }, []);

  //list item
  const items = [
    {
      id: 0,
      title: strings.season_infor,
    },
    {
      id: 1,
      title: strings.crops,
    },
    {
      id: 2,
      title: strings.season_material,
    },
  ];

  const handleSetvalue = (value) => {
    setseasonDetails({ ...seasonDetails, ...value });
  };

  const handleUpdateSeason = (value) => {
    const newSeason = { ...seasonDetails, ...value };
    updateSeasonDetails(newSeason)
      .then((res) => {
        history.push(PATH.SEASON_MANAGERMENT_LIST);
        message.success(strings.season_update_success);
      })
      .catch((err) => {
        message.success(strings.season_update_error);
        console.log(err);
      });
  };

  const renderContent = () => {
    switch (step) {
      case 0:
        return (
          <SeasonInfor
            items={items}
            setStep={setStep}
            value={seasonDetails}
            farm={farm}
            handleSetValue={handleSetvalue}
            handleUpdate={handleUpdateSeason}
          />
        );
      case 1:
        return (
          <Crops
            items={items}
            setStep={setStep}
            value={seasonDetails}
            handleSetValue={handleSetvalue}
            handleUpdate={handleUpdateSeason}
            product={product}
            unitsMass={unitsMass}
            unitsArea={unitsArea}
            certificationLand={certificationLand}
          />
        );
      case 2:
        return (
          <Material
            items={items}
            setStep={setStep}
            value={seasonDetails.materials}
            handleSetValue={handleSetvalue}
            handleUpdate={handleUpdateSeason}
            unitsMass={unitsMass}
            category={category}
            manufacturer={manufacturer}
          />
        );

      default:
        break;
    }
  };
  return loading ? (
    <div
      style={{ height: "500px" }}
      className="w-100 d-flex justify-content-center align-items-center"
    >
      <Spin tip="Loading" size="large" />
    </div>
  ) : (
    <ContentWrapper>
      <div
        style={{
          minHeight: "80vh",
          borderRadius: "3px",
          position: "relative",
        }}
        className="w-100 bg-white"
      >
        {renderContent()}
      </div>
    </ContentWrapper>
  );
}
export default compose(
  withRouter,
  connect(null, {
    getSeasonDetails,
    getFarmDetails,
    getMasterData,
    getAllCertificateOfLands,
    getUnits,
    getCategoryOptions,
    getCategorys,
    getManufacturer,
    updateSeasonDetails,
  }),
)(SeasonDetails);

import React from "react";
import { Wrapper } from "./styles";
import { LoginForm, OTPForm } from "~/views/presentation/AuthForm";
import styled from "styled-components";
import {
  IntroduceForm,
  ChangePassForm,
  RegisterForm,
  RegisterFinishForm,
} from "../../presentation/AuthForm";
import RecoveryInputInfo from "../../presentation/AuthForm/RecoveryInputInfo";
import { withRouter } from "react-router-dom";
import {
  LOGIN_PATH,
  ACCOUNT_RECOVERY_PATH,
  RECOVERY_OTP_PATH,
  RECOVERY_CHANGE_PASSWORD_PATH,
  APP_DEFAULT_PATH,
  MASTER_DATA_DASHBOARD,
} from "~/configs/routesConfig";
import Animate from "react-smooth";
import { compose } from "recompose";
import { connect } from "react-redux";

const ContentWrapper = styled.div`
  flex: 1;
  background-color: #fff;
`;
const LeftContent = styled.div`
  width: 50%;
  @media (max-width: 800px) {
    width: 100%;
  }
  padding: 0px !important;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  display: flex;
`;
const RightContent = styled.div`
  width: 50%;
  @media (max-width: 800px) {
    display: none;
    background-color: rgb(7, 72, 128);
    justify-content: center;
    align-items: center;
    min-height: 100vh;
  }
`;

class Login extends React.PureComponent {
  renderRightContent = () => {
    switch (this.props.match.path) {
      case LOGIN_PATH: {
        return <LoginForm />;
      }
      case ACCOUNT_RECOVERY_PATH: {
        return <RecoveryInputInfo />;
      }
      case RECOVERY_OTP_PATH: {
        return <OTPForm />;
      }
      case RECOVERY_CHANGE_PASSWORD_PATH: {
        return <ChangePassForm />;
      }
    }
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    const { user, history } = this.props;
    if (user) {
      history.push(MASTER_DATA_DASHBOARD);
    }
  }
  render() {
    const steps = [
      {
        style: {
          opacity: 0,
        },
        duration: 200,
      },
      {
        style: {
          opacity: 1,
        },
        duration: 200,
      },
      {
        style: {
          opacity: 0.9,
        },
        duration: 100,
      },
    ];

    return (
      <Wrapper>
        <ContentWrapper className="row mx-0">
          <LeftContent>
            <Animate steps={steps}>
              <div style={{ height: "100%" }} className="w-100">
                {this.renderRightContent()}
              </div>
            </Animate>
          </LeftContent>
          <RightContent>
            <IntroduceForm />
          </RightContent>
        </ContentWrapper>
      </Wrapper>
    );
  }
}

export default compose(
  connect(
    (state) => ({
      user: state["authUser"].user,
    }),
    {}
  ),
  withRouter
)(Login);

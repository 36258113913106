import React from "react";
import { compose } from "recompose";
import { PageTitle } from "~/views/presentation/ui/commons";
import strings from "~/localization";
import { ContentWrapper } from "~/views/presentation/ui/container";
import ViewPage from "../components/ViewPage";

class BusinessCertificateOfLand extends React.PureComponent {
  render() {
    return (
      <ContentWrapper>
        <PageTitle
          title={strings.BUSINESS_CER_LAND_PAGE_TITLE}
          breadcrumb={false}
        />
        <ViewPage
          KeyString="BUSINESS_CER_LAND"
          KeyGetApi="CERTIFICATION_LAND"
          KeyActionApi="certification-land"
        />
      </ContentWrapper>
    );
  }
}

export default BusinessCertificateOfLand;

import React from "react";
import styled from "styled-components";
import strings from "~/localization";
import { Steps } from "antd";
import * as PATH from "~/configs/routesConfig";
import { withRouter } from "react-router-dom";
import { UIButton } from "~/views/presentation/ui/buttons";
import { compose } from "redux";

const { Step } = Steps;

const ContentStyle = styled.div`
  min-height: 70px;
  margin-left: 30px;
  margin-right: 30px;
  align-items: center;
  padding-bottom: 10px;
  padding-top: 10px;
`;

function StepHeader(props) {
  const {
    setStep,
    step,
    items = [],
    history,
    match,
    handleUpdateFarm,
    values,
  } = props;
  return (
    <ContentStyle>
      <div
        className="d-flex align-items-center"
        style={{ paddingTop: "20px", paddingBottom: "20px" }}
      >
        {match.params.action === "view" ? (
          <>
            <UIButton
              type=""
              className="btn-pd-add"
              onClick={() => {
                history.push(PATH.SEASON_MANAGERMENT_LIST);
              }}
            >
              {strings.back}
            </UIButton>
            <UIButton
              type="secondary"
              className="btn-pd-add"
              onClick={() => {
                history.push(match.url.replace("view", "edit"));
              }}
            >
              {strings.MENU_EDIT}
            </UIButton>
          </>
        ) : (
          <>
            <UIButton
              type="danger"
              className="btn-pd-add"
              onClick={() => {
                history.push(match.url.replace("edit", "view"));
              }}
            >
              {strings.cancel}
            </UIButton>
            <UIButton
              type="primary"
              className="btn-pd-add"
              onClick={() => {
                handleUpdateFarm(values);
              }}
            >
              {strings.save}
            </UIButton>
          </>
        )}
      </div>
      <Steps current={step}>
        {items.map((m) => {
          return <Step title={m.title} onClick={() => setStep(m.id)} />;
        })}
      </Steps>
      <div
        style={{
          height: "1px",
          backgroundColor: "#f0f0f0",
          margin: "10px",
        }}
      ></div>
    </ContentStyle>
  );
}

export default compose(withRouter)(StepHeader);

const COLOR = {
  white: "#fff",
  black: "#000",
  red: "#d0011b",
  gray: "#6c757d", // text color
  blue: "#189eff",
  lightgray: "#dddddd",
  yellow: "#f6821f",
  graytheme: "#374d63", //theme color
  grayhover: "#678db2",
  gray_01: "#9b9b9b",
  gray_02: "#F6F6F6",
  green: "#428079",
  green_02: "#043A34",
  greenhover: "#8db95b",
  yellowGreen: "#E8C01E",
  brow: "#44413a",
  browLight: "#44413ab8",
};
export default COLOR;

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  InputField,
  InputNumber,
  SelectField,
} from "~/views/presentation/ui/fields";
import strings from "~/localization";
import { TableEditable, UITable } from "~/views/presentation/ui/tables";
import { Tabs, message } from "antd";
import { Formik } from "formik";
import * as yup from "yup";
import StepHeader from "./StepHeader";
import { UIButton } from "~/views/presentation/ui/buttons";
const { TabPane } = Tabs;

export const Markets = (props) => {
  const {
    value,
    action,
    markets,
    handleSetValue,
    items,
    setStep,
    handleUpdateFarm,
  } = props;

  const [marketId, setMarketId] = useState();
  const [currentValue, setCurrentValue] = useState();
  const [nextStep, setNextStep] = useState(0);

  const validationSchema = yup.object().shape({
    // products: yup.array().min(1, strings.min_crops_1),
  });

  const handleSelectRow = (value) => {
    setCurrentValue(value);
    setMarketId(value.id);
  };

  const columns = [
    {
      title: strings.market_type,
      dataIndex: "id",
      key: "id",
      width: "30%",
      editable: false,
      align: "center",
      render: (cell, row) => {
        return (
          <div onClick={() => handleSelectRow(row)}>
            {cell ? strings.master_data : strings.consumption_market_other}
          </div>
        );
      },
    },
    {
      title: strings.market_name,
      dataIndex: "name",
      key: "name",
      width: "70%",
      align: "center",
      editable: false,
    },
  ];

  return (
    <Formik
      initialValues={{ consumptionMarket: value }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        handleSetValue(values);
        setStep(nextStep);
      }}
    >
      {(props) => {
        const { handleSubmit, handleChange, values } = props;
        return (
          <form onSubmit={handleSubmit}>
            <StepHeader
              setStep={(val) => {
                handleSubmit(values);
                setNextStep(val);
              }}
              step={2}
              items={items}
              values={values}
              handleUpdateFarm={handleUpdateFarm}
            />
            <div className="row">
              <div className="col-12">
                <div className="row col-md-6 col-sm-6 col-lg-6 col-12 mt-10">
                  <div className="col-12">
                    <SelectField
                      required
                      disabled={action === "view"}
                      className="w-100"
                      value={currentValue ? currentValue.id : undefined}
                      data={[...markets, { value: null, label: strings.other }]}
                      onChange={(value) => {
                        setMarketId(value);
                        //to do after
                        setCurrentValue({
                          name: value
                            ? markets.find((f) => f.value === value).label
                            : "",
                          id: value,
                        });
                      }}
                      iconEnd="caret-down"
                      placeholder={strings.consumption_market}
                      label={strings.consumption_market}
                    />
                  </div>

                  {marketId === null && (
                    <div className="col-12">
                      <InputField
                        disabled={action === "view"}
                        label={strings.consumption_market_other}
                        placeholder={strings.consumption_market_other}
                        name="name"
                        inline={false}
                        value={(currentValue && currentValue.name) || ""}
                        onChange={(e) => {
                          setCurrentValue({
                            key: currentValue.key || undefined,
                            id: null,
                            name: e.target.value,
                          });
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className="w-100 pr-20 pl-30 ">
                <UIButton
                  type="primary"
                  className="btn-pd-add"
                  disabled={!currentValue || !currentValue.name}
                  onClick={() => {
                    if (currentValue.key || currentValue.key === 0) {
                      let mar = values.consumptionMarket;
                      mar[currentValue.key] = currentValue;
                      setCurrentValue(undefined);
                      handleChange("consumptionMarket")(mar);
                    } else {
                      handleChange("consumptionMarket")([
                        ...values.consumptionMarket,
                        currentValue,
                      ]);
                      setCurrentValue(undefined);
                    }
                  }}
                >
                  {strings.save}
                </UIButton>
              </div>
              <div className="w-100 pr-20 pl-30 pt-30">
                <Tabs defaultActiveKey="1" type="card" size={"middle"}>
                  <TabPane tab={strings.consumption_market} key="1">
                    <TableEditable
                      scroll={{ x: 600 }}
                      changedData={(index, val) => {
                        handleChange("consumptionMarket")(
                          values.consumptionMarket.filter(
                            (f, i) => i !== index,
                          ),
                        );
                      }}
                      editable={action !== "view"}
                      addRowAble={action !== "view"}
                      onSelect={handleSelectRow}
                      selectable={true}
                      dataSource={values.consumptionMarket}
                      columns={columns}
                    />
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default connect()(Markets);

import React, { useState } from "react";
import { Alert, Popover, Button } from "antd";
import { getString, getArray } from "~/views/utilities/helpers/utilObject";
import styled from "styled-components";
import { Form } from "antd";
import UtilDate from "~/views/utilities/helpers/UtilDate";

import strings from "~/localization";
import { UIButton } from "~/views/presentation/ui/buttons/index";
import { DatePicker, Space } from "antd";
import moment from "moment";
import { DownOutlined } from "@ant-design/icons";

const { RangePicker } = DatePicker;

/**viet
 *
 */
const FieldStyle = styled(Form.Item)`
  .ant-popover-inner-content {
    padding: 0px !important;
  }
  label {
    color: black !important;
    font-size: 16px;
  }
  .ant-select-dropdown.ant-select-dropdown-placement-bottomLeft
    div
    div:nth-child(2)
    div:nth-child(1) {
    height: inherit !important;
    position: relative;
    overflow: auto !important;
  }
  width: 100%;
  display: block;
  button {
    width: 400px;
    display: flex;
    height: fit-content;
    flex-direction: column;
  }
  .icon-expand {
    position: absolute !important;
    left: 91%;
    top: 10px;
  }
`;
const WrapContent = styled.div`
  min-width: 260px;
  width: 320px;
  border: 1px solid #ccc;
  background-color: #fff;
  border-right: none;
  border-bottom: none;
  .border-title {
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
    font-size: 15px;
    font-weight: 100;
    text-transform: uppercase;
    text-align: center;
    color: #757575;
    width: 100%;
    margin: 0;
    padding: 1em;
    background-color: #fff;
    font-weight: 600;
  }

  .button-custom {
    height: 47px;
    border: none;
    width: 50%;
    border-radius: 0;
    span {
      font-weight: 600;
    }
    border-right: 1px solid rgb(204, 204, 204) !important;
  }
  .ant-btn:focus {
    border: none;
  }
  .active-button {
    border-bottom: 3px solid #5d9c14 !important;
  }
  .item {
    background-color: #f0f0f0;
    padding: 12px 12px 12px 36px;
    position: relative;
    display: block;
    width: 50%;
    display: inline-block;
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
  }
  .item:hover {
    background-color: #ddd;
  }
  .square {
    margin: 0px 14px;
    width: 8px;
    height: 8px;
    display: inline-block;
    background-color: #5d9c14;
  }
  .active-choose {
    background-color: #fff;
    font-weight: 600;
    padding: 12px 12px 12px 0px !important;
  }
  .active-choose:hover {
    background-color: #fff;
  }
  .compare {
    height: 42px;
    display: flex;
    width: 100%;
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
    color: #757575;
    font-size: 14px;
    justify-content: center;
    align-items: center;
  }
`;
const dataSrc = [
  {
    id: "today",
    name: strings.today,
  },
  {
    id: "yesterday",
    name: strings.yesterday,
  },
  {
    id: "weekToDate",
    name: strings.week_to_date,
  },
  {
    id: "lastweek",
    name: strings.last_week,
  },
  {
    id: "monthToDate",
    name: strings.month_to_date,
  },
  {
    id: "lastMonth",
    name: strings.last_moth,
  },
  {
    id: "quarterToDate",
    name: strings.quarter_to_date,
  },
  {
    id: "lastQuarter",
    name: strings.last_quarter,
  },
  {
    id: "yearToDate",
    name: strings.year_to_date,
  },
  {
    id: "lastYear",
    name: strings.last_year,
  },
];
const BORDER = "1px solid #ccc";
const Content = (props) => {
  /** activeButton @param  === 1 cai dat truoc
   *               @param === 2 tuy trinh
   */
  const [activeButton, setActiveButton] = useState(1);
  /**default value mode */
  const [activeChoose, setACtiveChosse] = useState("yearToDate");
  const [activeChooseCompare, setACtiveChosseCompare] =
    useState("periodPrevious");
  /**date picker */
  const [firstDatePicker, setFirstDatePicker] = useState(undefined);
  const [secondDatePicker, setSecondDatePicker] = useState(undefined);

  const _handleChosseCompare = (item) => {
    setACtiveChosseCompare(item);
  };
  const _handleChosse = (item) => {
    setACtiveChosse(item.id);
  };
  let dateFist = moment
    .utc(firstDatePicker, UtilDate.formatDateTimeServer)
    .local();
  let dateSecond = moment
    .utc(secondDatePicker, UtilDate.formatDateTimeServer)
    .local();
  let date = [
    dateFist.isValid() ? dateFist : undefined,
    dateSecond.isValid() ? dateSecond : undefined,
  ];
  /**funtion get Ranger !!!
   *
   */
  const getRanger = () => {
    if (activeChooseCompare === "periodPrevious") {
      switch (activeChoose) {
        case "today":
          return {
            firstValue: {
              first: moment()
                .endOf("day")
                .utc()
                .format(UtilDate.formatDateTimeServer),
              second: null,
            },
            secondValue: {
              first: moment()
                .subtract(1, "day")
                .endOf("day")
                .utc()
                .format(UtilDate.formatDateTimeServer),
              second: null,
            },
          };
        case "yesterday":
          return {
            firstValue: {
              first: moment()
                .subtract(1, "day")
                .endOf("day")
                .utc()
                .format(UtilDate.formatDateTimeServer),
              second: null,
            },
            secondValue: {
              first: moment()
                .subtract(2, "day")
                .endOf("day")
                .utc()
                .format(UtilDate.formatDateTimeServer),
              second: null,
            },
          };
        case "weekToDate":
          let first = moment().startOf("isoweek").endOf("day");
          let second = moment().endOf("day");
          let ranger = second.diff(first, "days");
          return {
            firstValue: {
              first: moment()
                .startOf("isoweek")
                .endOf("day")
                .utc()
                .format(UtilDate.formatDateTimeServer),
              second: moment()
                .endOf("day")
                .utc()
                .format(UtilDate.formatDateTimeServer),
            },
            secondValue: {
              first: moment()
                .subtract(1, "weeks")
                .startOf("week")
                .utc()

                .format(UtilDate.formatDateTimeServer),
              second: moment()
                .subtract(1, "weeks")
                .startOf("week")
                .add(ranger, "days")
                .utc()

                .format(UtilDate.formatDateTimeServer),
            },
          };
        case "lastweek":
          return {
            firstValue: {
              first: moment()
                .subtract(1, "weeks")
                .startOf("week")
                .utc()

                .format(UtilDate.formatDateTimeServer),
              second: moment()
                .subtract(1, "weeks")
                .endOf("isoWeek")
                .subtract(1, "day")
                .utc()

                .format(UtilDate.formatDateTimeServer),
            },
            secondValue: {
              first: moment()
                .subtract(2, "weeks")
                .startOf("week")
                .utc()

                .format(UtilDate.formatDateTimeServer),
              second: moment()
                .subtract(2, "weeks")
                .endOf("isoWeek")
                .subtract(1, "day")
                .utc()

                .format(UtilDate.formatDateTimeServer),
            },
          };
        case "lastMonth":
          return {
            firstValue: {
              first: moment()
                .subtract(1, "months")
                .startOf("month")
                .utc()

                .format(UtilDate.formatDateTimeServer),
              second: moment()
                .subtract(1, "months")
                .endOf("month")
                .subtract(1, "day")
                .utc()

                .format(UtilDate.formatDateTimeServer),
            },
            secondValue: {
              first: moment()
                .subtract(2, "months")
                .startOf("month")
                .utc()

                .format(UtilDate.formatDateTimeServer),
              second: moment()
                .subtract(2, "months")
                .endOf("month")
                .subtract(1, "day")
                .utc()

                .format(UtilDate.formatDateTimeServer),
            },
          };
        case "monthToDate": {
          let first = moment().startOf("month");
          let second = moment().endOf("day");
          let ranger = second.diff(first, "days");
          return {
            firstValue: {
              first: moment()
                .startOf("month")
                .utc()
                .format(UtilDate.formatDateTimeServer),
              second: moment()
                .endOf("day")
                .utc()
                .format(UtilDate.formatDateTimeServer),
            },
            secondValue: {
              first: moment()
                .subtract(1, "months")
                .startOf("month")
                .utc()

                .format(UtilDate.formatDateTimeServer),
              second: moment()
                .subtract(1, "months")
                .startOf("month")
                .add(ranger, "days")
                .utc()

                .format(UtilDate.formatDateTimeServer),
            },
          };
        }
        case "lastQuarter":
          return {
            firstValue: {
              first: moment()
                .startOf("quarter")
                .subtract(1, "quarter")
                .utc()

                .format(UtilDate.formatDateTimeServer),
              second: moment()
                .subtract(1, "quarter")
                .endOf("quarter")
                .subtract(1, "day")
                .utc()

                .format(UtilDate.formatDateTimeServer),
            },
            secondValue: {
              first: moment()
                .startOf("quarter")
                .subtract(2, "quarter")
                .utc()

                .format(UtilDate.formatDateTimeServer),
              second: moment()
                .subtract(2, "quarter")
                .endOf("quarter")
                .subtract(1, "day")
                .utc()

                .format(UtilDate.formatDateTimeServer),
            },
          };
        case "quarterToDate": {
          let first = moment().startOf("quarter");
          let second = moment().endOf("day");
          let ranger = second.diff(first, "days");
          return {
            firstValue: {
              first: moment()
                .startOf("quarter")
                .utc()
                .format(UtilDate.formatDateTimeServer),
              second: moment()
                .endOf("day")
                .utc()
                .format(UtilDate.formatDateTimeServer),
            },
            secondValue: {
              first: moment()
                .startOf("quarter")
                .subtract(1, "quarter")
                .utc()

                .format(UtilDate.formatDateTimeServer),
              second: moment()
                .subtract(1, "quarter")
                .startOf("quarter")
                .add(ranger, "days")
                .utc()

                .format(UtilDate.formatDateTimeServer),
            },
          };
        }
        case "lastYear":
          return {
            firstValue: {
              first: moment()
                .startOf("year")
                .subtract(1, "year")
                .utc()

                .format(UtilDate.formatDateTimeServer),
              second: moment()
                .subtract(1, "year")
                .endOf("year")
                .subtract(1, "day")
                .utc()

                .format(UtilDate.formatDateTimeServer),
            },
            secondValue: {
              first: moment()
                .startOf("year")
                .subtract(2, "year")
                .utc()

                .format(UtilDate.formatDateTimeServer),
              second: moment()
                .subtract(2, "year")
                .endOf("year")
                .subtract(1, "day")
                .utc()

                .format(UtilDate.formatDateTimeServer),
            },
          };
        case "yearToDate": {
          let first = moment().startOf("year");
          let second = moment().endOf("day");
          let ranger = second.diff(first, "days");
          return {
            firstValue: {
              first: moment()
                .startOf("year")
                .utc()
                .format(UtilDate.formatDateTimeServer),
              second: moment()
                .endOf("day")
                .utc()
                .format(UtilDate.formatDateTimeServer),
            },
            secondValue: {
              first: moment()
                .startOf("year")
                .utc()
                .format(UtilDate.formatDateTimeServer),
              second: moment()
                .startOf("year")
                .add(ranger, "days")
                .utc()
                .format(UtilDate.formatDateTimeServer),
            },
          };
        }
        default:
          break;
      }
    } else if (activeChooseCompare === "yearPrevios") {
      switch (activeChoose) {
        case "today":
          return {
            firstValue: {
              first: moment()
                .endOf("day")
                .utc()
                .format(UtilDate.formatDateTimeServer),
              second: null,
            },
            secondValue: {
              first: moment()
                .subtract(1, "year")
                .endOf("day")
                .utc()
                .format(UtilDate.formatDateTimeServer),
              second: null,
            },
          };
        case "yesterday":
          return {
            firstValue: {
              first: moment()
                .subtract(1, "day")
                .endOf("day")
                .utc()
                .format(UtilDate.formatDateTimeServer),
              second: null,
            },
            secondValue: {
              first: moment()
                .subtract(1, "year")
                .subtract(1, "day")
                .endOf("day")
                .utc()
                .format(UtilDate.formatDateTimeServer),
              second: null,
            },
          };
        case "weekToDate":
          return {
            firstValue: {
              first: moment()
                .startOf("isoweek")
                .endOf("day")
                .utc()
                .format(UtilDate.formatDateTimeServer),
              second: moment()
                .endOf("day")
                .utc()
                .format(UtilDate.formatDateTimeServer),
            },
            secondValue: {
              first: moment()
                .startOf("isoweek")
                .subtract(1, "year")
                .endOf("day")
                .utc()
                .format(UtilDate.formatDateTimeServer),
              second: moment()
                .endOf("day")
                .subtract(1, "year")
                .utc()
                .format(UtilDate.formatDateTimeServer),
            },
          };
        case "lastweek":
          return {
            firstValue: {
              first: moment()
                .subtract(1, "weeks")
                .startOf("week")
                .utc()
                .format(UtilDate.formatDateTimeServer),
              second: moment()
                .subtract(1, "weeks")
                .endOf("Week")
                .utc()
                .format(UtilDate.formatDateTimeServer),
            },
            secondValue: {
              first: moment()
                .subtract(1, "weeks")
                .startOf("week")
                .subtract(1, "year")
                .utc()
                .format(UtilDate.formatDateTimeServer),
              second: moment()
                .subtract(1, "weeks")
                .endOf("Week")
                .subtract(1, "year")
                .utc()
                .format(UtilDate.formatDateTimeServer),
            },
          };
        case "lastMonth":
          return {
            firstValue: {
              first: moment()
                .subtract(1, "months")
                .startOf("month")
                .utc()

                .format(UtilDate.formatDateTimeServer),
              second: moment()
                .subtract(1, "months")
                .endOf("month")
                .subtract(1, "day")
                .utc()

                .format(UtilDate.formatDateTimeServer),
            },
            secondValue: {
              first: moment()
                .subtract(1, "months")
                .startOf("month")
                .subtract(1, "year")
                .utc()

                .format(UtilDate.formatDateTimeServer),
              second: moment()
                .subtract(1, "months")
                .endOf("month")
                .subtract(1, "day")
                .subtract(1, "year")
                .utc()

                .format(UtilDate.formatDateTimeServer),
            },
          };
        case "monthToDate": {
          return {
            firstValue: {
              first: moment()
                .startOf("month")

                .utc()
                .format(UtilDate.formatDateTimeServer),
              second: moment()
                .endOf("day")
                .utc()
                .format(UtilDate.formatDateTimeServer),
            },
            secondValue: {
              first: moment()
                .startOf("month")
                .subtract(1, "year")
                .utc()
                .format(UtilDate.formatDateTimeServer),
              second: moment()
                .endOf("day")
                .subtract(1, "year")
                .utc()
                .format(UtilDate.formatDateTimeServer),
            },
          };
        }
        case "lastQuarter":
          return {
            firstValue: {
              first: moment()
                .startOf("quarter")
                .subtract(1, "quarter")
                .utc()

                .format(UtilDate.formatDateTimeServer),
              second: moment()
                .subtract(1, "quarter")
                .endOf("quarter")
                .subtract(1, "day")
                .utc()

                .format(UtilDate.formatDateTimeServer),
            },
            secondValue: {
              first: moment()
                .startOf("quarter")
                .subtract(1, "quarter")
                .subtract(1, "year")
                .utc()
                .format(UtilDate.formatDateTimeServer),
              second: moment()
                .subtract(1, "quarter")
                .endOf("quarter")
                .subtract(1, "year")
                .utc()
                .format(UtilDate.formatDateTimeServer),
            },
          };
        case "quarterToDate": {
          return {
            firstValue: {
              first: moment()
                .startOf("quarter")
                .utc()
                .format(UtilDate.formatDateTimeServer),
              second: moment()
                .endOf("day")
                .utc()
                .format(UtilDate.formatDateTimeServer),
            },
            secondValue: {
              first: moment()
                .startOf("quarter")
                .utc()
                .subtract(1, "year")
                .format(UtilDate.formatDateTimeServer),
              second: moment()
                .endOf("day")
                .subtract(1, "year")
                .utc()
                .format(UtilDate.formatDateTimeServer),
            },
          };
        }
        case "lastYear":
          return {
            firstValue: {
              first: moment()
                .startOf("year")
                .subtract(1, "year")
                .utc()

                .format(UtilDate.formatDateTimeServer),
              second: moment()
                .subtract(1, "year")
                .endOf("year")
                .subtract(1, "day")
                .utc()

                .format(UtilDate.formatDateTimeServer),
            },
            secondValue: {
              first: moment()
                .startOf("year")
                .subtract(2, "year")
                .utc()

                .format(UtilDate.formatDateTimeServer),
              second: moment()
                .subtract(2, "year")
                .endOf("year")
                .subtract(1, "day")
                .utc()

                .format(UtilDate.formatDateTimeServer),
            },
          };
        case "yearToDate": {
          let first = moment().startOf("year");
          let second = moment().endOf("day");
          let ranger = second.diff(first, "days");
          return {
            firstValue: {
              first: moment()
                .startOf("year")
                .utc()
                .format(UtilDate.formatDateTimeServer),
              second: moment()
                .endOf("day")
                .utc()
                .format(UtilDate.formatDateTimeServer),
            },
            secondValue: {
              first: moment()
                .startOf("year")
                .subtract(1, "year")

                .utc()
                .format(UtilDate.formatDateTimeServer),
              second: moment()
                .endOf("day")
                .subtract(1, "year")

                .utc()
                .format(UtilDate.formatDateTimeServer),
            },
          };
        }
        default:
          break;
      }
    }
  };
  const getRangerForCustom = () => {
    let setSecondDatePickerSecond;
    let setFirstDatePickerSecond;

    if (activeChooseCompare === "periodPrevious") {
      const first = dateFist.endOf("day");

      const second = dateSecond.endOf("day");
      let ranger = second.diff(first, "days");

      setSecondDatePickerSecond = dateFist
        .endOf("day")
        .subtract(1, "day")
        .utc()
        .format(UtilDate.formatDateTimeServer);

      setFirstDatePickerSecond = dateFist
        .endOf("day")
        .subtract(ranger, "day")
        .utc()
        .format(UtilDate.formatDateTimeServer);
    } else if (activeChooseCompare === "yearPrevios") {
      setFirstDatePickerSecond = dateFist
        .endOf("day")

        .subtract(1, "year")
        .utc()
        .format(UtilDate.formatDateTimeServer);

      setSecondDatePickerSecond = dateSecond
        .endOf("day")
        .subtract(1, "year")
        .utc()
        .format(UtilDate.formatDateTimeServer);
    }
    return {
      firstValue: {
        first: firstDatePicker,
        second: secondDatePicker,
      },
      secondValue: {
        first: setFirstDatePickerSecond,
        second: setSecondDatePickerSecond,
      },
    };
  };
  const checkSubMit = () => {
    const itemChosse = dataSrc.find((data) => data.id === activeChoose);
    let { name } = itemChosse;
    /**cai dat truoc */
    if (activeButton === 1)
      return {
        ...getRanger(),
        firstLabel: name,
        secondLabel:
          activeChooseCompare === "periodPrevious"
            ? strings.previos_period
            : strings.previos_year,
      };
    /**tuy trinh */ else if (activeButton === 2) {
      return {
        ...getRangerForCustom(),
        firstLabel: strings.date_ranger,
        secondLabel:
          activeChooseCompare === "periodPrevious"
            ? strings.previos_period
            : strings.previos_year,
      };
    }
  };
  const _handleCheckActive = () => {
    if (activeButton === 1) return false;
    else if (activeButton === 2) {
      let check = false;
      if (getArray(date, undefined, []).length) {
        date.map((val) => {
          if (!val) check = true;
        });
      }
      return check;
    }
  };
  const _handleSubmit = () => {
    /**return data for dataRanger label */
    const dataReturn = checkSubMit();
    props.handleVisibleChange();
    props.onChange(dataReturn);
  };
  const _handleChangeDate = (data) => {
    if (getArray(data, undefined, []).length >= 2) {
      setFirstDatePicker(
        data[0].endOf("day").utc().format(UtilDate.formatDateTimeServer)
      );
      setSecondDatePicker(
        data[1].endOf("day").utc().format(UtilDate.formatDateTimeServer)
      );
    }
  };
  return (
    <WrapContent>
      <div className="border-title">{strings.date_ranger_title}</div>
      <Button
        onClick={() => {
          setActiveButton(1);
        }}
        className={`button-custom ${activeButton === 1 && "active-button"}`}
        style={{ borderRight: BORDER }}
      >
        {strings.presets}
      </Button>
      <Button
        onClick={() => {
          setActiveButton(2);
        }}
        style={{ borderBottom: BORDER }}
        className={`button-custom ${activeButton === 2 && "active-button"}`}
      >
        {strings.custom}
      </Button>
      {activeButton === 1 &&
        dataSrc.map((item, index) => (
          <div
            className={`item ${activeChoose === item.id && "active-choose"}`}
            key={item.id}
            onClick={() => _handleChosse(item)}
          >
            {activeChoose === item.id && <div className="square" />}
            {item.name}
          </div>
        ))}
      {activeButton === 2 && (
        <Space direction="vertical" style={{ width: "100%", border: "none" }}>
          <RangePicker
            style={{
              width: "100%",
              border: "none",
              borderRight: BORDER,
              borderTop: BORDER,
              borderBottom: BORDER,
            }}
            value={date}
            disabledDate={(current) =>
              current && current > moment().endOf("day")
            }
            onChange={(value) => {
              console.log(value, 123);
              _handleChangeDate(value);
            }}
          />
        </Space>
      )}
      {/* <div className="compare">{strings.compare_to}</div>
      <div
        className={`item ${
          activeChooseCompare === "periodPrevious" && "active-choose"
        }`}
        onClick={() => _handleChosseCompare("periodPrevious")}
      >
        {activeChooseCompare === "periodPrevious" && <div className="square" />}
        {strings.previos_period}
      </div>
      <div
        className={`item ${
          activeChooseCompare === "yearPrevios" && "active-choose"
        }`}
        onClick={() => _handleChosseCompare("yearPrevios")}
      >
        {activeChooseCompare === "yearPrevios" && <div className="square" />}
        {strings.previos_year}
      </div> */}
      <div className="compare" style={{ height: "60px" }}>
        <Button
          onClick={_handleSubmit}
          style={{ width: "120px" }}
          type="primary"
          disabled={_handleCheckActive()}
        >
          {strings.update}
        </Button>
      </div>
    </WrapContent>
  );
};
/**
 * @author viet
 * @param {label} props
 * @component content is content of DataRanger
 */
const DataRanger = (props) => {
  const { label, disabled = false } = props;
  /** value mac dinh */
  const [firstCompare, setFistCompare] = useState(strings.default);
  const [rotate, setRotate] = useState(0);
  const [secondCompare, setSecondCompare] = useState(strings.default);
  const [visible, setVisible] = useState(false);
  const _handleVisibleChange = () => {
    if (!visible) setRotate(180);
    else setRotate(360);

    setVisible(!visible);
  };
  const _handleOnChange = (data) => {
    props.handleChange(data);
    setFistCompare(
      `${data.firstLabel}(${UtilDate.toDateLocal(data.firstValue.first)}${
        data.firstValue.second &&
        ` - ${UtilDate.toDateLocal(data.firstValue.second)}`
      })`
    );
    setSecondCompare(
      `${data.secondLabel}(${UtilDate.toDateLocal(data.secondValue.first)}${
        data.secondValue.second &&
        ` - ${UtilDate.toDateLocal(data.secondValue.second)}`
      })`
    );
  };

  return (
    <FieldStyle label={label}>
      <Popover
        trigger="click"
        placement="bottom"
        content={
          <Content
            handleVisibleChange={_handleVisibleChange}
            onChange={_handleOnChange}
          />
        }
      >
        <Button
          onClick={_handleVisibleChange}
          className="w-100 d-flex  justify-content-center"
          style={{ height: "40px" }}
        >
          <DownOutlined rotate={rotate} className="icon-expand" />

          <span style={{ fontWeight: 600 }}>
            {firstCompare.replace(null, "")}
          </span>
          {/* <span>vs. {secondCompare.replace(null, "")}</span> */}
        </Button>
      </Popover>
    </FieldStyle>
  );
};

export default DataRanger;

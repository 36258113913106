import React from "react";
import { compose } from "recompose";
import { PageTitle } from "~/views/presentation/ui/commons";
import strings from "~/localization";
import { ContentWrapper } from "~/views/presentation/ui/container";
import ViewPage from "../components/ViewPage";

class FormOfUsesContent extends React.PureComponent {
  render() {
    return (
      <ContentWrapper>
        <PageTitle title={strings.FORM_OF_USES_PAGE_TITLE} breadcrumb={false} />
        <ViewPage
          KeyString="FORM_OF_USES"
          KeyGetApi="FORM_OF_USES"
          KeyActionApi="form-of-uses"
        />
      </ContentWrapper>
    );
  }
}

export default FormOfUsesContent;

export const formatShortNumber = (number)=>{
    let unit = ''
    let result = '0'
    const newNumber = Number(number);
   if(!newNumber){
    result = number;
    unit = ''
   }else{
    if(newNumber >= 1000000000){
        result = newNumber/1000000000.0
        unit = "B"
    }
    else{
        if(newNumber >= 1000000){
            result = newNumber/1000000.0
            unit = "M"
        }else{
            if(newNumber >= 1000){
                result = newNumber/1000.0
                unit = "K"
            }
            else{
                result = newNumber
                unit = ""
            }
        }
    }
    
   }
    return result + unit;
}
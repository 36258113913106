import React from "react";
import styled from "styled-components";

const ContentWrapperStyle = styled.div`
  background-color: #e9e9e9;
  padding-bottom: 10px;
`;
/**
 *
 * @param {*} topNav {pageName : 'Test', links : [{ name : 'link', link : '#' }]}
 */
export const ContentWrapper = ({ children }) => {
  return <ContentWrapperStyle>{children}</ContentWrapperStyle>;
};

export default ContentWrapper;

import React, { PureComponent } from "react";
import { Select } from "antd";
import PropTypes from "prop-types";
import { SelectStyle } from "./styles";
import { getArray } from "~/views/utilities/helpers/utilObject";
import { compose, lifecycle, withHandlers, withState } from "recompose";
import { removeAccentsNew } from "~/views/utilities/helpers/string";

const Option = Select.Option;

class SelectField extends PureComponent {
  render() {
    const {
      label,
      help,
      data,
      width,
      size,
      onChange,
      className,
      disabled,
      onBlur,
      validatestatus,
      iconEnd,
      iconStyle,
      inputStyle,
      gutterbottom,
      placeholder,
      labelCol,
      wrapperCol,
      mode,
      value,
      showSearch,
      loading,
      required = false,
      filterOption,
      keyword,
      setKeyword,
      ...rest
    } = this.props;

    return (
      <SelectStyle
        width={width}
        label={label}
        size={size}
        required={required}
        className={className}
        hasFeedback
        validatestatus={validatestatus}
        help={help}
        gutterbottom={gutterbottom}
        labelCol={labelCol}
        wrapperCol={wrapperCol}
      >
        <Select
          {...rest}
          loading={loading}
          showSearch={showSearch}
          onSearch={(val) => setKeyword(val)}
          style={inputStyle}
          onChange={onChange}
          onBlur={onBlur}
          allowClear
          disabled={disabled}
          placeholder={placeholder}
          mode={mode}
          value={value && getArray(data, undefined, []).length > 0 ? value : []}
          filterOption={false}
        >
          {data &&
            data
              .filter((f) =>
                removeAccentsNew(f.label).includes(removeAccentsNew(keyword)),
              )
              .map((item) => (
                <Option value={item.value} key={item.value}>
                  {item.label}
                </Option>
              ))}
        </Select>
      </SelectStyle>
    );
  }
}

SelectField.defaultProps = {
  disabled: false,
  placeholder: "",
  defaultValue: "",
  iconStyle: { color: "rgba(0,0,0,.25)" },
  // gutterbottom: true,
  mode: "default",
  showSearch: false,
  loading: false,
};

SelectField.propTypes = {
  showSearch: PropTypes.bool,
  label: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  inputStyle: PropTypes.object,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  data: PropTypes.array,
  gutterbottom: PropTypes.bool,
};

export default compose(withState("keyword", "setKeyword", ""))(SelectField);

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import strings from "~/localization";
import { UIButton } from "~/views/presentation/ui/buttons";
import { PageTitle, UIPagination } from "~/views/presentation/ui/commons";
import { SearchField } from "~/views/presentation/ui/fields";
import { UITable } from "~/views/presentation/ui/tables";
import { getArray, getString } from "~/views/utilities/helpers/utilObject";
import { getSeasonList, getFarmlist } from "~/state/ducks/appApis/actions";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import * as PATH from "~/configs/routesConfig";
import InputFilter from "~/views/presentation/ui/fields/InputFilter";
import { SearchOutlined } from "@ant-design/icons";

const optionFilter = [
  {
    value: "0-1000",
    label: "0 - 1000 Tấn",
  },
  {
    value: "1000-2000",
    label: "1000 Tấn - 2000 Tấn",
  },
  {
    value: "2000-3000",
    label: "2000 Tấn - 3000 Tấn",
  },
  {
    value: "3000-5000",
    label: "3000 Tấn - 5000 Tấn",
  },
  {
    value: "5000-10000",
    label: "5000 Tấn - 10000 Tấn",
  },
  {
    value: "10000-9999999",
    label: "> 10000 Tấn",
  },
];

function SeasonList(props) {
  const { getSeasonList, history, getFarmlist } = props;
  const [filters, setFilters] = useState({});
  const [dataSource, setDataSource] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [farmList, setFarmList] = useState([]);
  const [pagination, setPagination] = useState({
    total: 0,
    current: 1,
    pageSize: 5,
    hideOnSinglePage: false,
  });
  const [sort, setSort] = useState("farmingSeasonId,desc");
  const [isLoading, setIsLoading] = useState(false);

  const generateAutoKey = (dataSource) => {
    return getArray(dataSource, undefined, []).map((item, index) => ({
      ...item,
      key: item.id,
    }));
  };

  const fetchSeason = (page) => {
    setIsLoading(true);
    getSeasonList({
      ...filters,
      sort: sort,
      page: page ? page - 1 : pagination.current - 1,
      size: pagination.pageSize,
    })
      .then((res) => {
        setDataSource(generateAutoKey(res.res));
        const total = getString(res.header, "x-total-count");
        setPagination((prev) => ({ ...prev, total: total }));
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setIsLoading(false);
      });
    getFarmlist()
      .then((res) => {
        setFarmList(
          res.res.map((m) => {
            return {
              value: m.id,
              label: m.name,
            };
          })
        );
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchSeason();
  }, [filters, sort]);

  /**
   *  Handle change filters
   */

  const changeTextSearch = (value) => {
    setFilters((prev) => ({ keyword: value }));
  };

  const changePage = (page) => {
    setPagination((prev) => ({ ...prev, current: page }));
    fetchSeason(page);
  };

  // onsort params
  const onSort = (dataIndex, sortKey) => {
    const sort = sortKey === "ascend" ? "asc" : "desc";
    setSort(dataIndex + "," + sort);
  };

  // onfilter params
  const onFilter = (value) => {
    setFilters({ ...filters, ...value });
  };

  const handleClick = (action, id) => {
    history.push(
      PATH.SEASON_MANAGERMENT_DETAILS.replace(":action/:id", action + id)
    );
  };

  let columns = [
    {
      title: strings.SEASON_TABLE_COLUMN_ID,
      dataIndex: "id",
      key: "farmingSeasonId",
      width: 130,
      render: (cellData, row) => {
        return (
          <div
            onClick={() => {
              handleClick("view/", row.farmingSeasonId);
            }}
            className="text-primary"
          >
            {row.farmingSeasonId ? row.farmingSeasonId : "-"}
          </div>
        );
      },
    },
    {
      title: strings.SEASON_TABLE_COLUMN_NAME,
      dataIndex: "farmingSeasonName",
      render: (cellData, row) => {
        return (
          <div
            onClick={() => {
              handleClick("view/", row.farmingSeasonId);
            }}
            className="text-primary"
          >
            {cellData ? cellData : "-"}
          </div>
        );
      },
    },
    {
      title: strings.SEASON_TABLE_COLUMN_FARM,
      dataIndex: "farmName",
      render: (cellData, records) => {
        return <>{cellData ? cellData : "-"}</>;
      },
      filterDropdown: (
        <InputFilter
          type="select"
          value={filters.farmId || ""}
          width={185}
          onFiler={(val) => {
            onFilter({ farmId: val });
          }}
          options={farmList}
        />
      ),
    },
    {
      title: strings.SEASON_TABLE_COLUMN_CROPS,
      dataIndex: "productName",
      sorter: false,
      render: (cellData, records) => {
        return cellData;
      },
    },
    {
      title: strings.SEASON_TABLE_COLUMN_SOWING,
      dataIndex: "sowingDate",
      supportFilter: "date",
      onFiler: (val) => {
        onFilter({ sowingDateFrom: val[0], sowingDateTo: val[1] });
      },
      render: (cellData, records) => {
        return (
          <>{cellData ? new Date(cellData).toLocaleDateString("vi-VN") : "-"}</>
        );
      },
    },
    {
      title: strings.SEASON_TABLE_COLUMN_HARVEST,
      dataIndex: "harvestDate",
      supportFilter: "date",
      onFiler: (val) => {
        onFilter({ harvestDateFrom: val[0], harvestDateTo: val[1] });
      },
      render: (cellData, records) => {
        return (
          <>{cellData ? new Date(cellData).toLocaleDateString("vi-VN") : "-"}</>
        );
      },
    },
    {
      title: strings.SEASON_TABLE_COLUMN_EXPECTED,
      dataIndex: "valueToday",
      filterDropdown: (
        <InputFilter
          type="select"
          value={
            (filters.valueTodayFrom &&
              filters.valueTodayTo &&
              `${filters.valueTodayFrom}-${filters.valueTodayTo}`) ||
            ""
          }
          width={185}
          onFiler={(val) => {
            if (val) {
              const value = val.split("-");
              onFilter({
                valueTodayFrom: value[0],
                valueTodayTo: value[1],
              });
            } else {
              onFilter({
                valueTodayFrom: undefined,
                valueTodayTo: undefined,
              });
            }
          }}
          options={optionFilter}
        />
      ),
      render: (cellData, records) => {
        return (
          <div>
            {cellData && cellData} {records.unitName && records.unitName}
          </div>
        );
      },
    },
    {
      title: strings.SEASON_TABLE_COLUMN_DIARY,
      dataIndex: "",
      width: 130,
      sorter: false,
      filterDropdown: false,
      onFiler: (val) => {},
      render: (cellData, row) => {
        return (
          <UIButton
            type="primary"
            width="20"
            onClick={() => {
              history.push(
                PATH.DIARY_MANAGERMENT_LIST.replace(
                  ":farmingSeasonId",
                  row.farmingSeasonId
                )
              );
            }}
          >
            {strings.VIEW}
          </UIButton>
        );
      },
    },
    {
      title: strings.ACTION,
      dataIndex: "",
      sorter: false,
      filterDropdown: false,
      width: 160,
      render: (cellData, row) => {
        return (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <UIButton
              type="primary"
              width="20"
              onClick={() => {
                handleClick("view/", row.farmingSeasonId);
              }}
            >
              {strings.VIEW}
            </UIButton>
            <UIButton
              type="secondary"
              width="20"
              onClick={() => {
                handleClick("edit/", row.farmingSeasonId);
              }}
            >
              {strings.EDIT}
            </UIButton>
          </div>
        );
      },
    },
  ];

  columns = columns.map((col) => {
    let newCol = {
      width: 185,
      align: "center",
      sorter: (a, b, sortOrder) => {
        onSort(col.key || col.dataIndex, sortOrder);
      },
      filterDropdown: (
        <InputFilter
          value={filters[col.key ? col.key : col.dataIndex] || ""}
          type={col.supportFilter || "input"}
          width={col.width || 185}
          options={col.options || []}
          onFiler={
            col.onFiler
              ? col.onFiler
              : (val) => {
                  onFilter({ [col.key ? col.key : col.dataIndex]: val });
                }
          }
        />
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
      ),
      showSorterTooltip: false,
      ...col,
    };
    if (!newCol.filterDropdown) delete newCol.filterDropdown;
    return newCol;
  });

  return (
    <div>
      <PageTitle title={strings.MENU_SEASON} breadcrumb={false} />
      <div className="form-search-header w-100">
        <SearchField
          initialValue={filters.keyword || ""}
          placeholder={strings.search_season_placeholder}
          size="small"
          onChangeText={changeTextSearch}
        />
      </div>
      <div className="category-page">
        <div className="table-view w-100">
          <UITable
            width={1500}
            dataSource={getArray(dataSource)}
            rowClassName="cursor-pointer"
            columns={columns}
            loading={isLoading}
          />
          <div className="form-action-header mt-3 row">
            <div className="col-12 d-flex justify-content-end mb-3">
              <div className="pagination-right d-flex justify-content-center align-items-center ">
                <div className="amount-category mr-4">
                  <p>{`${pagination.total} ${strings.season}`}</p>
                </div>
                <UIPagination
                  defaultCurrent={pagination.current}
                  total={pagination.total}
                  pageSize={pagination.pageSize}
                  onChange={(page) => changePage(page)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default compose(
  withRouter,
  connect((state) => ({}), {
    getSeasonList,
    getFarmlist,
  })
)(SeasonList);

import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { compose, withState, lifecycle } from "recompose";
import * as PATH from "~/configs/routesConfig";
import Category from "./CategoryProduct";
import BusinessCertificate from "./BusinessCertificate";
import BusinessCertificateOfLand from "./BusinessCertificateOfLand";
import CropsProductContent from "./cropProduct";
import CultivationContent from "./Cultivation";
import _ from "lodash";
import { MenuSize } from "~/views/presentation/ui/navigation/Navbar";
import OriginalProduct from "./OriginalProduct";
import MarketContent from "./Market";
import FormOfUsesContent from "./FormOfUses";
import Overview from "./Overview/index";

class MasterData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewAsBlocks: true,
      showMenu: true,
      menuSize: MenuSize.small,
    };
  }

  renderContent = () => {
    switch (this.props.match.path) {
      case PATH.MASTER_DATA_DASHBOARD:
        return <Overview />;
      case PATH.MASTER_DATA_CATEGORY:
        return <Category />;
      case PATH.MASTER_DATA_PRODUCT_ALL:
      case PATH.MASTER_DATA_PRODUCT_VIEW:
      case PATH.MASTER_DATA_PRODUCT_ADD:
      case PATH.MASTER_DATA_PRODUCT_EDIT:
        return <OriginalProduct />;
      case PATH.MASTER_DATA_BUSINESS_CERTIFICATE:
        return <BusinessCertificate />;
      case PATH.MASTER_DATA_CERTIFICATION_LAND:
        return <BusinessCertificateOfLand />;
      case PATH.MASTER_DATA_CROPS:
        return <CropsProductContent />;
      case PATH.MASTER_DATA_CULTIVATION:
        return <CultivationContent />;
      case PATH.MASTER_DATA_MARKET:
        return <MarketContent />;
      case PATH.MASTER_DATA_FORM_OF_USES:
        return <FormOfUsesContent />;
      default:
        return;
    }
  };

  render() {
    return <div>{this.renderContent()}</div>;
  }
}

export default compose(withRouter)(MasterData);

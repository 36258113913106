import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import FarmList from "./Farm";
import SeasonList from "./Season";
import SeasonDetails from "./Season/Details";
import DiaryList from "./Diary";
import FarmDetails from "./Farm/Details";
import * as PATH from "~/configs/routesConfig";
import _ from "lodash";
import { MenuSize } from "~/views/presentation/ui/navigation/Navbar";
import Dashboard from "./Dashboard";
import ProductionDetails from "./Diary/Details";

class FarmManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewAsBlocks: true,
      showMenu: true,
      menuSize: MenuSize.small,
    };
  }

  renderContent = () => {
    switch (this.props.match.path) {
      case PATH.FARM_MANAGERMENT_LIST:
        return <FarmList />;
      case PATH.FARM_MANAGERMENT_DETAILS:
        return <FarmDetails />;
      case PATH.SEASON_MANAGERMENT_LIST:
        return <SeasonList />;
      case PATH.SEASON_MANAGERMENT_DETAILS:
        return <SeasonDetails />;
      case PATH.DIARY_MANAGERMENT_LIST:
        return <DiaryList />;
      case PATH.DIARY_MANAGERMENT_DETAILS:
        return <ProductionDetails />;
      case PATH.FARM_MANAGERMENT_DASHBOARD:
        return <Dashboard />;
      default:
        return;
    }
  };

  render() {
    return <div>{this.renderContent()}</div>;
  }
}

export default compose(withRouter)(FarmManagement);

import React, { PureComponent } from "react";
import { Row, Col } from "antd";
import { Form } from "antd";
import enhance from "./withForm";
// import { InputField } from "~/views/presentation/ui/fields";
import InputFieldIcon from "../../ui/fields/InputFieldIcon";
import { UIButton } from "~/views/presentation/ui/buttons";
import styled from "styled-components";
import strings from "../../../../localization";
import { ACCOUNT_RECOVERY_PATH, REGISTER_PATH } from "~/configs/routesConfig";
import Color from "~/views/utilities/layout/color";
import LogoIntro from "~/static/images/Logo.svg";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { InputField } from "../../ui/fields";

const FormStyled = styled(Form)`
  background-color: ${Color.white};
  height: 100%;
  padding: 5%;
  width: 100%;
  .login   {
    color: ${Color.brow};
  }
  span {
    color: ${Color.gray_01};
  }

  .login-content {
    padding-left: 5%;
    padding-right: 5%;
  }
  .welcom {
    margin-top: 10%;
    display: block;
    color: ${Color.green_02};
    font-family: Inter;
    font-size: 350%;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    @media (max-width: 960px) {
      font-size: 36px;
    }
  }
  .backoffice {
    display: block;
    color: #202020;
    font-family: Inter;
    font-size: 160%;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    @media (max-width: 960px) {
      font-size: 18px;
    }
  }
  span.policy_terms a {
    color: ${Color.gray_01};
    text-align: right;
    font-family: Inter;
    font-size: 120%;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @media (max-width: 960px) {
      display: none;
    }
  }
  span.policy_terms a:hover {
    color: green;
    text-decoration: none;
  }
  .login {
    margin-top: 10%;
    color: ${Color.green_02};
    font-family: Inter;
    font-size: 140%;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    @media (max-width: 960px) {
      font-size: 18px;
    }
  }

  img {
    height: 80px;
    width: 165px;
  }
  .login_anotation {
    margin-bottom: 5%;
    color: #9ca3af;
    font-family: Inter;
    font-size: 100%;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
  }
  div div button:hover {
    color: ${Color.grayhover};
    text-decoration: none;
    border: none;
  }
  div div button {
    text-decoration: none;
    font-style: italic;
    border: none;
  }
  .forgot {
    color: #202020;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .body-content {
    height: 100%;
    @media (max-width: 960px) {
      height: 95%;
    }
  }
`;

class LoginForm extends PureComponent {
  render() {
    const {
      values,
      touched,
      errors,
      handleChange,
      handleSubmit,
      isValid,
      isSubmitting,
      history,
    } = this.props;

    return (
      <FormStyled onFinish={handleSubmit}>
        <div
          style={{
            height: "100%",
          }}
        >
          <div className="body-content">
            <div className="row d-flex justify-content-end">
              <span className="policy_terms">
                <a href="https://edenfarm.com.vn/privacy-policy/">
                  {strings.policy} |
                </a>{" "}
                <a href="https://edenfarm.com.vn/terms-of-service/">
                  {strings.terms}
                </a>
              </span>
            </div>
            <img src={LogoIntro} />
            <div className="login-content">
              <span className="welcom">{strings.welcom_to}</span>
              <span className="backoffice">
                {strings.Farm_management_backoffice}
              </span>
              <p className="login">{strings.login.toUpperCase()}</p>
              <p className="login_anotation">{strings.login_des}</p>

              <InputField
                validatestatus={
                  touched.username && errors.username ? "error" : undefined
                }
                help={
                  touched.username && errors.username ? errors.username : ""
                }
                name="username"
                iconStart={<UserOutlined />}
                autoFocus
                value={values.username}
                onChange={handleChange}
                placeholder={strings.login_placeholder}
              />
              <InputField
                validatestatus={
                  touched.password && errors.password ? "error" : undefined
                }
                help={
                  touched.password && errors.password ? errors.password : ""
                }
                name="password"
                type="password"
                iconStart={<LockOutlined />}
                autoFocus
                value={values.password}
                onChange={handleChange}
                placeholder={strings.password}
              />
              <Row type="flex" className="mt-3">
                <Col span={24} className="d-flex justify-content-end">
                  <button
                    className="btn btn-link mx-0 px-0"
                    type="button"
                    onClick={() => {
                      history.push(ACCOUNT_RECOVERY_PATH);
                    }}
                  >
                    <span className="forgot">{strings.forgot_password}</span>
                  </button>
                </Col>
              </Row>
              <Row type="flex" align="middle" className="mt-4">
                <UIButton
                  style={{ width: "100%" }}
                  type="primary"
                  htmlType="submit"
                  loading={isSubmitting}
                  disabled={!isValid}
                >
                  <span>{strings.login}</span>
                </UIButton>
              </Row>
            </div>
          </div>

          <span>
            Copyright © 2023 EdenHub, | Powered by{" "}
            <span style={{ textDecoration: "underline" }}>Edenhub JSC</span>
          </span>
        </div>
      </FormStyled>
    );
  }
}

export default enhance(LoginForm);

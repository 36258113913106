import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import COLOR from "~/views/utilities/layout/color";
import strings from "~/localization";
import { DataRang } from "~/views/presentation/ui/dashboard";
import {
  getMasterData,
  getAllCategories,
  getAllProducts,
} from "~/state/ducks/appApis/actions";

const Container = styled.div`
  width:100%;

  .content {
    width:100%;
    background-color:${COLOR.white}
    border-radius : 8px;
    box-sizing: border-box;
    padding:20px;
    display:flex;
    justify-content:space-between;
    flex-wrap:wrap;
  }

`;

const Card = styled.div`
  margin-bottom: 5px;
  height: 140px;
  width: 24%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1024px) and (min-width: 960px) {
    width: 33%;
  }
  @media (max-width: 960px) and (min-width: 600px) {
    width: 49%;
  }
  @media (max-width: 600px) {
    width: 99%;
  }
  border-radius: 8px;
  box-sizing: border-box;
  background-color: ${(props) => props.backgroundColor || COLOR.green};
  span {
    color: ${COLOR.white};
    display: block;
  }
  .title {
    font-size: 36px;
  }
  .description {
    font-size: 16px;
  }
`;

export const Overview = (props) => {
  const { getMasterData, getAllCategories, getAllProducts } = props;
  const [masterData, setMasterData] = useState({
    CATEGORY: 0,
    PRODUCT_MASTER: 0,
    CERTIFICATION: 0,
    CERTIFICATION_LAND: 0,
    PRODUCT: 0,
    CULTIVATION: 0,
    MARKET: 0,
    FORM_OF_USES: 0,
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    let value = {};
    value.CERTIFICATION = await FetchMasterData("CERTIFICATION");
    value.CERTIFICATION_LAND = await FetchMasterData("CERTIFICATION_LAND");
    value.PRODUCT = await FetchMasterData("PRODUCT");
    value.CULTIVATION = await FetchMasterData("CULTIVATION");
    value.MARKET = await FetchMasterData("MARKET");
    value.FORM_OF_USES = await FetchMasterData("FORM_OF_USES");
    value.CATEGORY = await FetchCategory();
    value.PRODUCT_MASTER = await FetchProductMaster();
    setMasterData(value);
  };

  const FetchMasterData = async (type) => {
    const results = await getMasterData({ type: type });
    return results.header["x-total-count"];
  };

  const FetchCategory = async () => {
    const results = await getAllCategories();
    return results.header["x-total-count"];
  };
  const FetchProductMaster = async () => {
    const results = await getAllProducts("");
    return results.header["x-total-count"];
  };

  const CardDashBoard = ({ title, value, color }) => {
    return (
      <Card backgroundColor={color}>
        <div>
          <span className="title">{value}</span>
          <span className="description">{title}</span>
        </div>
      </Card>
    );
  };

  return (
    <Container>
      <div className="row d-flex justify-content-between">
        <div className="col-12 col-md-6 col-lg-6">
          <h1>{strings.MENU_DASHBOARD}</h1>
        </div>
        <div className="col-12 col-md-6 col-lg-6">
          <DataRang
            disabled
            handleChange={(val) => {
              // fetchData(val.firstValue.first, val.firstValue.second);
            }}
            label={strings.dashboard_data_range}
          />
        </div>
      </div>
      <div className="content">
        <CardDashBoard
          title={strings.MENU_CATEGORY}
          value={masterData.CATEGORY}
          color={COLOR.green_02}
        />
        <CardDashBoard
          title={strings.MENU_PRODUCT}
          value={masterData.PRODUCT_MASTER}
          color={COLOR.brow}
        />
        <CardDashBoard
          title={strings.MENU_BUSINESS_CERTIFICATE}
          color={COLOR.blue}
          value={masterData.CERTIFICATION}
        />
        <CardDashBoard
          title={strings.MENU_CERTIFICATION_LAND}
          color={COLOR.yellow}
          value={masterData.CERTIFICATION_LAND}
        />
        <CardDashBoard
          title={strings.MENU_CROPS}
          value={masterData.PRODUCT}
          color={COLOR.yellowGreen}
        />
        <CardDashBoard
          title={strings.MENU_CULTIVATION}
          color={COLOR.browLight}
          value={masterData.CULTIVATION}
        />
        <CardDashBoard
          title={strings.MENU_MARKETS}
          value={masterData.CULTIVATION}
          color={COLOR.graytheme}
        />
        <CardDashBoard
          title={strings.MENU_FORM_OF_USES}
          value={masterData.FORM_OF_USES}
          color={COLOR.red}
        />
      </div>
    </Container>
  );
};

export default connect(null, {
  getMasterData,
  getAllCategories,
  getAllProducts,
})(Overview);

import React from "react";
import styled from "styled-components";
import { UIBreadcrumb } from "~/views/presentation/ui/commons";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import * as PATH from "~/configs/routesConfig";
import { Button, Popover } from "antd";
import strings from "~/localization";
import { Color } from "~/views/utilities/layout";

const ContainerStyled = styled.div`
  span.ant-dropdown-trigger {
    margin-right: auto;
  }
  a:hover {
    text-decoration: none;
    color: ${Color.green};
  }
  background-color: #fff;
  box-shadow: 1px 1px 1px 0px #ccc;
  padding: 10px .row {
    align-items: center;
    div {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
`;
const GroupButtonStyled = styled.div`
  button {
    width: auto;
    height: 24px !important;
    padding: 0px 15px !important;
    border-radius: 5px;
  }
  button span {
    text-transform: lowercase;
    cursor: pointer;
  }
  button span:first-letter {
    text-transform: uppercase;
  }
`;

const ButtonStyled = styled(Button)`
  display: block;
  width: 100%;
  margin-bottom: 3px;
`;

class MDNav extends React.PureComponent {
  constructor(props) {
    super(props);
    if (global.viewAsBlocks === undefined) {
      global.viewAsBlocks = true;
    }
    this.state = {
      viewAsBlocks: global.viewAsBlocks,
      isChooseProfileType: false,
    };
  }

  handleChangeView = () => {
    let viewAsBlocks = this.state.viewAsBlocks;
    this.setState({ viewAsBlocks: !viewAsBlocks });
    global.viewAsBlocks = !viewAsBlocks;
    this.handleUpdateViewStyle(!viewAsBlocks);
  };
  componentDidMount() {
    this.handleUpdateViewStyle(this.state.viewAsBlocks);
  }

  handleUpdateViewStyle = (viewAsBlocks) => {
    const { changeViewStyle } = this.props;
    changeViewStyle && changeViewStyle(viewAsBlocks);
  };

  renderButtons = () => {
    const { match, history } = this.props;
    switch (match.path) {
      case PATH.MASTER_DATA_DASHBOARD:
        return (
          <Popover
            trigger="click"
            placement="bottom"
            content={
              <div style={{ width: "200px" }}>
                <ButtonStyled
                  onClick={() => history.push(PATH.MASTER_DATA_CATEGORY)}
                >
                  {strings.MENU_CATEGORY}
                </ButtonStyled>
                <ButtonStyled
                  onClick={() => history.push(PATH.MASTER_DATA_PRODUCT_ADD)}
                >
                  {strings.MENU_PRODUCT}
                </ButtonStyled>
                <ButtonStyled
                  onClick={() =>
                    history.push(PATH.MASTER_DATA_BUSINESS_CERTIFICATE)
                  }
                >
                  {strings.MENU_BUSINESS_CERTIFICATE}
                </ButtonStyled>
                <ButtonStyled
                  onClick={() =>
                    history.push(PATH.MASTER_DATA_CERTIFICATION_LAND)
                  }
                >
                  {strings.MENU_CERTIFICATION_LAND}
                </ButtonStyled>
                <ButtonStyled
                  onClick={() => history.push(PATH.MASTER_DATA_CROPS)}
                >
                  {strings.MENU_CROPS}
                </ButtonStyled>
                <ButtonStyled
                  onClick={() => history.push(PATH.MASTER_DATA_CULTIVATION)}
                >
                  {strings.MENU_CULTIVATION}
                </ButtonStyled>
                <ButtonStyled
                  onClick={() => history.push(PATH.MASTER_DATA_MARKET)}
                >
                  {strings.MENU_MARKETS}
                </ButtonStyled>
                <ButtonStyled
                  onClick={() => history.push(PATH.MASTER_DATA_FORM_OF_USES)}
                >
                  {strings.MENU_FORM_OF_USES}
                </ButtonStyled>
              </div>
            }
          >
            <Button type="primary" block>
              {strings.service_creation}
            </Button>
          </Popover>
        );
    }
  };

  render() {
    const { history, breadcrumb = [{ title: "", href: "" }] } = this.props;
    return (
      <ContainerStyled className={this.props.className}>
        <div className="row">
          <div className="col-12 ml-3">
            <UIBreadcrumb path={breadcrumb} />
          </div>
        </div>
        <div className="row">
          <div className="ml-5">
            <GroupButtonStyled>{this.renderButtons()}</GroupButtonStyled>
          </div>
        </div>
      </ContainerStyled>
    );
  }
}
export default compose(withRouter)(MDNav);

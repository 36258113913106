import React, { useState } from "react";
import {
  InputField,
  SelectField,
  InputNumber,
} from "~/views/presentation/ui/fields";
import strings from "~/localization";
import { Card, Tabs } from "antd";
import LicenceUploader from "~/views/presentation/ui/upload/LicencesUpload";
import { TableEditable } from "~/views/presentation/ui/tables";
import OneUploader from "./OneUploader";
import { Formik } from "formik";
import StepHeader from "./StepHeader";
import { UIButton } from "~/views/presentation/ui/buttons";
const { TabPane } = Tabs;

function CertificationLand(props) {
  const {
    value,
    action,
    unitsArea,
    certificationLand,
    formOfUses,
    handleSetValue,
    items,
    setStep,
    handleUpdateFarm,
  } = props;
  const defaultValue = {
    landLotNo: "",
    address: null,
    images: "",
    ownerNameOther: "",
    areage: {
      value: null,
      unitName: "",
      unitId: null,
    },
    formOfUsesIds: [],
    typeId: undefined,
  };
  const [currentValue, setCurrentValue] = useState(defaultValue);
  const [nextStep, setNextStep] = useState(0);
  const [image, setImage] = useState(["", "", "", ""]);

  const handleSelectRow = (value) => {
    setCurrentValue(value);
    const imagesArray = value.images.split("|")
    setImage([
      imagesArray[0] || "",
      imagesArray[1] || "",
      imagesArray[2] || "",
      imagesArray[3] || ""
    ])
  };


  const columns = [
    {
      title: strings.certificate_type,
      dataIndex: "typeId",
      key: "type",
      width: "20%",
      editable: false,
      render: (cell, row) => {
        const results = certificationLand.find((f) => f.value === cell);
        return (
          <div onClick={() => handleSelectRow(row)}>
            {results ? results.label : "-"}
          </div>
        );
      },
    },
    {
      title: strings.id_land,
      dataIndex: "landLotNo",
      key: "landLotNo",
      width: "20%",
      align: "center",
      editable: false,
    },
    {
      title: strings.acreage,
      dataIndex: ["areage", "value"],
      key: "areageValue",
      type: "text",
      width: "20%",
      align: "center",
      render: (cell, row) => {
        return (
          <div onClick={() => handleSelectRow(row)}>
            {cell || cell === 0
              ? cell + " " + (row.areage.unitName || "")
              : "-"}
          </div>
        );
      },
    },
    {
      title: strings.status_land,
      dataIndex: "formOfUsesIds",
      key: "formOfUsesIds",
      width: "20%",
      align: "center",
      render: (cell, row) => {
        const result =
          cell && cell.length && cell[0]
            ? formOfUses.find((f) => f.value === cell[0])
            : undefined;
        return (
          <div onClick={() => handleSelectRow(row)}>
            {result ? result.label : "-"}
          </div>
        );
      },
    },
    {
      title: strings.his_land,
      dataIndex: "ownerNameOther",
      key: "ownerNameOther",
      width: "20%",
      align: "center",
    },
  ];

  return (
    <Formik
      initialValues={{ certifycateOfLands: value }}
      onSubmit={(values) => {
        handleSetValue(values);
        setStep(nextStep);
      }}
    >
      {(props) => {
        const { handleSubmit, handleChange, values } = props;
        return (
          <form onSubmit={handleSubmit}>
            <StepHeader
              setStep={(val) => {
                handleSubmit(values);
                setNextStep(val);
              }}
              step={4}
              items={items}
              values={values}
              handleUpdateFarm={handleUpdateFarm}
            />
            <div className="row p-10">
              <div className="col-12 col-md-6 mb-3">
                <Card>
                  <SelectField
                    required
                    className="w-100"
                    data={certificationLand}
                    disabled={action === "view"}
                    onChange={(value) => {
                      setCurrentValue({
                        ...currentValue,
                        typeId: value,
                      });
                    }}
                    iconEnd="caret-down"
                    placeholder={strings.certificate_type}
                    label={strings.certificate_type}
                    value={(currentValue && currentValue.typeId) || undefined}
                  />
                  <InputField
                    required
                    name="landType"
                    type="text"
                    disabled={action === "view"}
                    value={
                      (currentValue && currentValue.landLotNo) || undefined
                    }
                    onChange={(e) => {
                      setCurrentValue({
                        ...currentValue,
                        landLotNo: e.target.value,
                      });
                    }}
                    placeholder={strings.id_land}
                    label={strings.id_land}
                  />
                  <div className="row">
                    <InputNumber
                      disabled={action === "view"}
                      label={strings.sum_acreage}
                      name="value"
                      onChange={(val) => {
                        setCurrentValue({
                          ...currentValue,
                          areage: {
                            ...currentValue.areage,
                            value: val,
                          },
                        });
                      }}
                      placeholder={strings.sum_acreage}
                      className="col-6"
                      value={
                        (currentValue &&
                          currentValue.areage &&
                          currentValue.areage.value) ||
                        null
                      }
                    />
                    <SelectField
                      disabled={action === "view"}
                      className="col-6"
                      onChange={(val) => {
                        setCurrentValue({
                          ...currentValue,
                          areage: {
                            ...currentValue.areage,
                            unitId: val,
                          },
                        });
                      }}
                      placeholder={strings.unit}
                      data={unitsArea}
                      label={strings.unit}
                      value={
                        (currentValue &&
                          currentValue.areage &&
                          currentValue.areage.unitId) ||
                        undefined
                      }
                    />
                  </div>
                  <SelectField
                    className="w-100"
                    disabled={action === "view"}
                    placeholder={strings.status_land}
                    data={formOfUses}
                    label={strings.status_land}
                    value={
                      (currentValue &&
                        currentValue.formOfUsesIds &&
                        currentValue.formOfUsesIds.length &&
                        currentValue.formOfUsesIds[0]) ||
                      undefined
                    }
                    onChange={(val) => {
                      setCurrentValue({
                        ...currentValue,
                        formOfUsesIds: [val],
                      });
                    }}
                  />

                  <InputField
                    name="owner"
                    type="text"
                    disabled={action === "view"}
                    value={
                      (currentValue && currentValue.ownerNameOther) || undefined
                    }
                    onChange={(e) => {
                      setCurrentValue({
                        ...currentValue,
                        ownerNameOther: e.target.value,
                      });
                    }}
                    placeholder={strings.his_land}
                    label={strings.his_land}
                  />
                </Card>
              </div>
              <div className="col-12 col-md-6 ">
                <Card>
                  <div className="row">
                    <div className="col-6">
                      <OneUploader
                        disabled={action === "view"}
                        show={true}
                        label={strings.before_upload_GCN}
                        images={{
                          img: currentValue && currentValue.images,
                          index: 0,
                        }}
                        onChange={(e) => {
                          let images = image;
                          images[0] = e;
                          setImage(images);
                          setCurrentValue({
                            ...currentValue,
                            images: image.join("|"),
                          });
                        }}
                      />
                    </div>
                    <div className="col-6" style={{ marginBottom: 25 }}>
                      <OneUploader
                        disabled={action === "view"}
                        show={false}
                        label={strings.after_upload_GCN}
                        images={{
                          img: currentValue && currentValue.images,
                          index: 1,
                        }}
                        onChange={(e) => {
                          let images = image;
                          images[1] = e;
                          setImage(images);
                          setCurrentValue({
                            ...currentValue,
                            images: image.join("|"),
                          });
                        }}
                      />
                    </div>
                    <div className="col-6">
                      <OneUploader
                        disabled={action === "view"}
                        show={false}
                        label={strings.human_and_GCN_upload}
                        images={{
                          img: currentValue && currentValue.images,
                          index: 2,
                        }}
                        onChange={(e) => {
                          let images = image;
                          images[2] = e;
                          setImage(images);
                          setCurrentValue({
                            ...currentValue,
                            images: image.join("|"),
                          });
                        }}
                      />
                    </div>
                    <div className="col-6">
                      <OneUploader
                        disabled={action === "view"}
                        show={false}
                        label={strings.other}
                        images={{
                          img: currentValue && currentValue.images,
                          index: 3,
                        }}
                        onChange={(e) => {
                          let images = image;
                          images[3] = e;
                          setImage(images);

                          setCurrentValue({
                            ...currentValue,
                            images: image.join("|"),
                          });
                        }}
                      />
                    </div>
                  </div>
                </Card>
              </div>
              <div className="w-100 pr-20 pl-30 ">
                <UIButton
                  type="primary"
                  className="btn-pd-add "
                  disabled={
                    !currentValue ||
                    !(currentValue.typeId && currentValue.landLotNo)
                  }
                  onClick={() => {
                    if (currentValue.key || currentValue.key === 0) {
                      let cer_land = values.certifycateOfLands;
                      cer_land[currentValue.key] = currentValue;
                      setCurrentValue(defaultValue);
                      handleChange("certifycateOfLands")(cer_land);
                    } else {
                      handleChange("certifycateOfLands")([
                        ...values.certifycateOfLands,
                        currentValue,
                      ]);
                      setCurrentValue(defaultValue);
                    }
                  }}
                >
                  {strings.save}
                </UIButton>
              </div>
              <div className="col-12 mt-3">
                <Tabs defaultActiveKey="1" type="card" size={"middle"}>
                  <TabPane tab={strings.gcn_qsdd} key="1">
                    <TableEditable
                      changedData={(index) => {
                        handleChange("certifycateOfLands")(
                          values.certifycateOfLands.filter(
                            (f, i) => i !== index,
                          ),
                        );
                      }}
                      editable={action !== "view"}
                      addRowAble={true}
                      onSelect={handleSelectRow}
                      selectable={true}
                      dataSource={values.certifycateOfLands}
                      columns={columns}
                    />
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
}

export default CertificationLand;
